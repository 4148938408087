import React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import Avatar from "@material-ui/core/Avatar";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import FirebaseContext from "../firebase/context";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import AvatarComponentSeat from "./AvatarComponentSeat";
import { useTranslation } from "react-i18next";
import vibrate from "../util/feedback";

export default function FriendRequest({ uid, admin, params, onClick }) {
  const { firebase, storage, user } = React.useContext(FirebaseContext);
  const [userData = {}] = useDocumentData(firebase.db.doc(`users/${uid}`));
  const { t, i18n } = useTranslation();

  function kickPerson() {
    firebase.db
      .doc(`drivers/${params.id}`)
      .update({ seats: firebase.FieldValue.arrayRemove(uid) });

    firebase.db.doc(`users/${uid}`).update({
      kicked: firebase.FieldValue.arrayUnion(firebase.auth.currentUser.uid),
    });
  }

  function removeRequest(user) {
    firebase.db.doc(`users/${user}`).update({
      friendRequests: firebase.FieldValue.arrayRemove(
        firebase.auth.currentUser.uid
      ),
    });
  }

  function handleFriendRemove(remover) {
    firebase.db
      .doc(`users/${remover}`)
      .update({
        friends: firebase.FieldValue.arrayRemove(firebase.auth.currentUser.uid),
      })
      .then(() => {
        firebase.db
          .doc(`users/${user && user.uid}`)
          .update({ friends: firebase.FieldValue.arrayRemove(remover) });
      });
  }

  function handleFriendRequest(user) {
    firebase.db.doc(`users/${user}`).update({
      friendRequests: firebase.FieldValue.arrayUnion(
        firebase.auth.currentUser.uid
      ),
    });
  }

  return (
    <div className="drivepost add seats">
      <div className="seatlistsingle" onClick={onClick}>
        <AvatarComponentSeat driverid={uid} />
        <p
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            textAlign: "left",
            color: "white",
            fontWeight: "600",
            marginLeft: "15px",
          }}
        >
          {userData.username && userData.username.length > 11
            ? `${userData.username.substring(0, [10])}...`
            : userData.username}
        </p>
      </div>
      {uid === firebase.auth.currentUser.uid ? (
        <button className="addfriendbutton request">{t("you")}</button>
      ) : userData.friendRequests?.includes(firebase.auth.currentUser.uid) ? (
        <button
          onClick={() => removeRequest(uid)}
          className="addfriendbutton request"
        >
          {t("requested")}
        </button>
      ) : userData.friends?.includes(firebase.auth.currentUser.uid) ? (
        <button
          className="addfriendbutton"
          onClick={() => handleFriendRemove(uid)}
        >
          {t("remove_big")}
        </button>
      ) : (
        <button
          className="addfriendbutton"
          onClick={() => {
            vibrate("medium");
            handleFriendRequest(uid);
          }}
        >
          {t("adding_button")}
        </button>
      )}
      {admin === firebase.auth.currentUser.uid ? (
        <DeleteForeverRoundedIcon
          onClick={kickPerson}
          style={{ color: "rgb(250, 73, 113)" }}
        />
      ) : (
        ""
      )}
    </div>
  );
}
