import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import firebaseConfig from "./config";

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    this.auth = app.auth();
    this.googleAuthProvider = new app.auth.GoogleAuthProvider();
    this.appleAuthProvider = new app.auth.OAuthProvider("apple.com");
    this.FieldValue = app.firestore.FieldValue;
    this.db = app.firestore();
    this.storage = app.storage();
    app.firestore().enablePersistence();
  }

  async register(name, email, password, data) {
    const newUser = await this.auth.createUserWithEmailAndPassword(
      email,
      password
    );
    this.db.doc(`users/${newUser.user.uid}`).set(data);
    await newUser.user.sendEmailVerification();
    await newUser.user.updateProfile({
      displayName: name,
    });
    return newUser.user.uid;
  }

  async loginWithEmail(email, password) {
    return await this.auth.signInWithEmailAndPassword(email, password);
  }

  async loginWithGoogle(lang) {
    this.googleAuthProvider.addScope(
      "https://www.googleapis.com/auth/contacts.readonly"
    );
    this.auth.languageCode = lang;
    return this.auth.signInWithPopup(this.googleAuthProvider);
  }

  async loginWithApple(lang) {
    this.appleAuthProvider.addScope("email");
    this.appleAuthProvider.addScope("name");
    this.appleAuthProvider.setCustomParameters({ locale: lang });
    return this.auth.signInWithPopup(this.appleAuthProvider);
  }

  async handleOauthLoginRegister(user) {
    try {
      if (!user) throw new Error("No user provided");
      const existingAccount = (
        await this.db.doc(`users/${user.uid}`).get()
      ).data();
      if (existingAccount.usernamelowerCase && existingAccount.username) {
        return console.debug(`user exists`);
      }
      console.debug(`auto assigning user data...`);
      const name = await this.createUniqueUsername(user);
      await this.registerNonEmailUser(user, name);
    } catch (err) {
      this.handleLoginError(err);
    }
  }

  async validateUsername(name) {
    const uniqueusername = await this.db
      .collection("users")
      .where("usernamelowerCase", "==", name.toLowerCase())
      .get();

    if (uniqueusername.docs.length) throw new Error("username already exists");
    if (name.replace(/ /g, "") === "") throw new Error("username is empty");
  }

  async createUniqueUsername({ displayName, email, uid }) {
    try {
      await this.validateUsername(displayName);
      return displayName;
    } catch (err) {
      try {
        await this.validateUsername(email.split("@")[0]);
        return email.split("@")[0];
      } catch (err) {
        try {
          await this.validateUsername(uid.slice(0, 8));
          return uid.slice(0, 8);
        } catch (err) {
          await this.validateUsername(uid);
          return uid;
        }
      }
    }
  }

  async registerNonEmailUser(newUser, name) {
    await this.db.doc(`users/${newUser.uid}`).set(
      {
        username: name,
        usernamelowerCase: name?.toLowerCase(),
      },
      { merge: true }
    );
    await newUser.sendEmailVerification();
    await newUser.updateProfile({
      displayName: name,
    });
  }

  handleLoginError(error) {
    console.debug(`login error`, error);
  }

  async logout() {
    await this.auth.signOut();
  }

  async resetPassword(email) {
    await this.auth.sendPasswordResetEmail(email);
  }
}

const firebase = new Firebase();
export default firebase;
