import React, { useEffect, useState } from "react";
import FirebaseContext from "../firebase/context";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useParams, useHistory, Link } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";

//Moment
import moment from "moment";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import LinkIcon from "@material-ui/icons/Link";
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnRounded";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AccessTimeRoundedIcon from "@material-ui/icons/AccessTimeRounded";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AttachMoneyRoundedIcon from "@material-ui/icons/AttachMoneyRounded";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import Modal from "@material-ui/core/Modal";
import EditIcon from "@material-ui/icons/Edit";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { useTranslation } from "react-i18next";
import Drawer from "@material-ui/core/Drawer";
import { DeleteOutline } from "@material-ui/icons";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Fade, SwipeableDrawer } from "@material-ui/core";
import { emitCloseDrawerEvent } from "../hooks/useDrawer";
import vibrate from "../util/feedback";
import InnerMap from "./InnerMap";

export default function EventPost(props) {
  const { firebase, user } = React.useContext(FirebaseContext);
  const { t, i18n } = useTranslation();
  const _params = useParams();
  const params = props.params || _params;
  const history = useHistory();
  const SwiperArea = document.getElementById("swipareadiv");
  const [edit, setEdit] = useState(false);
  const [createstate, setCreatestate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [more, setMore] = useState(false);
  const [refreshDrawer, setRefreshDrawer] = useState(false);

  const [eventdata, loading] = useDocumentData(
    firebase.db.doc(`b2bposts/${params.id}`)
  );

  useEffect(() => {
    firebase.db.doc(`b2bposts/${params.id}`).update({
      views: firebase.FieldValue.arrayUnion(firebase.auth.currentUser.uid),
    });
  }, [
    firebase.FieldValue,
    firebase.auth.currentUser.uid,
    firebase.db,
    params.id,
  ]);

  useEffect(() => {
    if (!loading && !eventdata) {
      history.push("/Home");
      emitCloseDrawerEvent();
    }
  });

  function ScrollFixer() {
    //document.scrollingElement.scrollTo(0, 0);
  }

  const url = eventdata?.link && new URL(eventdata?.link);

  function callPhone() {
    if (process.env.NODE_ENV === "development") {
      window.open(`tel:${eventdata.telefon}`, "_system");
    } else if (window?.cordova?.platformId === "ios") {
      window.plugins.CallNumber.callNumber(
        onSuccess,
        onError,
        eventdata?.telefon
      );
    } else {
      window.open(`tel:${eventdata.telefon}`, "_system");
    }

    function onSuccess(result) {
      console.log("Success:" + result);
    }

    function onError(result) {
      console.log("Error:" + result);
    }
  }

  const [userData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );
  const favouritelist = (userData && userData.favourites) || [];
  const promotions = (userData && userData.promotions) || 0;
  const [saved, setSaved] = useState(false);

  function refreshPromotion() {
    firebase.db.doc(`b2bposts/${params.id}`).update({
      date: new Date(),
    });
    firebase.db
      .doc(`users/${user && user.uid}`)
      .update({ promotions: promotions - 1 });
    setRefreshDrawer(false);
    setEdit(false);
  }

  async function handleFavourite() {
    setTimeout(() => {
      vibrate("heavy");
    }, 125);
    vibrate("heavy");
    await firebase.db
      .doc(`users/${user && user.uid}`)
      .update("favourites." + params.id, eventdata.date)
      .then(
        setSaved(true),
        setTimeout(() => {
          setSaved(false);
        }, 1000)
      );
  }

  async function handleRemoveFavourite() {
    await firebase.db
      .doc(`users/${user && user.uid}`)
      .update("favourites." + params.id, false)
      .then(() => console.log("Removed!"));
  }

  async function handleDelete(uid) {
    setEdit(false);
    setOpenModal(true);
    setTimeout(() => {
      const postRef = firebase.db.doc(`b2bposts/${uid}`);
      props.onClose?.();
      setTimeout(() => {
        postRef
          .delete()
          .then(() => {
            console.log(`Document deleted`);
          })
          .catch((err) => {
            console.error("Error deleting document:", err);
          });
      }, 1000);
    }, 500);
  }

  const openLink = () => {
    if (window?.cordova?.platformId === "ios") {
      window?.cordova?.InAppBrowser.open(eventdata?.link, "_system");
    } else {
      navigator.app.loadUrl(eventdata?.link, { openExternal: true });
    }
  };

  if (!eventdata) return null;
  return (
    <>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        open={saved}
        onClose={() => setSaved(false)}
      >
        <Fade in={saved}>
          <span className="savedmodal"> {t("saved")} </span>
        </Fade>
      </Modal>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="modalwarning" style={{ padding: "20px" }}>
          <CircularProgress style={{ color: "#fd7f4d" }} />
        </div>
      </Modal>
      <SwipeableDrawer
        PaperProps={{
          elevation: 0,
          style: {
            background: "#262626",
            width: "100%",
            borderRadius: "20px 20px 0 0",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="bottom"
        open={refreshDrawer}
        disableSwipeToOpen
        onClose={() => setRefreshDrawer(false)}
      >
        <div className="businessInfoWrapper">
          <span className="swipeline" />
          <h2>{t("refresh_post")}</h2>
          <p>{t("refresh_post_text")}</p>
          <button onClick={refreshPromotion} className="paymentbutton free">
            {t("refresh_button_text")} <br /> <span>0,00€ / 1 Post</span>
          </button>
        </div>
      </SwipeableDrawer>

      <Drawer
        anchor="bottom"
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            backgroundColor: "black",
          },
        }}
        open={edit}
        onClose={() => setEdit(false)}
      >
        <div className="editdrawer">
          <div
            id="createtab"
            className={createstate ? "createAdrivePost" : "deActive"}
          >
            <div
              className={createstate ? "createInner" : "createInnerdeActive"}
            >
              <div className="addheader">
                <div className="headtitleparty">
                  <div
                    onClick={() => {
                      setEdit(false);
                      setAnimation((prevState) => !prevState);
                    }}
                    className="backlink"
                  >
                    <ArrowBackIosRoundedIcon style={{ color: "#FD7F4D" }} />
                  </div>
                  <h2>
                    <EditIcon />
                  </h2>
                  {/* <button
                      onClick={handleUpdate}
                      className="poster buttonanimation"
                    >
                      {t("edit")}
                    </button> */}
                </div>
              </div>
              <div className="editEventWrapper">
                {new Date() - eventdata?.date.toDate() < 1000 * 60 * 60 * 24 ? (
                  <div className="activeLabel">
                    <FiberManualRecordIcon
                      style={{ fontSize: "17px", margin: "0 3px" }}
                    />{" "}
                    Live
                  </div>
                ) : null}
                <div className="postviews">
                  <h2>{eventdata?.views?.length || 0}</h2>
                  <h2>Views</h2>
                </div>
                {new Date() - eventdata?.date.toDate() <
                1000 * 60 * 60 * 24 ? null : (
                  <div
                    onClick={() => setRefreshDrawer(true)}
                    className="activedriver"
                    style={{ zIndex: "1", marginTop: "20px" }}
                  >
                    <RefreshIcon style={{ fontSize: "28px" }} />
                  </div>
                )}
                <div
                  onClick={() => handleDelete(params.id)}
                  className="activedriver"
                  style={{ zIndex: "1", marginTop: "20px" }}
                >
                  <DeleteOutline style={{ fontSize: "28px" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>

      <div className="DrivePostWrapper">
        <div className="overflowtop">
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "70px",
            }}
            id="progress"
          ></div>
          <div
            onClick={() => {
              props.onClose?.();
            }}
          >
            <ArrowBackIosRoundedIcon
              style={{ fontSize: "25px" }}
              className="postheaderbackicon"
            />
          </div>
          {eventdata.id === firebase.auth.currentUser.uid ? (
            <button
              onClick={() => {
                ScrollFixer();
                setEdit(true);
              }}
              className="posteditbutton"
            >
              <EditIcon style={{ fontSize: "20px", marginLeft: "4px" }} />
              <span className="editspan">Edit</span>
            </button>
          ) : (
            ""
          )}
        </div>
        <div
          className="DrivePostHeader Event"
          style={{
            backgroundImage: `url(${eventdata.image})`,
            backgroundPosition: "center",
          }}
        ></div>
        <div className="overflow"></div>
        <div style={{ paddingBottom: "100px" }} className="DrivePostBody">
          {favouritelist?.[params.id] ? (
            <h4 className="eventfavourite" onClick={handleRemoveFavourite}>
              <FavoriteIcon style={{ fontSize: "30px" }} />
            </h4>
          ) : (
            <h4 className="eventfavourite" onClick={handleFavourite}>
              <FavoriteBorderIcon style={{ fontSize: "30px" }} />
            </h4>
          )}
          <h4 className="eventphonecall" onClick={callPhone}>
            <LocalPhoneIcon style={{ fontSize: "30px" }} />
          </h4>
          <div className="DrivePostBodyMain">
            <h1>{moment(eventdata.date.toDate()).format("DD")}</h1>
            <h2>{moment(eventdata.date.toDate()).format("MMMM")}</h2>
            <h3> {eventdata.clubname} </h3>
          </div>
          <div className="divertwo"></div>
          <div className="DrivePostInfo">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                fontSize: "16px",
                margin: "10px 0",
                color: "#A5A5A5",
              }}
            >
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "18px",
                  margin: "5px 0",
                  wordBreak: "break-word",
                }}
              >
                <LocationOnRoundedIcon
                  style={{ fontSize: "28px", marginRight: "10px" }}
                />{" "}
                {eventdata.address}, {eventdata.city}
              </p>
            </div>
          </div>
          <div className="divertwo"></div>
          <div className="DrivePostInfo">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                fontSize: "16px",
                margin: "10px 0",
                color: "#A5A5A5",
              }}
            >
              <p
                style={{
                  margin: "10px 0",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "18px",
                }}
              >
                <DateRangeIcon style={{ marginRight: "10px" }} />{" "}
                {moment(eventdata.date.toDate()).format("DD.MM.YYYY")}{" "}
              </p>
              <p
                style={{
                  margin: "10px 0",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "18px",
                  wordBreak: "break-word",
                  marginLeft: "40px",
                }}
              >
                <AccessTimeRoundedIcon style={{ marginRight: "10px" }} />{" "}
                {eventdata.opens}
              </p>
            </div>
          </div>

          <div className="divertwo"></div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              width: "100%",
              fontSize: "16px",
              margin: "10px 0",
              color: "#A5A5A5",
            }}
          >
            <p
              style={{
                margin: "10px 0",
                display: "flex",
                alignItems: "center",
                fontSize: "18px",
                wordBreak: "break-word",
              }}
            >
              <AttachMoneyRoundedIcon style={{ marginRight: "5px" }} />{" "}
              {eventdata.entranceprice}
            </p>
            <p
              style={{
                display: "flex",
                alignItems: "flex-start",
                fontSize: "18px",
                wordBreak: "break-word",
                maxWidth: "250px",
                margin: "10px 0",
                marginLeft: "40px",
              }}
            >
              <InfoOutlinedIcon style={{ marginRight: "10px" }} />
              {eventdata?.topic?.join(", ")}
            </p>
          </div>

          {eventdata.info && (
            <div className="DrivePostEvent Info">
              {eventdata.info.length >= 57 ? (
                <p style={{ margin: "5px 0" }}>
                  {!more ? (
                    <span style={{ color: "white" }}>
                      {eventdata.info.substring(0, [57])}{" "}
                      <span
                        style={{ color: "#a5a5a5" }}
                        onClick={() => setMore(true)}
                      >
                        ...{t("more")}
                      </span>
                    </span>
                  ) : (
                    <span style={{ color: "white" }}>{eventdata.info}</span>
                  )}
                </p>
              ) : (
                <p style={{ margin: "5px 0" }}>
                  <span style={{ color: "white" }}>{eventdata.info}</span>
                </p>
              )}
            </div>
          )}

          <InnerMap
            lat={eventdata?.lat}
            lng={eventdata?.long}
            image={eventdata?.image}
          />

          {eventdata.link && (
            <div
              className="activedriver"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={openLink}
            >
              <LinkIcon
                style={{ marginRight: "5px", transform: "rotate(-45deg)" }}
              />
              {url?.host.length > 18
                ? `${url?.host.substring(0, [17])}...`
                : url?.host}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
