import React, { useRef } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import Avatar from "@material-ui/core/Avatar";
import FirebaseContext from "../firebase/context";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import { formatDistanceStrict } from "date-fns";
import { de, enUS } from "date-fns/locale";
import { useTranslation } from "react-i18next";

export default function FriendRequestAccepted({ data, key, onclick }) {
  const { firebase, user } = React.useContext(FirebaseContext);
  const [userData = {}] = useDocumentData(firebase.db.doc(`users/${data?.id}`));
  const element = useRef();

  const { i18n } = useTranslation();
  const lang =
    i18n.language === "en" ? enUS : i18n.language === "de" ? de : enUS;

  function RemoveRequest() {
    firebase.db.doc(`users/${user && user.uid}`).update({
      chatMessageNotifications: firebase.FieldValue.arrayRemove(data),
    });
  }

  return (
    <div
      className="singlenotification"
      ref={element}
      onClick={() => {
        onclick();

        setTimeout(() => {
          RemoveRequest();
        }, 400);
      }}
      key={key}
    >
      <Avatar
        alt="Profile"
        src={userData?.imgRef}
        style={{ height: "3rem", width: "3rem" }}
      />{" "}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          marginLeft: "10px",
        }}
      >
        <p
          style={{
            maxWidth: "initial",
            marginLeft: "0",
            color: "white",
            fontWeight: "800",
          }}
        >
          {data?.subject?.length > 15
            ? `${data?.subject?.substring(0, [15])}...`
            : data?.subject}
          <span>
            {" "}
            •{" "}
            {formatDistanceStrict(
              new Date(data?.messageDate?.seconds * 1000),
              new Date(),
              {
                locale: lang,
              }
            )}
          </span>
        </p>
        <span style={{ marginTop: "5px", color: "white", fontWeight: "800" }}>
          {data?.text?.length > 37
            ? `${data?.text?.substring(0, [37])}...`
            : data?.text}
        </span>
        <span className="chatdot" />
      </div>
    </div>
  );
}
