import React from "react";
//FIREBASE
import FirebaseContext from "../firebase/context";
import Avatar from "./AvatarComponentMini";
import { formatDistanceStrict } from "date-fns";
import { de, enUS } from "date-fns/locale";
import { useTranslation } from "react-i18next";

export default function Message({ ...message }) {
  const { firebase } = React.useContext(FirebaseContext);
  const { i18n } = useTranslation();
  const lang =
    i18n.language === "en" ? enUS : i18n.language === "de" ? de : enUS;

  return (
    <div className="singlemessage">
      {firebase.auth.currentUser.uid === message.uid ? (
        ""
      ) : (
        <span>
          <Avatar driverid={message.uid} />
        </span>
      )}
      <div className="messagetextwrapper">
        {firebase.auth.currentUser.uid === message.uid ? (
          ""
        ) : (
          <span className="messagename">
            {message.displayName && message.displayName.length > 18
              ? `${message.displayName.substring(0, [17])}...`
              : message.displayName}
          </span>
        )}
        <p
          style={
            firebase.auth.currentUser.uid === message.uid
              ? { marginLeft: "0" }
              : { marginLeft: "10px" }
          }
        >
          {message?.text} <br />{" "}
          <span
            style={
              firebase.auth.currentUser.uid === message.uid
                ? { justifyContent: "flex-end" }
                : { justifyContent: "flex-start" }
            }
          >
            {formatDistanceStrict(
              new Date(message?.createdAt.seconds * 1000),
              new Date(),
              {
                locale: lang,
              }
            )}
          </span>
        </p>
      </div>
    </div>
  );
}
