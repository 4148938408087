/* eslint-disable no-undef */
import { useCallback } from "react";
import { SwipeableDrawer } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { useTranslation } from "react-i18next";

export default function CamUpload({
  user,
  firebase,
  targetWidth,
  targetHeight,
  setLoader,
  onClose,
  open,
  profileImage,
  setPropImage,
  setImgRef,
}) {
  const { t } = useTranslation();
  const onSuccess = useCallback(
    async (takenPicture) => {
      setLoader(true);
      const image = "data:image/png;base64," + takenPicture;
      const Hash = Date.now();

      await firebase.storage
        .ref(`images/${user?.uid}/${Hash}.jpg`)
        .putString(image, "data_url")
        .then(async () => {
          const imgRef = `https://firebasestorage.googleapis.com/v0/b/exude-hopon.appspot.com/o/images%2F${user?.uid}%2F${Hash}.jpg?alt=media`;

          if (profileImage) {
            await firebase.db
              .collection("users")
              .doc(user?.uid)
              .update({ imgRef });
            setLoader(false);
            onClose();
          } else {
            setLoader(false);
            onClose();
            setPropImage(image);
            setImgRef(imgRef);
          }
        });
    },
    [
      setLoader,
      firebase.storage,
      firebase.db,
      user?.uid,
      profileImage,
      onClose,
      setPropImage,
      setImgRef,
    ]
  );

  const onFail = useCallback(
    (message) => {
      console.log("Camera was closed or something went wrong: ", message);
      setLoader(false);
    },
    [setLoader]
  );

  const handlePicture = useCallback(
    (option) => {
      var options = {
        destinationType: Camera.DestinationType.DATA_URL,
        sourceType:
          option === "camera"
            ? Camera.PictureSourceType.CAMERA
            : Camera.PictureSourceType.PHOTOLIBRARY,
        allowEdit: window?.cordova.platformId === "ios",
        encodingType: Camera.EncodingType.JPEG,
        targetWidth: targetWidth,
        targetHeight: targetHeight,
        quality: 90,
        popoverOptions: CameraPopoverOptions,
        saveToPhotoAlbum: false,
        correctOrientation: true,
      };
      navigator.camera.getPicture(onSuccess, onFail, options);
    },
    [targetWidth, targetHeight, onSuccess, onFail]
  );

  return (
    <SwipeableDrawer
      style={{ zIndex: 200 }}
      PaperProps={{
        elevation: 0,
        style: {
          background: "#262626",
          width: "100%",
          borderRadius: "20px 20px 0 0",
          marginTop: "env(safe-area-inset-top)",
        },
      }}
      anchor="bottom"
      open={open}
      disableSwipeToOpen
      onClose={onClose}
    >
      <div className="uploadchoicewrapper">
        <span className="swipeline"></span>
        <div style={{ width: "calc(100% - 40px)" }}>
          <div className="singlechoice" onClick={() => handlePicture("camera")}>
            {t("camera")} <CameraAltIcon style={{ fontSize: "35px" }} />
          </div>
          <div
            className="singlechoice"
            onClick={() => handlePicture("gallery")}
          >
            {t("gallery")} <ImageIcon style={{ fontSize: "35px" }} />
          </div>
        </div>
      </div>
    </SwipeableDrawer>
  );
}
