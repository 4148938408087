import React from "react";
import FirebaseContext from "../firebase/context";
import { useDocumentData } from "react-firebase-hooks/firestore";

export default function NameGetter({ id }) {
  const { firebase } = React.useContext(FirebaseContext);
  const [userData] = useDocumentData(firebase.db.doc(`users/${id}`));
  return (
    <h2>
      {userData?.username && userData?.username.length > 10
        ? `${userData?.username.substring(0, [9])}...`
        : userData?.username}
    </h2>
  );
}
