import React, { useEffect, useState } from "react";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";

export default function BugInfo() {
  const [bugInfo, setBugInfo] = useState(false);

  useEffect(() => {
    const Timer = setTimeout(() => {
      setBugInfo(true);
    }, 8000);

    return () => clearTimeout(Timer);
  }, []);

  if (!bugInfo) return null;

  return (
    <div
      className="bugInfoWrapper"
      style={
        bugInfo
          ? { opacity: 1 }
          : {
              opacity: 0,
              userSelect: "none",
              zIndex: 0,
            }
      }
    >
      <ClearRoundedIcon
        onClick={() => setBugInfo(false)}
        style={{
          fontSize: "25px",
          color: "white",
          position: "absolute",
          top: "10px",
          right: "10px",
        }}
      />
      <p>If the loading takes a while, try restarting the App.</p>
    </div>
  );
}
