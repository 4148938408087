import React, { useState } from "react";
import FirebaseContext from "../firebase/context";
import { useTranslation } from "react-i18next";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import SeatRequest from "./SeatRequest";
import Kicked from "./Kicked";
import AcceptedRequest from "./Accepted";
import DeclinedRequest from "./DeclinedRequest";
import DeclinedEvent from "./DeclinedEvent";
import FriendRequest from "./FriendRequest";
import FriendRequestAccepted from "./FriendRequestAccepted";
import PartyInvite from "./PartyInvite";
import EventRequest from "./EventRequest";
import Declined from "./Declined";
import KickedParty from "./KickedParty";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import { SwipeableDrawer } from "@material-ui/core";
import PartyPost from "./PartyPost";
import Userpage from "./Userpage";
import ChatNotification from "./ChatNotification";
import Chatroom from "./Chatroom";

export default function NotificationPage(props) {
  const { t, i18n } = useTranslation();
  const { firebase, user } = React.useContext(FirebaseContext);

  const date = React.useMemo(() => {
    const result = new Date();
    result.setDate(result.getDate() - 1);
    return result;
  }, []);

  const [userData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );
  const [seatData] = useCollection(
    firebase.db.collection(`drivers`).where("id", "==", user?.uid || "")
  );
  const [partyData] = useCollection(
    firebase.db.collection("parties").where("date", ">", date)
  );
  const [guestData] = useCollection(
    firebase.db.collection(`parties`).where("id", "==", user?.uid || "")
  );

  const kicks = userData && userData.kicked;
  const accepted = userData && userData.accepted;
  const declinedRequests = userData && userData.declinedRequests;
  const friendrequests = (userData && userData.friendRequests) || [];
  const friendRequestsAccepted =
    (userData && userData.friendRequestsAccepted) || [];
  const declinedEventRequests = userData && userData.declinedEventRequests;
  const declines = userData && userData.declines;
  const kickedPartys = userData && userData.kickedParty;

  const partyInvites =
    partyData &&
    partyData.docs.filter((party) => {
      if (party.data().guests.includes(firebase.auth.currentUser.uid)) {
        return party;
      }
    });

  const chatNotificationData = userData?.chatMessageNotifications?.filter(
    (notification) => {
      if (
        notification &&
        new Date() - notification?.postDate.toDate() < 1000 * 60 * 60 * 24
      ) {
        return notification;
      }
    }
  );

  const [friendPartypostDrawer, setFriendPartypostDrawer] = useState({});
  const [userPage, setUserPage] = useState({});
  const [chatroom, setChatroom] = useState(false);
  const [chatroomData, setChatroomData] = useState({});

  return (
    <>
      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 200 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={friendPartypostDrawer.open}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() =>
          setFriendPartypostDrawer((prev) => ({ ...prev, open: false }))
        }
      >
        <PartyPost
          params={{ id: friendPartypostDrawer.id }}
          noPaddingBottom
          onClose={() =>
            setFriendPartypostDrawer((prev) => ({ ...prev, open: false }))
          }
        ></PartyPost>
      </SwipeableDrawer>

      <SwipeableDrawer
        open={chatroom}
        onClose={() => setChatroom(false)}
        anchor="right"
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "transparent",
            width: "100vw",
            height: "100%",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
      >
        <Chatroom
          onClose={() => setChatroom(false)}
          params={chatroomData.params}
          guests={chatroomData.guests}
          adminId={chatroomData.adminId}
          subject={chatroomData.subject}
          date={chatroomData.date}
        />
      </SwipeableDrawer>

      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 200 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={userPage.open}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setUserPage((prev) => ({ ...prev, open: false }))}
      >
        <Userpage
          params={{ UserId: userPage.id }}
          noPaddingBottom
          onClose={() => setUserPage((prev) => ({ ...prev, open: false }))}
        ></Userpage>
      </SwipeableDrawer>

      <div className="notificationpage">
        <div className="notificationheader">
          <ArrowBackIosRoundedIcon
            onClick={() => props.onClose()}
            style={{ fontSize: "25px", color: "white" }}
          />
          <h2>{t("notification")}</h2>
        </div>
        <div className="notificationbody">
          {chatNotificationData?.map((notification, key) => (
            <ChatNotification
              onclick={() => {
                setChatroomData(() => ({
                  params: notification?.postId,
                  date: notification?.postDate,
                  guests: notification?.guests,
                  adminId: notification?.adminId,
                  subject: notification?.subject,
                }));
                setChatroom(true);
              }}
              data={notification}
              key={key}
            />
          ))}
          {seatData &&
            seatData.docs.map((document, key) =>
              (document.data().seatRequests || []).map((request) => (
                <SeatRequest
                  key={request}
                  docid={document.id}
                  uid={request}
                  onClick={() => setUserPage({ id: request, open: true })}
                />
              ))
            )}
          {kicks &&
            kicks.map((kick) => (
              <Kicked
                onClick={() => setUserPage({ id: kick, open: true })}
                key={kick}
                uid={kick}
              />
            ))}
          {accepted &&
            accepted.map((kick) => (
              <AcceptedRequest
                key={kick}
                uid={kick}
                onClick={() => setUserPage({ id: kick, open: true })}
              />
            ))}
          {declinedRequests &&
            declinedRequests.map((kick) => (
              <DeclinedRequest
                key={kick}
                uid={kick}
                onClick={() => setUserPage({ id: kick, open: true })}
              />
            ))}
          {friendrequests &&
            friendrequests.map((request, key) => (
              <FriendRequest
                key={request}
                uid={request}
                onClick={() => setUserPage({ id: request, open: true })}
              />
            ))}
          {friendRequestsAccepted &&
            friendRequestsAccepted.map((request, key) => (
              <FriendRequestAccepted
                key={request}
                uid={request}
                onClick={() => setUserPage({ id: request, open: true })}
              />
            ))}
          {partyInvites &&
            (partyInvites.length
              ? partyInvites.map((party, index) => (
                  <PartyInvite
                    onAccept={() => {
                      setFriendPartypostDrawer({
                        open: true,
                        id: party.id,
                      });
                    }}
                    key={party.id}
                    party={party.id}
                    uid={party.data().id}
                  />
                ))
              : "")}

          {guestData &&
            guestData.docs.map((document, key) =>
              (document.data().guestRequests || []).map((request) => (
                <EventRequest
                  key={request}
                  docid={document.id}
                  uid={request}
                  onClick={() => setUserPage({ id: request, open: true })}
                />
              ))
            )}

          {declinedEventRequests &&
            declinedEventRequests.map((decline) => (
              <DeclinedEvent
                key={decline}
                uid={decline}
                onClick={() => setUserPage({ id: decline, open: true })}
              />
            ))}

          {declines &&
            declines.map((decline) => (
              <Declined
                key={decline}
                uid={decline}
                onClick={() => setUserPage({ id: decline, open: true })}
              />
            ))}
          {kickedPartys &&
            kickedPartys.map((kick) => (
              <KickedParty
                key={kick}
                uid={kick}
                onClick={() => setUserPage({ id: kick, open: true })}
              />
            ))}
        </div>
      </div>
    </>
  );
}
