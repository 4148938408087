import React from "react";
import FirebaseContext from "../firebase/context";
import { useDocumentData } from "react-firebase-hooks/firestore";
//i18n
import i18next from "i18next";
import { useTranslation } from "react-i18next";
//Mat-UI
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";

const languages = [
  {
    code: "de",
    name: "German",
  },
  {
    code: "en",
    name: "English",
  },
];

export default function Languages() {
  const { firebase, storage, user } = React.useContext(FirebaseContext);
  const [userData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  function changeLanguage(code) {
    i18next.changeLanguage(code);
    firebase.db.doc(`users/${user && user.uid}`).update({ language: code });
  }

  return (
    <div className="languagebox">
      <div className="languagehead">
        <span className="swipeline"></span>
        {/* <h2>{t("language")}</h2> */}
      </div>

      {languages.map(({ code, name }) => (
        <div
          key={code}
          className="languageboxsingle"
          onClick={() => changeLanguage(code)}
        >
          <p>{name}</p>
          {lang === code ? (
            <CheckRoundedIcon
              style={{ marginRight: "30px", color: "rgb(253, 127, 77)" }}
            />
          ) : (
            ""
          )}
        </div>
      ))}
    </div>
  );
}
