import React, { useState } from "react";
import FirebaseContext from "../firebase/context";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import { useDocumentData } from "react-firebase-hooks/firestore";
import AvatarComponent from "./AvatarComponentPost";
import AvatarComponentFriends from "./AvatarComponentFriends";
import AvatarComponentSeat from "./AvatarComponentSeat";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import InstagramIcon from "@material-ui/icons/Instagram";
import snapchatcustomprofilelink from "../images/snapchatcustomprofilelink.svg";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import CalendarMonthIcon from "@material-ui/icons/DateRange";
import NameGetter from "./NameGetter";
import Favourite from "./Favourite";
import Modal from "@material-ui/core/Modal";
import {
  Collapse,
  Drawer,
  Fade,
  IconButton,
  Slide,
  SwipeableDrawer,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import EventPostPage from "./EventPost";
import useCloseDrawers from "../hooks/useDrawer";
import vibrate from "../util/feedback";
import moment from "moment";

export default function Userpage(props) {
  const { firebase, user } = React.useContext(FirebaseContext);
  const { t } = useTranslation();

  const _params = useParams();
  const params = props.params || _params;

  const [settings, setSettings] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [copiedAlert, setCopiedAlert] = useState(false);
  const [reportSuccess, setReportSuccess] = useState(false);
  const [mutualFriend, setMutualFriend] = useState({});
  const [eventPage, setEventPage] = useState({});
  const [preview, setPreview] = useState(false);

  //Navigationprops

  const [myData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );
  const [userData] = useDocumentData(firebase.db.doc(`users/${params.UserId}`));
  const friendrequests = (myData && myData.friendRequests) || [];
  const friendslist = (myData && myData.friends) || [];
  const favouritelist = (userData && userData.favourites) || [];

  const favouritemap =
    favouritelist &&
    Object.entries(favouritelist)
      .filter(
        ([_, date]) => date && new Date() - date.toDate() < 1000 * 60 * 60 * 24
      )
      .map(([favourite], index) => {
        return (
          <Favourite
            key={favourite}
            uid={favourite}
            onClick={() => setEventPage({ id: favourite, open: true })}
          />
        );
      });

  const friendInstagram = userData && userData.instagram;
  const friendSnapchat = userData && userData.snapchat;

  function removeRequest(user) {
    firebase.db.doc(`users/${user}`).update({
      friendRequests: firebase.FieldValue.arrayRemove(
        firebase.auth.currentUser.uid
      ),
    });
  }

  function handleFriendRemove(remover) {
    firebase.db
      .doc(`users/${remover}`)
      .update({
        friends: firebase.FieldValue.arrayRemove(firebase.auth.currentUser.uid),
      })
      .then(() => {
        firebase.db
          .doc(`users/${user && user.uid}`)
          .update({ friends: firebase.FieldValue.arrayRemove(remover) });
      })
      .then(() => setDeleteModal(false));
  }

  function handleFriendRequest(user) {
    firebase.db.doc(`users/${user}`).update({
      friendRequests: firebase.FieldValue.arrayUnion(
        firebase.auth.currentUser.uid
      ),
    });
  }

  function acceptRequest() {
    firebase.db
      .doc(`users/${user && user.uid}`)
      .update({
        friendRequests: firebase.FieldValue.arrayRemove(params.UserId),
      })
      .then(() => {
        firebase.db
          .doc(`users/${user && user.uid}`)
          .update({ friends: firebase.FieldValue.arrayUnion(params.UserId) });
        firebase.db
          .doc(`users/${params.UserId}`)
          .update({ friends: firebase.FieldValue.arrayUnion(user.uid) });
        firebase.db.doc(`users/${params.UserId}`).update({
          friendRequestsAccepted: firebase.FieldValue.arrayUnion(user.uid),
        });
      });
  }

  function copieAlert() {
    setCopiedAlert(true);
    setTimeout(() => {
      setCopiedAlert(false);
    }, 1200);
  }

  async function reportUser() {
    await firebase.db.collection("user-reports").add({
      reportedUser: userData?.username,
      reportedId: params.UserId,
      reportedBy: user?.uid,
      date: new Date(),
    });
    setSettings(false);
    setReportSuccess(true);

    setTimeout(() => {
      setReportSuccess(false);
    }, 1500);
  }

  useCloseDrawers({
    drawers: [
      () => setMutualFriend((prev) => ({ ...prev, open: false })),
      () => setEventPage((prev) => ({ ...prev, open: false })),
    ],
  });

  return (
    <>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        open={reportSuccess}
        onClose={() => setReportSuccess(false)}
      >
        <Fade in={reportSuccess}>
          <span className="savedmodal"> {t("user_reported")} </span>
        </Fade>
      </Modal>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        open={preview}
        onClose={() => setPreview(false)}
      >
        <Slide direction="up" in={preview}>
          <div className="previewwrapper">
            <div
              style={{
                background: `url(${userData && userData.imgRef})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              className="profilepicturewrapper"
              onClick={() => setPreview(false)}
            >
              <p>
                {userData?.username
                  ? userData.username.length > 45
                    ? `${userData.username.substring(0, [44])}...`
                    : userData.username
                  : "..."}
              </p>
            </div>
            <span onClick={() => setPreview(false)} className="previewcloser">
              <KeyboardArrowDownRoundedIcon style={{ fontSize: "45px" }} />
            </span>
          </div>
        </Slide>
      </Modal>
      <SwipeableDrawer
        transitionDuration={300}
        style={props.noPaddingBottom ? { zIndex: 200 } : { zIndex: 199 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={mutualFriend.open}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setMutualFriend((prev) => ({ ...prev, open: false }))}
      >
        <Userpage
          noPaddingBottom={props.noPaddingBottom}
          params={{ UserId: mutualFriend.id }}
          onClose={() => setMutualFriend((prev) => ({ ...prev, open: false }))}
        ></Userpage>
      </SwipeableDrawer>

      <SwipeableDrawer
        transitionDuration={300}
        style={props.noPaddingBottom ? { zIndex: 200 } : { zIndex: 199 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={eventPage.open}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setEventPage((prev) => ({ ...prev, open: false }))}
      >
        <EventPostPage
          params={{ id: eventPage.id }}
          onClose={() => setEventPage((prev) => ({ ...prev, open: false }))}
        ></EventPostPage>
      </SwipeableDrawer>

      <div className="userprofileouterwrapper">
        <div className="userprofilewrapper">
          <div className="overflowtop">
            <div onClick={props.onClose}>
              <ArrowBackRoundedIcon
                style={{ fontSize: "25px" }}
                className="postheaderbackicon"
              />
            </div>

            {params.UserId === firebase.auth.currentUser.uid ? (
              ""
            ) : (
              <div
                className="userprofileoptions"
                onClick={() => {
                  vibrate("light");
                  setSettings(true);
                }}
              >
                <MoreHorizRoundedIcon style={{ fontSize: "25px" }} />
              </div>
            )}
          </div>
          <div
            className="userprofileheader"
            style={{
              backgroundImage: `url(${userData && userData.imgRef})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              filter: "blur(4px)",
            }}
          ></div>
          <div
            className="userprofilemain"
            style={props.noPaddingBottom ? { marginBottom: "0" } : {}}
          >
            <div className="userprofilemainheader">
              <span className="previewclicker" onClick={() => setPreview(true)}>
                <AvatarComponent driverid={params.UserId} />
              </span>
            </div>
            <div className="userprofileinfoblock">
              <h2>
                {userData?.username
                  ? userData.username.length > 14
                    ? `${userData.username.substring(0, [13])}...`
                    : userData.username
                  : "..."}
                <br />
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#727476",
                    margin: "0",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                >
                  <CalendarMonthIcon
                    style={{
                      color: "#727476",
                      fontSize: "18px",
                      marginRight: "3px",
                    }}
                  />{" "}
                  {t("joined")}{" "}
                  {moment(userData?.createdAt?.toDate()).format("MMMM YYYY")}
                </p>
              </h2>
            </div>
            <div className="userprofilesocials">
              {friendInstagram ? (
                <span>
                  <a
                    style={{ textDecoration: "none", color: "grey" }}
                    href={friendInstagram}
                  >
                    <InstagramIcon
                      style={{
                        fontSize: "18px",
                        color: "#6f7376",
                        marginRight: "3px",
                      }}
                    />
                    <span style={{ color: " #3190de", margin: "0" }}>
                      {friendInstagram.length > 25
                        ? `${friendInstagram.substring(0, [24])}...`
                        : friendInstagram}
                    </span>
                  </a>
                </span>
              ) : (
                ""
              )}
              {friendSnapchat ? (
                <span>
                  <a
                    style={{ textDecoration: "none", color: "grey" }}
                    href={friendSnapchat}
                  >
                    <img
                      alt="snapchat"
                      src={snapchatcustomprofilelink}
                      style={{ width: "18px", marginRight: "3px" }}
                    />
                    <span style={{ color: " #3190de", margin: "0" }}>
                      {friendSnapchat.length > 25
                        ? `${friendSnapchat.substring(0, [24])}...`
                        : friendSnapchat}
                    </span>
                  </a>
                </span>
              ) : (
                ""
              )}
            </div>
            <div style={{ position: "absolute", top: "15px", right: "20px" }}>
              {params.UserId === firebase.auth.currentUser.uid ? (
                <button
                  style={{ opacity: ".3" }}
                  className="addfriendbutton userprofile"
                >
                  <AddRoundedIcon />
                  {t("adding_button")}
                </button>
              ) : friendrequests?.includes(params.UserId) ? (
                <button
                  onClick={() => {
                    vibrate("medium");
                    acceptRequest(params.UserId);
                  }}
                  className="addfriendbutton userprofile"
                >
                  <AddRoundedIcon /> {t("accept")}
                </button>
              ) : userData?.friendRequests?.includes(
                  firebase.auth.currentUser.uid
                ) ? (
                <button
                  onClick={() => removeRequest(params.UserId)}
                  className="addfriendbutton request userprofile"
                >
                  {t("requested")}
                </button>
              ) : userData?.friends?.includes(firebase.auth.currentUser.uid) ? (
                <button
                  className="addfriendbutton request userprofile"
                  onClick={() => setDeleteModal(true)}
                >
                  {t("remove_big")}
                </button>
              ) : (
                <button
                  className="addfriendbutton userprofile"
                  onClick={() => {
                    vibrate("medium");
                    handleFriendRequest(params.UserId);
                  }}
                >
                  <AddRoundedIcon /> {t("adding_button")}
                </button>
              )}
            </div>
            <div className="usersocialcount">
              <p>
                {userData?.friends?.length || 0} <span>{t("friends")}</span>
              </p>
              {params.UserId === firebase.auth.currentUser.uid ? (
                ""
              ) : (
                <p>
                  {userData?.friends?.filter((a) => friendslist.includes(a))
                    .length || 0}{" "}
                  <span>{t("mutual_friends")}</span>
                </p>
              )}
            </div>
            <div className="usersmutualfriends">
              <div className="usermutualfriendsheader">
                {params.UserId === firebase.auth.currentUser.uid ? (
                  <p>{t("friends")}</p>
                ) : (
                  <p>{t("mutual_friends")}</p>
                )}
              </div>
              {userData?.friends?.filter((a) => friendslist.includes(a))
                .length >= 1 ? (
                <div
                  className="usermutualfriendsmapper"
                  onTouchStart={(e) => {
                    e.stopPropagation();
                  }}
                  onTouchMove={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {userData?.friends
                    ?.filter((a) => friendslist.includes(a))
                    .map((a) => (
                      <div
                        className="singlemutualfriend"
                        onClick={() => setMutualFriend({ id: a, open: true })}
                      >
                        <AvatarComponentFriends driverid={a} />
                        <h2>
                          {" "}
                          <NameGetter id={a} />{" "}
                        </h2>
                      </div>
                    ))}
                </div>
              ) : (
                <div className="no_event friends" style={{ margin: "10px 0" }}>
                  {" "}
                  {t("no_mutual_friends")}{" "}
                </div>
              )}
            </div>
            <div className="userfavourites">
              <div className="usermutualfriendsheader">
                <p>{t("todays_favourites")}</p>
                {/* <p>View all</p> */}
              </div>
              {favouritemap?.length >= 1 ? (
                <div
                  className="usermutualfriendsmapper"
                  onTouchStart={(e) => {
                    e.stopPropagation();
                  }}
                  onTouchMove={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {favouritemap}
                </div>
              ) : (
                <div className="no_event friends" style={{ margin: "10px 0" }}>
                  {" "}
                  {t("no_favourites")}{" "}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            position: "fixed",
            top: "20px",
            zIndex: "100",
            left: "50%",
            transform: "translate(-50%, 0%)",
            width: "90%",
          }}
        >
          <Collapse in={copiedAlert} onClick={() => setCopiedAlert(false)}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setCopiedAlert(false);
                  }}
                ></IconButton>
              }
              sx={{ mb: 2 }}
            >
              {t("copy_message")}
            </Alert>
          </Collapse>
        </div>
        <Drawer
          anchor="bottom"
          PaperProps={{
            elevation: 0,
            style: {
              borderRadius: "0",
              backgroundColor: "transparent",
            },
          }}
          open={settings}
          onClose={() => setSettings(false)}
        >
          <div className="usersettingsmodal">
            <div
              onClick={reportUser}
              style={{ color: "#de4955", borderRadius: "15px 15px 0 0" }}
              className="alertbutton"
            >
              {t("report")}
            </div>
            {userData?.friends?.includes(firebase.auth.currentUser.uid) && (
              <div
                onClick={() => {
                  setSettings(false);
                  setDeleteModal(true);
                }}
                style={{ color: "#de4955", borderRadius: "0" }}
                className="alertbutton"
              >
                {t("remove_big")}
              </div>
            )}
            <div
              onClick={() => {
                navigator.clipboard.writeText(userData?.username);
                setSettings(false);
                copieAlert();
              }}
              style={{ color: "white" }}
              className="alertbutton"
            >
              {t("copy_user_name")}
            </div>
          </div>
          <div
            onClick={() => setSettings(false)}
            className="alertbutton single"
          >
            {t("cancel")}
          </div>
        </Drawer>
        <Drawer
          anchor="bottom"
          PaperProps={{
            elevation: 0,
            style: {
              borderRadius: "0",
              backgroundColor: "transparent",
            },
          }}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        >
          <div className="alert">
            <div className="alertavatar">
              <AvatarComponentSeat driverid={params.UserId} />
            </div>
            <h1>{t("remove_friend_title")}</h1>
            <p>
              {t("remove_message_start")}{" "}
              <span style={{ fontWeight: "800" }}>
                {" "}
                {userData?.username
                  ? userData.username.length > 16
                    ? `${userData.username.substring(0, [15])}...`
                    : userData.username
                  : "..."}{" "}
              </span>{" "}
              {t("remove_message_end")}{" "}
            </p>
            <div
              onClick={() => handleFriendRemove(params.UserId)}
              style={{ color: "#de4955" }}
              className="alertbutton"
            >
              {t("remove_big")}
            </div>
          </div>
          <div
            onClick={() => setDeleteModal(false)}
            className="alertbutton single"
          >
            {t("cancel")}
          </div>
        </Drawer>
      </div>
    </>
  );
}
