import React, { useState } from "react";
import FirebaseContext from "../firebase/context";
import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useDocumentData } from "react-firebase-hooks/firestore";

export default function SnapchatPage(props) {
  const { firebase, user } = React.useContext(FirebaseContext);
  const { t } = useTranslation();
  const [snapchatlink, setSnapchatlink] = React.useState("");
  const [opensnap, setOpensnap] = useState(false);

  const [userData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );

  function handleSnapchat() {
    if (snapchatlink.replace(/ /g, "") !== "") {
      firebase.db
        .doc(`users/${user && user.uid}`)
        .update({ snapchat: snapchatlink });
      setOpensnap(false);
      props.onClose();
    } else {
      console.log("invalid!");
    }
  }

  return (
    <div className="changeusernamewrapper">
      <div className="businesshead">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            borderBottom: "1px solid rgb(48 47 47)",
          }}
        >
          <ArrowBackIosIcon
            onClick={props.onClose}
            style={{
              color: "#FD7F4D",
              position: "absolute",
              left: "26px",
              top: "50%",
              transform: "translate(0%, -50%)",
              padding: "10px",
              marginLeft: "-10px",
            }}
          />
          <h1> Snapchat </h1>
          {snapchatlink.includes("snapchat") &&
          snapchatlink.replace(/ /g, "") !== "" ? (
            <span
              onClick={handleSnapchat}
              style={{
                color: "#FD7F4D",
                position: "absolute",
                right: "26px",
                top: "50%",
                transform: "translate(0%, -50%)",
                padding: "10px",
                marginLeft: "-10px",
                fontWeight: "500",
                fontSize: "18px",
                transition: "all .5s",
              }}
            >
              {t("done")}
            </span>
          ) : (
            <span
              style={{
                color: "#FD7F4D",
                position: "absolute",
                right: "26px",
                top: "50%",
                transform: "translate(0%, -50%)",
                padding: "10px",
                marginLeft: "-10px",
                fontWeight: "500",
                fontSize: "18px",
                transition: "all .5s",
                opacity: "50%",
              }}
            >
              {t("done")}
            </span>
          )}
        </div>
      </div>
      <div className="changeusernamemain">
        <p style={{ marginTop: "0" }}>{t("snapchat")}</p>
        <input
          value={snapchatlink}
          onChange={(e) => setSnapchatlink(e.target.value)}
          placeholder={userData?.snapchat}
        />
      </div>
    </div>
  );
}
