import React, { useEffect, useState } from "react";
import FirebaseContext from "../firebase/context";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";

import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import Avatar from "@material-ui/core/Avatar";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import Modal from "@material-ui/core/Modal";
//Progress
import CircularProgress from "@material-ui/core/CircularProgress";
//i18n
import { useTranslation } from "react-i18next";
import { Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  label: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    margin: "0",
  },
}));

export default function MorePeoplePage(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { firebase, user } = React.useContext(FirebaseContext);
  //Gets all users Data
  const [userdata] = useCollection(firebase.db.collection("users"));
  const [userData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );
  const [searchFriend, setSearchFriend] = React.useState("");
  const [party, setParty] = useState({ guests: {} });
  const friendslist = (userData && userData.friends) || [];
  const [submitting, setSubmitting] = React.useState(false);

  useEffect(() => {
    setParty((prev) => ({
      ...prev,
      guests: props?.data?.partyData?.guests.reduce(
        (acc, guestId) => ({ ...acc, [guestId]: true }),
        prev.guests
      ),
    }));
  }, [props.data.partyData.guests]);

  async function handleAdd() {
    setSubmitting(true);
    await firebase.db
      .doc(`parties/${props.partyId}`)
      .update({
        guests: Object.keys(party.guests).filter((id) => party.guests[id]),
      })
      .then(() => props.onClose());
  }

  return (
    <div className="partymodalwrapper">
      <div className="partymodalheader">
        <div className="headtitleparty">
          <a onClick={props.onClose}>
            <ArrowBackIosRoundedIcon
              className="buttonanimation"
              style={{ color: "#FD7F4D" }}
            />
          </a>
          <h1>{t("add_more_people")}</h1>
          <button
            className="buttonanimation"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleAdd}
          >
            <AddRoundedIcon style={{ fontSize: "28px" }} />
          </button>
        </div>
      </div>
      <div
        style={{
          margin: "0 16px",
          paddingBottom: "calc(env(safe-area-inset-bottom) + 10px)",
        }}
        className="addfrienddiv"
      >
        <div className="inputfriend">
          <label className="scndlabel">
            <h2>{t("guest_list")}:</h2>
            <div className="inputfriendfirst friends adding">
              <input
                autoFocus
                className="searchbar friend adding"
                value={searchFriend}
                onChange={(event) => {
                  setSearchFriend(event.target.value);
                }}
                placeholder={t("search_placeholder")}
              />
              {!searchFriend ? (
                <SearchIcon style={{ paddingRight: "17px" }} />
              ) : (
                <HighlightOffIcon
                  style={{ paddingRight: "17px" }}
                  onClick={() => setSearchFriend("")}
                />
              )}
            </div>
          </label>
        </div>
        <div>
          <div
            style={{ marginTop: "7px", paddingBottom: "10px" }}
            className="posts"
          >
            {userdata &&
              userdata.docs
                .filter((user) => {
                  if (searchFriend === "") {
                    return null;
                  } else if (
                    user
                      .data()
                      .username.toLowerCase()
                      .startsWith(searchFriend.toLowerCase())
                  ) {
                    return user;
                  }
                })
                .map((user, key) =>
                  firebase.auth.currentUser.uid === user.id ? (
                    <div
                      key={user.id}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        margin: "7px 0px",
                        background: "#1f1f1f",
                        borderRadius: "20px",
                      }}
                    >
                      <FormControlLabel
                        classes={classes}
                        style={{
                          width: "100%",
                          marginLeft: "0",
                          margin: "0 20px",
                        }}
                        labelPlacement="start"
                        value="all"
                        control={
                          <Checkbox
                            checked={!!party.guests[user.id]}
                            icon={
                              <RadioButtonUncheckedIcon
                                color="primary"
                                style={{
                                  fontSize: "35px",
                                  color: "grey",
                                }}
                              />
                            }
                            checkedIcon={
                              <CheckCircleIcon
                                color="primary"
                                style={{
                                  fontSize: "35px",
                                  color: "grey",
                                }}
                              />
                            }
                            name="checker"
                          />
                        }
                        label={
                          <div
                            key={user.id}
                            className="drivepost add"
                            style={{
                              justifyContent: "flex-start",
                              margin: "0",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Avatar
                                alt="Profile"
                                src={user.data().imgRef}
                                style={{ height: "4rem", width: "4rem" }}
                              />{" "}
                              <p style={{ marginLeft: "15px" }}>
                                {" "}
                                {user.data().username &&
                                user.data().username.length > 11
                                  ? `${user
                                      .data()
                                      .username.substring(0, [10])}...`
                                  : user.data().username}
                              </p>
                            </div>
                          </div>
                        }
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        margin: "7px 0",
                        background: "#1f1f1f",
                        borderRadius: "20px",
                      }}
                    >
                      <FormControlLabel
                        labelPlacement="start"
                        value="all"
                        classes={classes}
                        style={{
                          width: "100%",
                          marginLeft: "0",
                          margin: "0 20px",
                        }}
                        onChange={() =>
                          setParty((prev) => ({
                            ...prev,
                            guests: {
                              ...prev.guests,
                              [user.id]: !prev.guests[user.id],
                            },
                          }))
                        }
                        control={
                          <Checkbox
                            checked={!!party.guests[user.id]}
                            icon={
                              <RadioButtonUncheckedIcon
                                color="primary"
                                style={{
                                  fontSize: "35px",
                                  color: "#FD7F4D",
                                }}
                              />
                            }
                            checkedIcon={
                              <CheckCircleIcon
                                color="primary"
                                style={{
                                  fontSize: "35px",
                                  color: "#FD7F4D",
                                }}
                              />
                            }
                            name="checker"
                          />
                        }
                        label={
                          <div
                            key={user.id}
                            className="drivepost add"
                            style={{
                              justifyContent: "flex-start",
                              margin: "0",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Avatar
                                alt="Profile"
                                src={user.data().imgRef}
                                style={{ height: "4rem", width: "4rem" }}
                              />{" "}
                              <p
                                style={{
                                  marginLeft: "15px",
                                  maxHeight: "24px",
                                  maxWidth: "100%",
                                }}
                              >
                                {" "}
                                {user.data().username &&
                                user.data().username.length > 11
                                  ? `${user
                                      .data()
                                      .username.substring(0, [10])}...`
                                  : user.data().username}
                                <br />
                                {user.id === firebase.auth.currentUser.uid ? (
                                  ""
                                ) : (
                                  <>
                                    {user
                                      .data()
                                      .friends?.filter((a) =>
                                        friendslist.includes(a)
                                      ).length >= 1 ? (
                                      <span className="mutualfriends">
                                        {
                                          user
                                            .data()
                                            .friends?.filter((a) =>
                                              friendslist.includes(a)
                                            ).length
                                        }{" "}
                                        {user
                                          .data()
                                          .friends?.filter((a) =>
                                            friendslist.includes(a)
                                          ).length === 1
                                          ? t("mutual_friend")
                                          : t("mutual_friends")}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </p>
                            </div>
                          </div>
                        }
                      />
                    </div>
                  )
                )}
          </div>
        </div>
      </div>
    </div>
  );
}
