import React, { useState } from "react";
import FirebaseContext from "../firebase/context";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import Avatar from "@material-ui/core/Avatar";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import Modal from "@material-ui/core/Modal";
import AvatarComponentMini from "./AvatarComponent";
import AvatarComponentGuest from "./AvatarComponentGuest";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import LockIcon from "@material-ui/icons/Lock";
import BackspaceRoundedIcon from "@material-ui/icons/BackspaceRounded";
//Progress
import CircularProgress from "@material-ui/core/CircularProgress";
//i18n
import { useTranslation } from "react-i18next";
import { Collapse, Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Switcher from "./Switcher";
//Autocomplete
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import uploadFile from "../util/uploadFile";
import vibrate from "../util/feedback";
import CamUpload from "../util/CamUpload";
import BugInfo from "./BugInfo";

const useStyles = makeStyles((theme) => ({
  label: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    margin: "0",
  },
}));

export default function MakeParty(onClose) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { firebase, user } = React.useContext(FirebaseContext);
  //Gets all users Data
  const [userdata] = useCollection(firebase.db.collection("users"));
  const [searchFriend, setSearchFriend] = React.useState("");
  const [party, setParty] = useState({ guests: {} });
  const [next, setNext] = useState(false);
  const [animation, setAnimation] = useState(false);
  const history = useHistory();
  const [openCamDrawer, setOpenCamDrawer] = useState(false);

  const [userData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );

  const friendslist = (userData && userData.friends) || [];

  const [partyName, setPartyName] = React.useState("");
  const [partyTime, setPartyTime] = React.useState("");
  const [info, setInfo] = React.useState("");
  const [privatePartyCode, setPrivatePartyCode] = React.useState("");
  const [privateParty, setPrivateParty] = React.useState(false);
  const [partyLocation, setPartyLocation] = React.useState("");
  const [partyNameError, setPartyNameError] = React.useState(false);
  const [partyLocationError, setPartyLocationError] = React.useState(false);
  const [privatePartyError, setPrivatePartyError] = React.useState(false);
  const [partyTimeError, setPartyTimeError] = React.useState(false);
  const [locationCoordinates, setLocationCoordinates] = React.useState({
    lat: null,
    lng: null,
  });
  const [loader, setLoader] = React.useState(false);
  const [image, setImage] = React.useState("");
  const [eventImage, setEventImage] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const [clickanimation, setClickAnimation] = React.useState(false);
  const [codeDrawer, setCodeDrawer] = useState(false);
  const [success, setSuccess] = React.useState(false);
  const [successimage, setSuccessimage] = React.useState("");

  const handleSelectAddress = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setPartyLocation(value);
    setLocationCoordinates(latLng);
    setClickAnimation(false);
  };

  async function updateUserImage(file) {
    try {
      const { success, imgRef, error } = await uploadFile(file, user);
      if (!success) throw new Error(error);
      setImage(file);
      setEventImage(imgRef);
    } catch (err) {
      console.debug("DEBUG catch error", { err });
    }
    setLoader(false);
  }

  function makeId(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  function updateEventNotification(idsArray, newDataObject) {
    const batch = firebase.db.batch();

    idsArray.forEach((id) => {
      const userRef = firebase.db.collection("users").doc(id);
      batch.update(userRef, {
        onGuestListNotification: firebase.FieldValue.arrayUnion(newDataObject),
      });
    });

    batch.commit();
  }

  async function handleCreateParty() {
    if (partyName === "") {
      setPartyNameError(true);
    } else if (partyLocation === "") {
      setPartyLocationError(true);
    } else if (partyTime === "") {
      setPartyTimeError(true);
    } else if (privateParty && privatePartyCode === "") {
      setPrivatePartyError(true);
    } else if (privateParty === false) {
      setSubmitting(true);
      await firebase.db
        .collection(`parties`)
        .add({
          ...party,
          guests: Object.keys(party.guests).filter((id) => party.guests[id]),
          partyname: partyName,
          partylocation: partyLocation,
          eventinfo: info,
          id: firebase.auth.currentUser.uid,
          host: userData?.username,
          time: partyTime,
          date: new Date(),
          profilepicture: userData.imgRef || null,
          image: eventImage,
          views: [firebase.auth.currentUser.uid],
          lat: locationCoordinates.lat,
          long: locationCoordinates.lng,
        })
        .then(() => {
          updateEventNotification(Object.keys(party.guests), {
            id: firebase.auth.currentUser.uid,
            inviteId: makeId(15),
          });
        });
      setSubmitting(false);
      setSuccess(true);
      setTimeout(() => {
        vibrate("heavy");
      }, 100);
      setTimeout(() => {
        vibrate("heavy");
      }, 200);
      setTimeout(() => {
        vibrate("heavy");
      }, 300);
    } else {
      setSubmitting(true);
      await firebase.db
        .collection(`parties`)
        .add({
          ...party,
          guests: Object.keys(party.guests).filter((id) => party.guests[id]),
          partyname: partyName,
          partylocation: partyLocation,
          id: firebase.auth.currentUser.uid,
          host: userData?.username,
          time: partyTime,
          eventinfo: info,
          secretCode: privatePartyCode,
          private: privateParty,
          date: new Date(),
          profilepicture: userData.imgRef || null,
          image: eventImage,
          views: [firebase.auth.currentUser.uid],
          lat: locationCoordinates.lat,
          long: locationCoordinates.lng,
        })
        .then(() => {
          updateEventNotification(Object.keys(party.guests), {
            id: firebase.auth.currentUser.uid,
            inviteId: makeId(15),
          });
        });

      setSubmitting(false);
      setSuccess(true);
      setTimeout(() => {
        vibrate("heavy");
      }, 100);
      setTimeout(() => {
        vibrate("heavy");
      }, 200);
      setTimeout(() => {
        vibrate("heavy");
      }, 300);
    }
  }

  function changePrivateMode(e) {
    const code = document.getElementById("code");
    const message = document.getElementById("message");

    setPrivateParty((prevState) => !prevState);
    code.classList.toggle("active");
    // message.classList.toggle("active");
  }

  return (
    <>
      <Drawer
        open={codeDrawer}
        anchor="bottom"
        transitionDuration={350}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "black",
            height: "100vh",
          },
        }}
      >
        <div className="secretSearchWrapper" style={{ paddingTop: "0px" }}>
          <div className="secretHeader" style={{ paddingTop: "0px" }}>
            <ClearRoundedIcon
              onClick={() => {
                setCodeDrawer(false);
              }}
              style={{
                fontSize: "40px",
                color: "#FD7F4D",
                position: "absolute",
                top: "calc(env(safe-area-inset-top) + 15px)",
                left: "15px",
              }}
            />
            <LockIcon style={{ color: "white", fontSize: "35px" }} />
            <h2 className="secretHeaderTitle">{t("code_placeholder")}</h2>
            <div className="secretCodeIndicators">
              <span
                className="singleCodeIndicator"
                style={{
                  backgroundColor:
                    privatePartyCode.length >= 1 && "rgb(253, 127, 77)",
                  border:
                    privatePartyCode.length >= 1 && "1px solid transparent",
                }}
              ></span>
              <span
                className="singleCodeIndicator"
                style={{
                  backgroundColor:
                    privatePartyCode.length >= 2 && "rgb(253, 127, 77)",
                  border:
                    privatePartyCode.length >= 2 && "1px solid transparent",
                }}
              ></span>
              <span
                className="singleCodeIndicator"
                style={{
                  backgroundColor:
                    privatePartyCode.length >= 3 && "rgb(253, 127, 77)",
                  border:
                    privatePartyCode.length >= 3 && "1px solid transparent",
                }}
              ></span>
              <span
                className="singleCodeIndicator"
                style={{
                  backgroundColor:
                    privatePartyCode.length >= 4 && "rgb(253, 127, 77)",
                  border:
                    privatePartyCode.length >= 4 && "1px solid transparent",
                }}
              ></span>
              <span
                className="singleCodeIndicator"
                style={{
                  backgroundColor:
                    privatePartyCode.length >= 5 && "rgb(253, 127, 77)",
                  border:
                    privatePartyCode.length >= 5 && "1px solid transparent",
                }}
              ></span>
              <span
                className="singleCodeIndicator"
                style={{
                  backgroundColor:
                    privatePartyCode.length >= 6 && "rgb(253, 127, 77)",
                  border:
                    privatePartyCode.length >= 6 && "1px solid transparent",
                }}
              ></span>
            </div>
          </div>
          <div className="pinwrapper">
            <div className="pinNumberWrapper">
              <div className="pinnumber">
                <span
                  className="innerPinNumber"
                  onClick={() => {
                    vibrate("light");
                    if (privatePartyCode.length < 6) {
                      setPrivatePartyCode(privatePartyCode + "1");
                    }
                  }}
                >
                  1
                </span>
              </div>
              <div className="pinnumber">
                <span
                  className="innerPinNumber"
                  onClick={() => {
                    vibrate("light");
                    if (privatePartyCode.length < 6) {
                      setPrivatePartyCode(privatePartyCode + "2");
                    }
                  }}
                >
                  2
                </span>
              </div>
              <div className="pinnumber">
                <span
                  className="innerPinNumber"
                  onClick={() => {
                    vibrate("light");
                    if (privatePartyCode.length < 6) {
                      setPrivatePartyCode(privatePartyCode + "3");
                    }
                  }}
                >
                  3
                </span>
              </div>
              <div className="pinnumber">
                <span
                  className="innerPinNumber"
                  onClick={() => {
                    vibrate("light");
                    if (privatePartyCode.length < 6) {
                      setPrivatePartyCode(privatePartyCode + "4");
                    }
                  }}
                >
                  4
                </span>
              </div>
              <div className="pinnumber">
                <span
                  className="innerPinNumber"
                  onClick={() => {
                    vibrate("light");
                    if (privatePartyCode.length < 6) {
                      setPrivatePartyCode(privatePartyCode + "5");
                    }
                  }}
                >
                  5
                </span>
              </div>
              <div className="pinnumber">
                <span
                  className="innerPinNumber"
                  onClick={() => {
                    vibrate("light");
                    if (privatePartyCode.length < 6) {
                      setPrivatePartyCode(privatePartyCode + "6");
                    }
                  }}
                >
                  6
                </span>
              </div>
              <div className="pinnumber">
                <span
                  className="innerPinNumber"
                  onClick={() => {
                    vibrate("light");
                    if (privatePartyCode.length < 6) {
                      setPrivatePartyCode(privatePartyCode + "7");
                    }
                  }}
                >
                  7
                </span>
              </div>
              <div className="pinnumber">
                <span
                  className="innerPinNumber"
                  onClick={() => {
                    vibrate("light");
                    if (privatePartyCode.length < 6) {
                      setPrivatePartyCode(privatePartyCode + "8");
                    }
                  }}
                >
                  8
                </span>
              </div>
              <div className="pinnumber">
                <span
                  className="innerPinNumber"
                  onClick={() => {
                    vibrate("light");
                    if (privatePartyCode.length < 6) {
                      setPrivatePartyCode(privatePartyCode + "9");
                    }
                  }}
                >
                  9
                </span>
              </div>
              <div className="pinnumber" style={{ opacity: "0" }}>
                <span className="innerPinNumber"></span>
              </div>
              <div className="pinnumber">
                <span
                  className="innerPinNumber"
                  onClick={() => {
                    vibrate("light");
                    if (privatePartyCode.length < 6) {
                      setPrivatePartyCode(privatePartyCode + "0");
                    }
                  }}
                >
                  0
                </span>
              </div>
              <div
                className="pinnumber"
                onClick={() => {
                  const newStr = String(privatePartyCode).slice(0, -1);
                  setPrivatePartyCode(newStr);
                }}
              >
                <BackspaceRoundedIcon
                  style={{ color: "rgb(253, 127, 77)", fontSize: "25px" }}
                />
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              if (privatePartyCode.length === 6) {
                vibrate("light");
                setCodeDrawer(false);
              }
            }}
            style={privatePartyCode.length === 6 ? {} : { background: "grey" }}
            className="confirmbutton"
          >
            {t("confirm")}
          </div>
        </div>
      </Drawer>
      <Drawer
        open={success}
        anchor="bottom"
        transitionDuration={550}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "black",
            height: "100vh",
          },
        }}
      >
        <div className="successcontainer">
          {image && <img src={image} alt="Event" />}
          <div className="successmain">
            <h1>
              <span className="secondspan"> {t("success")} </span> <br />
              <span className="spantitle">{t("event_created")}</span>
            </h1>
            <span className="tag">@{userData?.username} </span>
            <div className="successbottom">
              <span></span>
              <div
                style={{
                  border: "1px solid white",
                  zIndex: "10",
                  borderRadius: "100%",
                  padding: "1px",
                  background: "white",
                }}
              >
                <AvatarComponentMini driverid={user.uid} />
              </div>
            </div>
            <div
              onClick={() => {
                setSuccess(false);
                onClose.onClose();
              }}
              className="successbutton"
            >
              {t("done")}
            </div>
          </div>
        </div>
      </Drawer>
      <Modal
        open={loader}
        onClose={() => setLoader(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="modalwarning" style={{ padding: "20px" }}>
          <CircularProgress style={{ color: "#fd7f4d" }} />
        </div>
      </Modal>
      {!next ? (
        <div className="partymodalwrapper">
          <div className="partymodalheader">
            <div className="headtitleparty">
              <a onClick={onClose.onClose}>
                <ArrowBackIosRoundedIcon
                  className="buttonanimation"
                  style={{ color: "#FD7F4D" }}
                />
              </a>
              <h1>{t("make_an_event")}</h1>
              <button
                className="buttonanimation"
                onClick={() => {
                  vibrate("light");
                  setNext(true);
                  setAnimation(true);
                }}
              >
                Next
              </button>
            </div>
          </div>
          <div
            style={{
              padding: "0px",
              background: "black",
            }}
            className="addfrienddiv"
          >
            <div className="inputfriend">
              <div className="scndlabel">
                <h2>{t("guest_list")}:</h2>
                <div
                  style={
                    Object.values(party.guests).includes(true)
                      ? { height: "80px" }
                      : { height: "0" }
                  }
                  className="addedguests"
                >
                  {Object.keys(party.guests)
                    ?.filter(
                      (guest, idx) => Object.values(party.guests)[idx] === true
                    )
                    .map((guest, idx) => (
                      <div
                        onTouchStart={(e) => {
                          e.stopPropagation();
                        }}
                        onTouchMove={(e) => {
                          e.stopPropagation();
                        }}
                        className="singleguestpreview"
                      >
                        <ClearRoundedIcon
                          style={{
                            padding: "3px",
                            position: "absolute",
                            top: "-6px",
                            right: "-8px",
                            background: "#333333",
                            color: "white",
                            borderRadius: "100%",
                            zIndex: "1",
                            fontSize: "18px",
                          }}
                          onClick={() =>
                            setParty((prev) => ({
                              ...prev,
                              guests: {
                                ...prev.guests,
                                [guest]: false,
                              },
                            }))
                          }
                        />
                        <AvatarComponentGuest driverid={guest} />
                      </div>
                    ))}
                </div>
                <div
                  style={{ marginTop: "0" }}
                  className="inputfriendfirst friends adding"
                >
                  <input
                    autoFocus
                    className="searchbar friend adding"
                    value={searchFriend}
                    onChange={(event) => {
                      setSearchFriend(event.target.value);
                    }}
                    placeholder={t("search_placeholder")}
                  />
                  {!searchFriend ? (
                    <SearchIcon style={{ paddingRight: "17px" }} />
                  ) : (
                    <HighlightOffIcon
                      style={{ paddingRight: "17px" }}
                      onClick={() => setSearchFriend("")}
                    />
                  )}
                </div>
              </div>
            </div>
            <div>
              <div
                style={{
                  marginTop: "7px",
                  paddingBottom: "calc(env(safe-area-inset-bottom) + 10px)",
                }}
                className="posts"
              >
                {userdata &&
                  userdata.docs
                    .filter((user) => {
                      if (searchFriend === "") {
                        const friends = friendslist?.includes(user.id);
                        return friends && user;
                      } else if (
                        user
                          .data()
                          .username.toLowerCase()
                          .startsWith(searchFriend.toLowerCase())
                      ) {
                        return user;
                      }
                    })
                    .map((user, key) =>
                      firebase.auth.currentUser.uid === user.id ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            margin: "7px 0px",
                            // background: "#1f1f1f",
                            borderRadius: "20px",
                          }}
                        >
                          <FormControlLabel
                            classes={classes}
                            style={{
                              width: "100%",
                              marginLeft: "0",
                              margin: "0px",
                            }}
                            labelPlacement="start"
                            value="all"
                            control={
                              <Checkbox
                                checked={!!party.guests[user.id]}
                                icon={
                                  <RadioButtonUncheckedIcon
                                    color="primary"
                                    style={{
                                      fontSize: "35px",
                                      color: "grey",
                                    }}
                                  />
                                }
                                checkedIcon={
                                  <CheckCircleIcon
                                    color="primary"
                                    style={{
                                      fontSize: "35px",
                                      color: "grey",
                                    }}
                                  />
                                }
                                name="checker"
                              />
                            }
                            label={
                              <div
                                key={user.id}
                                className="drivepost add"
                                style={{
                                  justifyContent: "flex-start",
                                  margin: "0",
                                  background: "transparent",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Avatar
                                    alt="Profile"
                                    src={user.data().imgRef}
                                    style={{ height: "4rem", width: "4rem" }}
                                  />{" "}
                                  <p
                                    style={{
                                      marginLeft: "15px",
                                      maxWidth: "initial",
                                    }}
                                  >
                                    {" "}
                                    {user.data().username &&
                                    user.data().username.length > 20
                                      ? `${user
                                          .data()
                                          .username.substring(0, [19])}...`
                                      : user.data().username}
                                  </p>
                                </div>
                              </div>
                            }
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            margin: "7px 0",
                            // background: "#1f1f1f",
                            borderRadius: "20px",
                          }}
                        >
                          <FormControlLabel
                            labelPlacement="start"
                            value="all"
                            classes={classes}
                            style={{
                              width: "100%",
                              marginLeft: "0",
                              margin: "0",
                            }}
                            onChange={() => {
                              vibrate("light");
                              setParty((prev) => ({
                                ...prev,
                                guests: {
                                  ...prev.guests,
                                  [user.id]: !prev.guests[user.id],
                                },
                              }));
                              setSearchFriend("");
                            }}
                            control={
                              <Checkbox
                                checked={!!party.guests[user.id]}
                                icon={
                                  <RadioButtonUncheckedIcon
                                    color="primary"
                                    style={{
                                      fontSize: "35px",
                                      color: "#FD7F4D",
                                    }}
                                  />
                                }
                                checkedIcon={
                                  <CheckCircleIcon
                                    color="primary"
                                    style={{
                                      fontSize: "35px",
                                      color: "#FD7F4D",
                                    }}
                                  />
                                }
                                name="checker"
                              />
                            }
                            label={
                              <div
                                key={user.id}
                                className="drivepost add"
                                style={{
                                  justifyContent: "flex-start",
                                  margin: "0",
                                  background: "transparent",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Avatar
                                    alt="Profile"
                                    src={user.data().imgRef}
                                    style={{ height: "4rem", width: "4rem" }}
                                  />{" "}
                                  <p
                                    style={{
                                      marginLeft: "15px",
                                      maxHeight: "24px",
                                      maxWidth: "initial",
                                    }}
                                  >
                                    {" "}
                                    {user.data().username &&
                                    user.data().username.length > 20
                                      ? `${user
                                          .data()
                                          .username.substring(0, [19])}...`
                                      : user.data().username}
                                    <br />
                                    {user.id ===
                                    firebase.auth.currentUser.uid ? (
                                      ""
                                    ) : (
                                      <>
                                        {user
                                          .data()
                                          .friends?.filter((a) =>
                                            friendslist.includes(a)
                                          ).length >= 1 ? (
                                          <span
                                            style={{ width: "150px" }}
                                            className="mutualfriends"
                                          >
                                            {
                                              user
                                                .data()
                                                .friends?.filter((a) =>
                                                  friendslist.includes(a)
                                                ).length
                                            }{" "}
                                            {user
                                              .data()
                                              .friends?.filter((a) =>
                                                friendslist.includes(a)
                                              ).length === 1
                                              ? t("mutual_friend")
                                              : t("mutual_friends")}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </p>
                                </div>
                              </div>
                            }
                          />
                        </div>
                      )
                    )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="partymodalinfo" style={{ marginBottom: "0" }}>
          <div className="partymodalheader">
            <div className="headtitleparty">
              <button
                onClick={() => {
                  setNext(false);
                  setAnimation(false);
                  setClickAnimation(false);
                }}
              >
                {" "}
                <ArrowBackIosRoundedIcon style={{ color: "#FD7F4D" }} />{" "}
              </button>
              <h1 className={next ? "animationtitle" : ""}>Event Info</h1>
              {partyName === "" ? (
                <button disabled style={{ color: "rgb(123, 127, 133)" }}>
                  {t("create")}
                </button>
              ) : partyLocation === "" ? (
                <button disabled style={{ color: "rgb(123, 127, 133)" }}>
                  {t("create")}
                </button>
              ) : partyTime === "" ? (
                <button disabled style={{ color: "rgb(123, 127, 133)" }}>
                  {t("create")}
                </button>
              ) : eventImage === "" ? (
                <button disabled style={{ color: "rgb(123, 127, 133)" }}>
                  {t("create")}
                </button>
              ) : privateParty && privatePartyCode.length !== 6 ? (
                <button disabled style={{ color: "rgb(123, 127, 133)" }}>
                  {t("create")}
                </button>
              ) : submitting ? (
                <button disabled>
                  <CircularProgress
                    style={{ color: "white", width: "25px", height: "25px" }}
                  />
                </button>
              ) : (
                <button onClick={handleCreateParty}>{t("create")}</button>
              )}
            </div>
          </div>
          <div className="createeventinputs">
            <input
              className={animation ? "scaleanimationinput" : ""}
              style={{ animationDelay: "0s" }}
              onChange={(e) => setPartyName(e.target.value)}
              placeholder="Event"
            />

            <div
              style={
                clickanimation
                  ? {
                      height: "100vh",
                      width: "100vw",
                      position: "absolute",
                      left: "0",
                      top: "0",
                      zIndex: "10",
                      transition: ".8s",
                      opacity: "1",
                      background: "black",
                      userSelect: "initial",
                    }
                  : {
                      height: "100vh",
                      width: "100vw",
                      position: "absolute",
                      left: "0",
                      top: "0",
                      zIndex: "-1",
                      transition: ".8s",
                      opacity: "0",
                      background: "black",
                      userSelect: "none",
                    }
              }
            >
              <span
                style={{
                  position: "absolute",
                  top: "85px",
                  right: "18px",
                  color: "white",
                }}
              >
                <span
                  style={{
                    position: "relative",
                    height: "35px",
                    width: "39px",
                  }}
                  onClick={() => {
                    setClickAnimation(false);
                  }}
                >
                  <ClearRoundedIcon style={{ fontSize: "35px" }} />
                </span>
              </span>
            </div>

            <PlacesAutocomplete
              value={partyLocation}
              onChange={(e) => {
                setPartyLocation(e);
                setClickAnimation(true);
              }}
              onSelect={handleSelectAddress}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div
                  style={
                    clickanimation
                      ? {
                          width: "calc(100% - 30px)",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          zIndex: "11",
                          marginTop: "-55px",
                          transition: ".5s",
                        }
                      : {
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          transition: ".5s",
                        }
                  }
                >
                  <input
                    className={animation ? "scaleanimationinput" : ""}
                    onChange={(e) => {
                      setPartyLocation(e.target.value);
                    }}
                    placeholder={t("address")}
                    onClick={() => setClickAnimation(true)}
                    {...getInputProps({
                      placeholder: t("address"),
                    })}
                    style={
                      clickanimation
                        ? {
                            width: "calc(100% - 30px)",
                            marginLeft: "-50px",
                          }
                        : { animationDelay: ".2s" }
                    }
                  />
                  <div
                    className="suggestions driver"
                    style={
                      partyLocation === ""
                        ? { display: "none" }
                        : clickanimation === false
                        ? {
                            display: "flex",
                            flexDirection: "column",
                            width: "calc(100% + 40px)",
                            marginLeft: "30px",
                          }
                        : {
                            display: "flex",
                            flexDirection: "column",
                            width: "calc(100% + 40px)",
                          }
                    }
                  >
                    {clickanimation && (
                      <>
                        {loading ? (
                          <>
                            <BugInfo />
                            <div className="suggestionloader">
                              <CircularProgress style={{ color: "white" }} />
                            </div>
                          </>
                        ) : null}
                      </>
                    )}
                    {suggestions.map((suggestion) => {
                      const style = {
                        backgroundColor: suggestion.active
                          ? "#efefef"
                          : "#ffff",
                        cursor: suggestion.active ? "pointer" : "initial",
                      };
                      return (
                        <div
                          key={suggestion.description}
                          {...getSuggestionItemProps(suggestion, { style })}
                          style={{ display: "flex", alignItems: "center" }}
                          className="suggestionsingle"
                        >
                          <p className="suggestiontext">
                            {suggestion.description}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>

            <input
              className={animation ? "scaleanimationinput" : ""}
              style={{ animationDelay: "0.2s" }}
              placeholder={t("time")}
              maxLength="13"
              onChange={(e) => setPartyTime(e.target.value)}
            />
            <textarea
              className={animation ? "scaleanimationinput" : ""}
              style={{ animationDelay: "0.3s" }}
              placeholder="Event Information (optional)"
              rows="5"
              onChange={(e) => setInfo(e.target.value)}
            />
          </div>
          <div
            className={
              animation ? "outsidelabel scaleanimationinput" : "outsidelabel"
            }
            style={{
              animationDelay: "0.4s",
              margin: "10px 25px",
              height: "auto",
            }}
          >
            <div className="privatepartysection">
              <h3>{t("private_event_text")}</h3>
              <Switcher
                onClick={() => {
                  vibrate("light");
                  setPrivateParty((prevState) => !prevState);
                }}
              />
            </div>
            <Collapse in={privateParty} style={{ width: "100%" }}>
              {/* <input
                id="code"
                className="codename"
                placeholder="Secret Code..."
                inputMode="numeric"
                maxLength="6"
                value={privatePartyCode}
                onChange={(e) => {
                  if (e.target.value.length < 7) {
                    setPrivatePartyCode(e.target.value.replace(/\D/g, ""));
                  }
                }}
              /> */}
              <div
                onClick={() => setCodeDrawer(true)}
                style={{
                  height: "47px",
                  width: "100%",
                  background: "#1F1F1F",
                  margin: "10px 0",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "grey",
                    fontSize: "17px",
                    paddingLeft: "10px",
                  }}
                >
                  {t("create_code")}
                  <ArrowForwardRoundedIcon
                    style={{
                      marginLeft: "5px",
                      fontSize: "18px",
                      color: "rgb(253, 127, 77)",
                    }}
                  />
                </span>
              </div>
            </Collapse>
          </div>
          <CamUpload
            user={user}
            firebase={firebase}
            targetHeight={900}
            targetWidth={900}
            onClose={() => setOpenCamDrawer(false)}
            open={openCamDrawer}
            setLoader={(val) => setLoader(val)}
            setImgRef={(val) => setEventImage(val)}
            setPropImage={(val) => setImage(val)}
          />
          <label
            className={
              animation ? "outsidelabel scaleanimationinput" : "outsidelabel"
            }
            style={{ animationDelay: "0.4s", margin: "30px 0" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                width: "100%",
              }}
            >
              <label
                className={
                  animation
                    ? "outsidelabel photo scaleanimationinput"
                    : "outsidelabel"
                }
                style={{ animationDelay: "0.5s" }}
              >
                <label onClick={() => setOpenCamDrawer(true)}>
                  <div className="businessuploader">
                    {image ? (
                      <CheckRoundedIcon
                        style={{ color: "#fd7f4d", fontSize: "30px" }}
                      />
                    ) : (
                      <AddRoundedIcon
                        style={{ color: "#726e6e", fontSize: "30px" }}
                      />
                    )}
                  </div>
                </label>
              </label>
            </div>
          </label>
        </div>
      )}
    </>
  );
}
