import React, { useCallback, useEffect, useState } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import SwipeableRoutes from "react-swipeable-routes";
import Login from "./Auth/Login";
import Home from "./components/Home";
//i18n
import i18next from "i18next";
//Firebase Context
import firebase, { FirebaseContext } from "./firebase";
import FirebaseContexttwo from "./firebase/context";
import { useDocumentData } from "react-firebase-hooks/firestore";
//UseAuth where you get user from
import useAuth from "./Auth/useAuth";
import Forgot from "./components/Forgot";
import Navigation from "./components/Navigation";
import Map from "./components/Map";
import Profile from "./components/Profile";
import DriverPost from "./components/DriverPost";
import PartyPost from "./components/PartyPost";
import EventPost from "./components/EventPost";
import MapDriver from "./components/MapDriver";
import ClubsHome from "./components/ClubsHome";
import BusinessPost from "./components/BusinessPost";
import SearchFriend from "./components/SearchFriend";
import MakeParty from "./components/MakeParty";
import MakeSecretParty from "./components/MakeSecretParty";
import Languages from "./components/Languages";
import Head from "./components/Head";
import Promotiondetails from "./components/Promotiondetails";
import Modal from "@material-ui/core/Modal";
//Icon
import CircularProgress from "@material-ui/core/CircularProgress";
import Userpage from "./components/Userpage";
import Alert from "./components/Alert";
import useHideKeyboard from "./hooks/useHideKeyboard";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "moment/locale/en-gb";
import "moment/locale/de";

const Loader = () => (
  <Modal
    open
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <div className="modalwarning" style={{ padding: "20px" }}>
      <CircularProgress style={{ color: "#fd7f4d" }} />
    </div>
  </Modal>
);

function RouteGuard(props) {
  const history = useHistory();
  const user = useAuth();
  useEffect(() => {
    if (!user || (!user.loading && !user.uid)) {
      history.push("/");
    } else if (user) {
      history.push("/Home");
    }
  }, [user, history]);
  return (
    <>
      {user?.loading && <Loader />}
      {props.children}
    </>
  );
}

export const DEFAULT_LOCATION = { lat: 50, lng: 10.44 };

export const LocationContext = React.createContext({
  position: DEFAULT_LOCATION,
  declined: true,
});

export default function App() {
  const { hideKeyboard } = useHideKeyboard();

  const [location, setLocation] = useState(DEFAULT_LOCATION);
  const [declined, setDeclined] = useState(false);

  // update moment locale when language changes
  const { i18n } = useTranslation();
  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  React.useEffect(() => {
    console.log("init");
    navigator.geolocation.getCurrentPosition(
      (position) =>
        console.log("locationLog") ||
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
    );
    navigator.geolocation.watchPosition(
      function (position) {
        console.log("tracking active");
        setDeclined(false);
      },
      function (error) {
        if (error.code === error.PERMISSION_DENIED)
          console.log("tracking offline");
        setDeclined(true);
      }
    );
  }, []);

  const user = useAuth();

  const [userData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );
  const language = userData && userData.language;

  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    const fn = () => {
      if (user?.uid) {
        if (window?.cordova?.plugins?.firebase?.messaging) {
          window.cordova.plugins.firebase.messaging
            .requestPermission()
            .then(() =>
              window.cordova.plugins.firebase.messaging
                .getToken()
                .then((token) => {
                  firebase.db.doc(`users/${user && user.uid}`).set(
                    {
                      pushToken: {
                        [window.device.uuid]: token,
                      },
                    },
                    { merge: true }
                  );
                })
                .catch((e) => console.debug(`DEBUG FCM assignment error`, e))
            );
          if (window.cordova.platformId === "ios") {
            window.cordova.plugins.firebase.messaging
              .getToken()
              .then((token) =>
                firebase.db.doc(`users/${user && user.uid}`).set(
                  {
                    pushToken: {
                      [window.device.uuid]: token,
                    },
                  },
                  { merge: true }
                )
              )
              .catch((e) => console.debug(`DEBUG FCM assignment error`, e));
          }
        }
      }
    };

    document.addEventListener("deviceready", fn);
    return () => document.removeEventListener("deviceready", fn);
  }, [user?.uid]);

  return (
    <BrowserRouter>
      <LocationContext.Provider value={{ position: location, declined }}>
        {/* {user && declined && <Alert />} */}
        <FirebaseContext.Provider value={{ user, firebase }}>
          <div className="app">
            <div className="statusbar"></div>
            {user && <Navigation />}
            <RouteGuard>
              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/ClubsHome" component={ClubsHome} />
                <Route exact path="/Promotions" component={Promotiondetails} />
                <Route path="/BusinessPost" component={BusinessPost} />
                <Route path="/Forgot" component={Forgot} />
                <Route path="/Home/:id" component={DriverPost} />
                <Route path="/User/:UserId" component={Userpage} />
                <Route path="/Home" component={Home} />
                <Route path="/Party/:PartyId" component={PartyPost} />
                <Route path="/Events/:EventId" component={EventPost} />
                <Route path="/Maps/:DriversId" component={MapDriver} />
                <Route path="/Maps" component={Map} />
                <Route path="/Searchfriend" component={SearchFriend} />
                <Route path="/Makeparty" component={MakeParty} />
                <Route path="/Makesecretparty" component={MakeSecretParty} />
                <Route path="/Profile" component={Profile} />
                <Route path="/Languages" component={Languages} />
              </Switch>
            </RouteGuard>
          </div>
        </FirebaseContext.Provider>
      </LocationContext.Provider>
    </BrowserRouter>
  );
}
