import React from "react";
//Firebase Context
import FirebaseContext from "../firebase/context";
//Google Maps
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
//Router-DOM
import { NavLink, useParams, useHistory } from "react-router-dom";
//Firebase Hooks
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
//Google Map styles
import mapStyles from "./mapStyles";
import SeatMarker from "./SeatMarker";
//Moment
import moment from "moment";

function Maps() {
  const { firebase, user } = React.useContext(FirebaseContext);
  const [iconSize, setIconSize] = React.useState(50);
  const [currentLatitude, setCurrentLatitude] = React.useState();
  const [currentLongitude, setCurrentLongitude] = React.useState();
  const [zoom, setZoom] = React.useState(8);
  const [info, setInfo] = React.useState(false);

  const params = useParams();

  const [driversdata] = useDocumentData(
    firebase.db.doc(`drivers/${params.DriversId}`)
  );

  const [driverData] = useDocumentData(
    firebase.db.doc(`users/${driversdata && driversdata.id}`)
  );

  function showPosition(position) {
    setCurrentLongitude(position.coords.longitude);
    setCurrentLatitude(position.coords.latitude);
  }

  React.useEffect(() => {
    navigator.geolocation.getCurrentPosition(showPosition);
  }, []);

  function handleMarker() {
    setInfo(!info);
  }

  if (!driversdata) return null;
  if (!driverData) return null;

  return (
    <GoogleMap
      defaultOptions={{
        disableDefaultUI: true,
        styles: mapStyles,
        gestureHandling: "greedy",
      }}
      zoom={currentLatitude ? zoom : 5}
      center={{
        lat: currentLatitude || 42,
        lng: currentLongitude || 12,
      }}
    >
      {driversdata && driversdata.id === user.uid ? (
        <Marker
          onClick={handleMarker}
          position={{
            lat: currentLatitude,
            lng: currentLongitude,
          }}
          icon={{
            url: "../location.svg",
            scaledSize: new window.google.maps.Size(iconSize, iconSize),
          }}
        >
          {info && (
            <InfoWindow>
              <p> You </p>
            </InfoWindow>
          )}
        </Marker>
      ) : (
        <Marker
          onClick={handleMarker}
          position={{
            lat: driverData && driverData.lat,
            lng: driverData && driverData.long,
          }}
          icon={{
            url:
              (driversdata && driversdata.profilepicture) ||
              "../profileplaceholder.png",
            scaledSize: new window.google.maps.Size(iconSize, iconSize),
          }}
        >
          {info && (
            <InfoWindow>
              <p style={{ textAlign: "center" }}>
                {" "}
                {driversdata && driversdata.driver} <br />{" "}
                {moment(driverData && driverData.locationdate.toDate())
                  .startOf("seconds")
                  .fromNow()}{" "}
              </p>
            </InfoWindow>
          )}
        </Marker>
      )}

      {driversdata &&
        driversdata.seats?.map((seat) => {
          return <SeatMarker key={seat} uid={seat} />;
        })}
    </GoogleMap>
  );
}

const WrappedMap = withScriptjs(withGoogleMap(Maps));

export default function Map() {
  return (
    <div className="mapholder">
      <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing&key=AIzaSyDTMBQt_sQuFpRMhtlzbSo1lGZdWJjOUMY`}
        loadingElement={<div style={{ height: "100%" }} />}
        containerElement={<div style={{ height: "100%" }} />}
        mapElement={<div style={{ height: "100%" }} />}
      />
    </div>
  );
}
