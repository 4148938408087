import React from "react";
import FirebaseContext from "../firebase/context";
import { useDocumentData } from "react-firebase-hooks/firestore";

export default function Name({ name }) {
  const { firebase, user } = React.useContext(FirebaseContext);
  const [userData] = useDocumentData(firebase.db.doc(`users/${name}`));

  if (!userData) return null;

  return (
    <>
      {userData?.username?.length >= 11
        ? `${userData?.username?.substring(0, [10])}...`
        : userData?.username}
    </>
  );
}
