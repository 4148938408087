import React, { useEffect, useState } from "react";
import FirebaseContext from "../firebase/context";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import LockIcon from "@material-ui/icons/Lock";
import BackspaceRoundedIcon from "@material-ui/icons/BackspaceRounded";
//i18n
import { useTranslation } from "react-i18next";
import PartyPost from "./PartyPost";
import { SwipeableDrawer } from "@material-ui/core";
import useCloseDrawers from "../hooks/useDrawer";
import Guests from "./Guests";
import PrivateEventDisplay from "./PrivateEventDisplay";
import vibrate from "../util/feedback";

export default function MakeParty(onClose) {
  const { t } = useTranslation();
  const { firebase, user } = React.useContext(FirebaseContext);
  //Gets all users Data
  const [userdata] = useCollection(firebase.db.collection("users"));
  const [searchCode, setSearchCode] = useState("");
  const [startSearch, setStartSearch] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);
  const [hidePinScreen, setHidePinScreen] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [friendPartypostDrawer, setFriendPartypostDrawer] = useState({});
  const [party, setParty] = useState({ guests: {} });
  const history = useHistory();

  const [userData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );

  //Get date
  const date = React.useMemo(() => {
    const result = new Date();
    result.setDate(result.getDate() - 1);
    return result;
  }, []);

  const [partyData] = useCollection(
    firebase.db.collection("parties").where("date", ">", date)
  );

  useEffect(() => {
    if (searchCode.length === 6) {
      setStartAnimation(true);

      setTimeout(() => {
        setHidePinScreen(true);
        setStartSearch(true);
      }, 600);

      setTimeout(() => {
        setShowResults(true);
      }, 800);
    }
  }, [searchCode]);

  useCloseDrawers({
    drawers: [
      () => setFriendPartypostDrawer((prev) => ({ ...prev, open: false })),
    ],
  });

  return (
    <>
      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 200 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={friendPartypostDrawer.open}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() =>
          setFriendPartypostDrawer((prev) => ({ ...prev, open: false }))
        }
      >
        <PartyPost
          params={{ id: friendPartypostDrawer.id }}
          noPaddingBottom
          onClose={() =>
            setFriendPartypostDrawer((prev) => ({ ...prev, open: false }))
          }
        ></PartyPost>
      </SwipeableDrawer>
      <div
        className="partymodalwrapper"
        style={{ marginBottom: 0, overflow: "hidden" }}
      >
        <div className="partymodalheader">
          <div className="headtitleparty">
            <a onClick={onClose.onClose}>
              <ArrowBackIosRoundedIcon
                className="buttonanimation"
                style={{ color: "#FD7F4D" }}
              />
            </a>
            <h1>{t("secret_party")}</h1>
            <button
              style={{ position: "absolute", right: "20px", left: "initial" }}
            >
              <SearchIcon style={{ color: "#FD7F4D", fontSize: "30px" }} />
            </button>
          </div>
        </div>
        {!hidePinScreen && (
          <div
            className="secretSearchWrapper"
            style={{
              transform: startAnimation && "scale(10)",
              opacity: startAnimation && "0",
            }}
          >
            <div className="secretHeader">
              <LockIcon style={{ color: "white", fontSize: "35px" }} />
              <h2 className="secretHeaderTitle">{t("code_placeholder")}</h2>
              <div className="secretCodeIndicators">
                <span
                  className="singleCodeIndicator"
                  style={{
                    backgroundColor:
                      searchCode.length >= 1 && "rgb(253, 127, 77)",
                    border: searchCode.length >= 1 && "1px solid transparent",
                  }}
                ></span>
                <span
                  className="singleCodeIndicator"
                  style={{
                    backgroundColor:
                      searchCode.length >= 2 && "rgb(253, 127, 77)",
                    border: searchCode.length >= 2 && "1px solid transparent",
                  }}
                ></span>
                <span
                  className="singleCodeIndicator"
                  style={{
                    backgroundColor:
                      searchCode.length >= 3 && "rgb(253, 127, 77)",
                    border: searchCode.length >= 3 && "1px solid transparent",
                  }}
                ></span>
                <span
                  className="singleCodeIndicator"
                  style={{
                    backgroundColor:
                      searchCode.length >= 4 && "rgb(253, 127, 77)",
                    border: searchCode.length >= 4 && "1px solid transparent",
                  }}
                ></span>
                <span
                  className="singleCodeIndicator"
                  style={{
                    backgroundColor:
                      searchCode.length >= 5 && "rgb(253, 127, 77)",
                    border: searchCode.length >= 5 && "1px solid transparent",
                  }}
                ></span>
                <span
                  className="singleCodeIndicator"
                  style={{
                    backgroundColor:
                      searchCode.length >= 6 && "rgb(253, 127, 77)",
                    border: searchCode.length >= 6 && "1px solid transparent",
                  }}
                ></span>
              </div>
            </div>
            <div className="pinwrapper">
              <div className="pinNumberWrapper">
                <div className="pinnumber">
                  <span
                    className="innerPinNumber"
                    onClick={() => {
                      vibrate("light");
                      if (searchCode.length < 6) {
                        setSearchCode(searchCode + "1");
                      }
                    }}
                  >
                    1
                  </span>
                </div>
                <div className="pinnumber">
                  <span
                    className="innerPinNumber"
                    onClick={() => {
                      vibrate("light");
                      if (searchCode.length < 6) {
                        setSearchCode(searchCode + "2");
                      }
                    }}
                  >
                    2
                  </span>
                </div>
                <div className="pinnumber">
                  <span
                    className="innerPinNumber"
                    onClick={() => {
                      vibrate("light");
                      if (searchCode.length < 6) {
                        setSearchCode(searchCode + "3");
                      }
                    }}
                  >
                    3
                  </span>
                </div>
                <div className="pinnumber">
                  <span
                    className="innerPinNumber"
                    onClick={() => {
                      vibrate("light");
                      if (searchCode.length < 6) {
                        setSearchCode(searchCode + "4");
                      }
                    }}
                  >
                    4
                  </span>
                </div>
                <div className="pinnumber">
                  <span
                    className="innerPinNumber"
                    onClick={() => {
                      vibrate("light");
                      if (searchCode.length < 6) {
                        setSearchCode(searchCode + "5");
                      }
                    }}
                  >
                    5
                  </span>
                </div>
                <div className="pinnumber">
                  <span
                    className="innerPinNumber"
                    onClick={() => {
                      vibrate("light");
                      if (searchCode.length < 6) {
                        setSearchCode(searchCode + "6");
                      }
                    }}
                  >
                    6
                  </span>
                </div>
                <div className="pinnumber">
                  <span
                    className="innerPinNumber"
                    onClick={() => {
                      vibrate("light");
                      if (searchCode.length < 6) {
                        setSearchCode(searchCode + "7");
                      }
                    }}
                  >
                    7
                  </span>
                </div>
                <div className="pinnumber">
                  <span
                    className="innerPinNumber"
                    onClick={() => {
                      vibrate("light");
                      if (searchCode.length < 6) {
                        setSearchCode(searchCode + "8");
                      }
                    }}
                  >
                    8
                  </span>
                </div>
                <div className="pinnumber">
                  <span
                    className="innerPinNumber"
                    onClick={() => {
                      vibrate("light");
                      if (searchCode.length < 6) {
                        setSearchCode(searchCode + "9");
                      }
                    }}
                  >
                    9
                  </span>
                </div>
                <div className="pinnumber" style={{ opacity: "0" }}>
                  <span className="innerPinNumber"></span>
                </div>
                <div className="pinnumber">
                  <span
                    className="innerPinNumber"
                    onClick={() => {
                      vibrate("light");
                      if (searchCode.length < 6) {
                        setSearchCode(searchCode + "0");
                      }
                    }}
                  >
                    0
                  </span>
                </div>
                <div
                  className="pinnumber"
                  onClick={() => {
                    const newStr = String(searchCode).slice(0, -1);
                    setSearchCode(newStr);
                  }}
                >
                  <BackspaceRoundedIcon
                    style={{ color: "rgb(253, 127, 77)", fontSize: "25px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {startSearch && (
          <div
            className="secretSearchResultWrapper"
            style={{ opacity: showResults ? 1 : 0 }}
          >
            {partyData &&
              partyData.docs
                .filter((party) => {
                  if (searchCode === "") {
                    return "";
                  } else if (
                    startSearch === true &&
                    party.data().secretCode === searchCode
                  ) {
                    return party;
                  }
                })
                .map((party, key) => (
                  <PrivateEventDisplay
                    party={party}
                    onClick={() =>
                      setFriendPartypostDrawer({
                        id: party.id,
                        open: true,
                      })
                    }
                  />
                ))}
          </div>
        )}
      </div>
    </>
  );
}
