import React from "react";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";

export default function SingleSetting(props) {
  return (
    <div className="singleinnersetting" onClick={props.onClick}>
      <h3 style={props.color && { color: props.color }}>{props.label}</h3>
      <p>
        {props.noArrow ? (
          <span style={{ marginRight: "5px" }}>
            {props.info && props.info.length > 24
              ? `${props.info.substring(0, [23])}...`
              : props.info}
          </span>
        ) : props.info && props.info.length > 24 ? (
          `${props.info.substring(0, [23])}...`
        ) : (
          props.info
        )}
        {props.noArrow ? (
          ""
        ) : (
          <ChevronRightRoundedIcon
            style={{
              color: "#616060",
              fontSize: "28px",
              marginLeft: "5px",
            }}
          />
        )}
      </p>
    </div>
  );
}
