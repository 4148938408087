import React, { useState } from "react";
import FirebaseContext from "../firebase/context";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SingleSetting from "./SingleSetting";
import { CircularProgress, SwipeableDrawer } from "@material-ui/core";
import ChangeUsernamePage from "./ChangeUsernamePage";
import EmailOverview from "./EmailOverview";
import InstagramPage from "./InstagramPage";
import SnapchatPage from "./SnapchatPage";
import Languages from "./Languages";
import SpottetBug from "./SpottedBug";
import SuggestionPage from "./SuggestionPage";
import useCloseDrawers from "../hooks/useDrawer";

export default function Settings(onClose) {
  const { firebase, user } = React.useContext(FirebaseContext);
  const { t } = useTranslation();
  const [userNameDrawer, setUserNameDrawer] = useState(false);
  const [emailDrawer, setEmailDrawer] = useState(false);
  const [instagramPage, setInstagramPage] = useState(false);
  const [snapchatPage, setSnapchatPage] = useState(false);
  const [languagedrawer, setLanguagedrawer] = React.useState(false);
  const [spottedBugDrawer, setSpottedBugDrawer] = React.useState(false);
  const [suggestionDrawer, setSuggestionDrawer] = React.useState(false);
  const [deleteAccountModal, setDeleteAccountModal] = React.useState(false);
  const [allUsers] = useCollection(firebase.db.collection("users"));
  const [loading, setLoading] = useState(false);
  const [userData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );

  const promotions = (userData && userData.promotions) || 0;

  const [filteredOfficialpartyData] = useCollection(
    user.uid
      ? firebase.db.collection("b2bposts").where("id", "==", user.uid)
      : null
  );

  const totalviews = filteredOfficialpartyData?.docs?.reduce(
    (accumulator, current) => accumulator + current.data().views?.length || 0,
    0
  );

  function settingsswitch(props) {
    if (window.cordova && window.cordova.plugins.settings) {
      console.log("openNativeSettings is active");
      window.cordova.plugins.settings.open(
        props,
        function () {
          console.log("opened settings");
        },
        function () {
          console.log("failed to open settings");
        }
      );
    } else {
      console.log("openNativeSettings is not active!");
    }
  }

  async function deleteAccount() {
    setLoading(true);
    const userRef = firebase.db.doc(`users/${user?.uid}`);

    const friendDeletionPromises = userData?.friends?.map((id) => {
      const friendRef = firebase.db.collection("users").doc(id);

      return friendRef.get().then((doc) => {
        if (doc.exists) {
          const batch = firebase.db.batch();
          batch.update(friendRef, {
            friends: firebase.FieldValue.arrayRemove(user?.uid),
          });

          return batch.commit().catch((error) => {
            console.error(
              "Error performing batch operation for user:",
              id,
              error
            );
          });
        }
      });
    });

    Promise.all(friendDeletionPromises)
      .then(() => userRef.delete())
      .then(() => user.delete())
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error deleting account:", error);
        setLoading(false);
      });
  }

  useCloseDrawers({
    drawers: [
      () => setUserNameDrawer(false),
      () => setEmailDrawer(false),
      () => setInstagramPage(false),
      () => setSnapchatPage(false),
      () => setLanguagedrawer(false),
      () => setSpottedBugDrawer(false),
      () => setSuggestionDrawer(false),
    ],
  });

  return (
    <>
      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 200 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={userNameDrawer}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setUserNameDrawer(false)}
      >
        <ChangeUsernamePage onClose={() => setUserNameDrawer(false)} />
      </SwipeableDrawer>
      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 200 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={emailDrawer}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setEmailDrawer(false)}
      >
        <EmailOverview onClose={() => setEmailDrawer(false)} />
      </SwipeableDrawer>
      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 200 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={instagramPage}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setInstagramPage(false)}
      >
        <InstagramPage onClose={() => setInstagramPage(false)} />
      </SwipeableDrawer>
      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 200 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={snapchatPage}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setSnapchatPage(false)}
      >
        <SnapchatPage onClose={() => setSnapchatPage(false)} />
      </SwipeableDrawer>
      <SwipeableDrawer
        transitionDuration={300}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "20px 20px 0 0",
            background: "transparent",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="bottom"
        open={languagedrawer}
        onClose={() => setLanguagedrawer(false)}
      >
        <Languages />
      </SwipeableDrawer>
      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 200 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={spottedBugDrawer}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setSpottedBugDrawer(false)}
      >
        <SpottetBug onClose={() => setSpottedBugDrawer(false)} />
      </SwipeableDrawer>
      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 200 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={suggestionDrawer}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setSuggestionDrawer(false)}
      >
        <SuggestionPage onClose={() => setSuggestionDrawer(false)} />
      </SwipeableDrawer>
      <SwipeableDrawer
        PaperProps={{
          elevation: 0,
          style: {
            background: "#262626",
            width: "100%",
            borderRadius: "20px 20px 0 0",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="bottom"
        open={deleteAccountModal}
        disableSwipeToOpen
        onClose={() => setDeleteAccountModal(false)}
      >
        <div className="businessInfoWrapper">
          <span className="swipeline" />
          <h2>{t("delete_account")}</h2>
          <p>{t("delete_account_text")}</p>
          <button
            style={{ height: "55px" }}
            onClick={deleteAccount}
            className="paymentbutton free"
          >
            {loading ? (
              <CircularProgress
                style={{
                  color: "white",
                  width: "25px",
                  height: "25px",
                }}
              />
            ) : (
              t("delete_account")
            )}
          </button>
        </div>
      </SwipeableDrawer>

      <div className="settingspagewrapper">
        <div className="businesshead">
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              borderBottom: "1px solid rgb(48 47 47)",
            }}
          >
            <ArrowBackIosIcon
              onClick={onClose.onClose}
              style={{
                color: "#FD7F4D",
                position: "absolute",
                left: "26px",
                top: "50%",
                transform: "translate(0%, -50%)",
                padding: "10px",
                marginLeft: "-10px",
              }}
            />
            <h1> {t("settings")} </h1>
          </div>
        </div>
        {/* HEAD */}
        <div className="mainsettingsection">
          <h2 className="settingsectiontitle"> {t("my_account")} </h2>
          <div className="settingsection">
            <SingleSetting
              label={t("username")}
              info={userData?.username}
              onClick={() => setUserNameDrawer(true)}
            />
            <SingleSetting
              label="E-Mail"
              info={user?.email}
              onClick={() => setEmailDrawer(true)}
            />
            <SingleSetting
              label="Instagram"
              info={userData?.instagram}
              onClick={() => setInstagramPage(true)}
            />
            <SingleSetting
              label="Snapchat"
              info={userData?.snapchat}
              onClick={() => setSnapchatPage(true)}
            />
            {/* <SingleSetting label={t("password")} /> */}
            <SingleSetting
              label={t("language")}
              onClick={() => setLanguagedrawer(true)}
            />
            <SingleSetting
              label={t("available_promotions")}
              info={promotions}
              noArrow
            />
            <SingleSetting
              label={t("posted_promotions")}
              info={
                filteredOfficialpartyData?.docs?.length
                  ? filteredOfficialpartyData?.docs?.length
                  : 0
              }
              noArrow
            />
            <SingleSetting label={t("total_views")} info={totalviews} noArrow />
          </div>

          <h2 className="settingsectiontitle"> {t("privacy_controls")} </h2>
          <div className="settingsection">
            <SingleSetting
              label={t("location")}
              onClick={() => settingsswitch("locations")}
            />
            <SingleSetting
              label={t("camera")}
              onClick={() => settingsswitch("photos")}
            />
            <SingleSetting
              label={t("notification")}
              onClick={() => settingsswitch("notification_id")}
            />
          </div>
          <h2 className="settingsectiontitle"> {t("feedback")} </h2>
          <div className="settingsection">
            <SingleSetting
              label={t("bug_spotted")}
              onClick={() => setSpottedBugDrawer(true)}
            />
            <SingleSetting
              label={t("suggestion")}
              onClick={() => setSuggestionDrawer(true)}
            />
          </div>
          <h2 className="settingsectiontitle">
            {/* {t("more_information")}  */}
          </h2>
          <div className="settingsection">
            {/* <SingleSetting label={t("privacy_policy")} />
            <SingleSetting label={t("terms_of_service")} />
            <SingleSetting label={t("other_legal")} /> */}
            <SingleSetting info={`#${allUsers?.docs?.length}`} noArrow />
            <SingleSetting
              label={t("delete_account")}
              noArrow
              color="#f23b57"
              onClick={() => setDeleteAccountModal(true)}
            />
            <SingleSetting
              label="Logout"
              onClick={() => firebase.logout(user)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
