import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FirebaseContext from "../firebase/context";
import { useTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import { useDocumentData, useCollection } from "react-firebase-hooks/firestore";
import TurnedInRoundedIcon from "@material-ui/icons/TurnedInRounded";
//Moment
import moment from "moment";
//Count-Up Animation
import FlipNumbers from "react-flip-numbers";
import { SwipeableDrawer, Slide, Modal } from "@material-ui/core";
import BusinessPostCreatePage from "./BusinessPost";
import EventPost from "./EventPost";
import useCloseDrawers from "../hooks/useDrawer";
import SwipeableViews from "react-swipeable-views";
//IMG
import Startimage from "../images/leaf.png";
import Basicimage from "../images/target.png";
import Proimage from "../images/rocket.png";
import Businessimage from "../images/business.png";
import Promoimg from "../images/promoimg.png";
import { PayPalButton } from "react-paypal-button-v2";

export default function Promotiondetails(onClose) {
  const { firebase, user } = React.useContext(FirebaseContext);
  const { t } = useTranslation();
  const [promotionModal, setPromotionModal] = React.useState();
  const [eventModal, setEventModal] = React.useState({});
  const [paymentModal, setPaymentModal] = useState(false);
  const [index, setIndex] = useState(0);
  const [paymentMethodModal, setPaymentMethodModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [payAmount, setPayAmount] = useState("");
  const [infoModal, setInfoModal] = useState(false);

  const [filteredOfficialpartyData] = useCollection(
    user.uid
      ? firebase.db.collection("b2bposts").where("id", "==", user.uid)
      : null
  );

  const [userData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );

  const promotions = (userData && userData.promotions) || 0;

  useCloseDrawers({
    drawers: [
      () => setPromotionModal(false),
      () => setEventModal((prev) => ({ ...prev, open: false })),
    ],
  });

  if (!user.uid) return null;

  return (
    <>
      <Modal
        open={paymentMethodModal}
        onClose={() => setPaymentMethodModal(false)}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Slide
          style={{ outline: "none" }}
          direction="up"
          in={paymentMethodModal}
        >
          <div className="paymentmodal">
            {loader && <p>loading...</p>}
            <PayPalButton
              onButtonReady={() => setLoader(false)}
              options={{
                clientId:
                  "AUXyD5AyN6ytsACY64pjQ993giJBkH5CZ952GiUrxVZBxokkf8Lm55ykUQBxf3WueiW3MCPU1KyPaA3o",
                currency: "EUR",
              }}
              amount={payAmount}
              onSuccess={(details, data) => {
                firebase.db
                  .doc(`users/${user && user.uid}`)
                  .update({ promotions: promotions + 1 });
                setPaymentMethodModal(false);
                return fetch("/paypal-transaction-complete", {
                  method: "post",
                  body: JSON.stringify({
                    orderID: data.orderID,
                  }),
                });
              }}
            />
          </div>
        </Slide>
      </Modal>

      <SwipeableDrawer
        PaperProps={{
          elevation: 0,
          style: {
            background: "#262626",
            width: "100%",
            borderRadius: "20px 20px 0 0",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="bottom"
        open={infoModal}
        disableSwipeToOpen
        onClose={() => setInfoModal(false)}
      >
        <div className="businessInfoWrapper">
          <span className="swipeline" />
          <h2>{t("create_a_post")}</h2>
          <p>{t("create_a_post_free")}</p>
          <button
            onClick={() => {
              setInfoModal(false);
              setPromotionModal(true);
            }}
            className="paymentbutton free"
          >
            {t("create_a_post_for")} <br /> <span>0,00€ / 1 Post</span>
          </button>
        </div>
      </SwipeableDrawer>

      <SwipeableDrawer
        PaperProps={{
          elevation: 0,
          style: {
            background: "#262626",
            width: "100%",
            borderRadius: "20px 20px 0 0",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="bottom"
        open={paymentModal}
        disableSwipeToOpen
        onClose={() => setPaymentModal(false)}
      >
        <div className="shopwrapper">
          <span className="swipeline"></span>
          <h2
            style={
              index === 0
                ? { color: "rgb(29, 208, 93)" }
                : index === 1
                ? { color: "rgb(94, 203, 241)" }
                : index === 2
                ? { color: "rgb(217, 28, 81)" }
                : index === 3
                ? { color: "white" }
                : {}
            }
          >
            Shop
          </h2>
          <SwipeableViews
            index={index}
            onChangeIndex={(index) => setIndex(index)}
            style={{ width: "100%" }}
          >
            <div className="paymentpackage">
              <img src={Startimage} />
              <h2>Starter</h2>
              <p> {t("starter_description")} </p>
              <div className="paymentidicator">
                <span
                  style={
                    index === 0
                      ? { background: "white" }
                      : { transform: "scale(.8)" }
                  }
                ></span>
                <span
                  style={
                    index === 1
                      ? { background: "white" }
                      : { transform: "scale(.8)" }
                  }
                ></span>
                <span
                  style={
                    index === 2
                      ? { background: "white" }
                      : { transform: "scale(.8)" }
                  }
                ></span>
                <span
                  style={
                    index === 3
                      ? { background: "white" }
                      : { transform: "scale(.8)" }
                  }
                ></span>
              </div>
              <button
                onClick={() => {
                  setPayAmount("9.99");
                  setPaymentMethodModal(true);
                }}
                className="paymentbutton"
                style={{ background: "#1dd05d" }}
              >
                {t("starter_button")} <br /> <span>9,99€ / 1 Post</span>
              </button>
              <span
                onClick={() => setPaymentModal(false)}
                className="declinebutton"
              >
                {t("next_time")}
              </span>
            </div>
            <div className="paymentpackage">
              <img src={Basicimage} />
              <h2>Basic</h2>
              <p> {t("basic_description")} </p>
              <div className="paymentidicator">
                <span
                  style={
                    index === 0
                      ? { background: "white" }
                      : { transform: "scale(.8)" }
                  }
                ></span>
                <span
                  style={
                    index === 1
                      ? { background: "white" }
                      : { transform: "scale(.8)" }
                  }
                ></span>
                <span
                  style={
                    index === 2
                      ? { background: "white" }
                      : { transform: "scale(.8)" }
                  }
                ></span>
                <span
                  style={
                    index === 3
                      ? { background: "white" }
                      : { transform: "scale(.8)" }
                  }
                ></span>
              </div>
              <button
                onClick={() => {
                  setPayAmount("36");
                  setPaymentMethodModal(true);
                }}
                className="paymentbutton"
                style={{ background: "#5ecbf1" }}
              >
                {t("basic_button")} <br /> <span>36€ / 4 Posts</span>
              </button>
              <span
                onClick={() => setPaymentModal(false)}
                className="declinebutton"
              >
                {t("next_time")}
              </span>
            </div>
            <div className="paymentpackage">
              <img src={Proimage} />
              <h2>Pro</h2>
              <p> {t("pro_description")} </p>
              <div className="paymentidicator">
                <span
                  style={
                    index === 0
                      ? { background: "white" }
                      : { transform: "scale(.8)" }
                  }
                ></span>
                <span
                  style={
                    index === 1
                      ? { background: "white" }
                      : { transform: "scale(.8)" }
                  }
                ></span>
                <span
                  style={
                    index === 2
                      ? { background: "white" }
                      : { transform: "scale(.8)" }
                  }
                ></span>
                <span
                  style={
                    index === 3
                      ? { background: "white" }
                      : { transform: "scale(.8)" }
                  }
                ></span>
              </div>
              <button
                onClick={() => {
                  setPayAmount("108");
                  setPaymentMethodModal(true);
                }}
                className="paymentbutton"
                style={{ background: "#d91c51" }}
              >
                {t("pro_button")} <br /> <span>108€ / 12 Posts</span>
              </button>
              <span
                onClick={() => setPaymentModal(false)}
                className="declinebutton"
              >
                {t("next_time")}
              </span>
            </div>
            <div className="paymentpackage">
              <img src={Businessimage} />
              <h2>Business</h2>
              <p> {t("business_description")} </p>
              <div className="paymentidicator">
                <span
                  style={
                    index === 0
                      ? { background: "white" }
                      : { transform: "scale(.8)" }
                  }
                ></span>
                <span
                  style={
                    index === 1
                      ? { background: "white" }
                      : { transform: "scale(.8)" }
                  }
                ></span>
                <span
                  style={
                    index === 2
                      ? { background: "white" }
                      : { transform: "scale(.8)" }
                  }
                ></span>
                <span
                  style={
                    index === 3
                      ? { background: "white" }
                      : { transform: "scale(.8)" }
                  }
                ></span>
              </div>
              <button
                onClick={() => {
                  setPayAmount("216");
                  setPaymentMethodModal(true);
                }}
                className="paymentbutton"
                style={{ background: "#4b2746" }}
              >
                {t("business_button")} <br /> <span>216€ / 24 Posts</span>
              </button>
              <span
                onClick={() => setPaymentModal(false)}
                className="declinebutton"
              >
                {t("next_time")}
              </span>
            </div>
          </SwipeableViews>
        </div>
      </SwipeableDrawer>
      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 199 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={eventModal.open}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setEventModal((prev) => ({ ...prev, open: false }))}
      >
        <EventPost
          params={{ id: eventModal.id }}
          onClose={() => setEventModal((prev) => ({ ...prev, open: false }))}
        ></EventPost>
      </SwipeableDrawer>

      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 199 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={promotionModal}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setPromotionModal(false)}
      >
        <BusinessPostCreatePage onClose={() => setPromotionModal(false)} />
      </SwipeableDrawer>
      <div className="clubshome promotions">
        <div className="businesshead">
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              borderBottom: "1px solid rgb(48 47 47)",
            }}
          >
            <ArrowBackIosIcon
              onClick={onClose.onClose}
              style={{
                color: "#FD7F4D",
                position: "absolute",
                left: "26px",
                top: "50%",
                padding: "10px",
                marginLeft: "-10px",
                transform: "translate(0%, -50%)",
              }}
            />
            <h1> {t("promotions")} </h1>

            {/* <span
              className="promotionbalance"
              onClick={() => setPaymentModal(true)}
            >
              {promotions} <TurnedInRoundedIcon />{" "}
            </span> */}
          </div>
        </div>
        <div className="promotionmain">
          <div className="promotionhead">
            <h2> {t("created_promotions")} </h2>
            {/* {promotions === 0 ? (
              <span
                onClick={() => setPaymentModal(true)}
                style={{ background: "#fd7049", padding: "8px" }}
              >
                <AddIcon style={{ fontSize: "22px" }} />
              </span>
            ) : (
              <span
                onClick={() => setPromotionModal(true)}
                style={{ background: "#fd7049", padding: "8px" }}
              >
                <AddIcon style={{ fontSize: "22px" }} />
              </span>
            )} */}
            <span
              onClick={() => setInfoModal(true)}
              style={{ background: "#fd7049", padding: "8px" }}
            >
              <AddIcon style={{ fontSize: "22px" }} />
            </span>
          </div>
          <div className="promotionposts">
            {filteredOfficialpartyData &&
              (filteredOfficialpartyData.docs.length ? (
                filteredOfficialpartyData.docs.map((post) => (
                  <div
                    key={post.id}
                    style={{
                      textDecoration: "none",
                      color: "initial",
                      width: "100%",
                    }}
                    onClick={() => setEventModal({ id: post.id, open: true })}
                  >
                    <div className="promotionlink">
                      <div className="promotionlinkimage">
                        <img
                          src={post.data().image && post.data().image}
                          // alt={post.data().image && post.data().image}
                        />
                      </div>
                      <p style={{ marginLeft: "15px" }}>
                        {" "}
                        {post.data().clubname.length > 15
                          ? `${post.data().clubname.substring(0, [15])}...`
                          : post.data().clubname}
                        <br />{" "}
                        <span>
                          {moment(post.data().date.toDate()).format("MMM DD,")}{" "}
                          {post.data().opens}, {post.data().entranceprice}{" "}
                          <br />
                          {post.data().address.length > 15
                            ? `${post.data().address.substring(0, [15])}...`
                            : post.data().address}{" "}
                        </span>{" "}
                      </p>
                      <p className="viewbutton analytics">
                        <RemoveRedEyeIcon style={{ fontSize: "20px" }} />
                        <br />
                        <FlipNumbers
                          numberStyle
                          height={11}
                          width={8}
                          color="white"
                          play
                          perspective={100}
                          numbers={`${post.data().views?.length || 0}`}
                        />
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="selectnow" style={{ margin: "10px 0" }}>
                  <div className="likediv">
                    <img src={Promoimg} />
                  </div>
                  <h2>{t("no_promo_longtext")}</h2>
                  <p> {t("no_official_events_now")} </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
