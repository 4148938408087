import React, { useEffect } from "react";
import FirebaseContext from "../firebase/context";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIosRounded";
import Avatar from "@material-ui/core/Avatar";
import AvatarComponent from "./AvatarComponent";
import SearchIcon from "@material-ui/icons/Search";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Link } from "react-router-dom";
import { SettingsEthernet } from "@material-ui/icons";
//i18n
import { useTranslation } from "react-i18next";
import { SwipeableDrawer } from "@material-ui/core";
import Userpage from "./Userpage";
import useCloseDrawers from "../hooks/useDrawer";

export default function SearchFriend(onClose) {
  const { t } = useTranslation();
  const { firebase, user } = React.useContext(FirebaseContext);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [initalSearch, setInitalSearch] = React.useState(true);
  const [userPage, setUserPage] = React.useState({});
  //Gets all users Data
  const [userdata] = useCollection(firebase.db.collection("users"));

  const [userData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );
  const friendslist = (userData && userData.friends) || [];

  const userMap =
    userdata &&
    userdata.docs.filter((user) => {
      if (searchTerm === "") {
        return "";
      } else if (
        user.data().username.toLowerCase().startsWith(searchTerm.toLowerCase())
      ) {
        return user;
      }
    });

  useCloseDrawers({
    drawers: [() => setUserPage((prev) => ({ ...prev, open: false }))],
  });

  return (
    <>
      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 200 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={userPage.open}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setUserPage((prev) => ({ ...prev, open: false }))}
      >
        <Userpage
          params={{ UserId: userPage.id }}
          noPaddingBottom
          onClose={() => setUserPage((prev) => ({ ...prev, open: false }))}
        ></Userpage>
      </SwipeableDrawer>
      <div className="searchmodal">
        <div className="addfrienddiv">
          <div className="inputfriendfirst friends adding">
            <div
              style={{
                margin: "0",
                width: "100%",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <ArrowBackIosIcon
                onClick={onClose.onClose}
                className="closemodaliconarrow"
                style={{
                  color: "#FD7F4D",
                  paddingRight: "15px",
                }}
              />
              <input
                autoFocus
                className="searchbar friend adding"
                onChange={(event) => {
                  setInitalSearch(false);
                  setSearchTerm(event.target.value);
                }}
                value={searchTerm}
                style={{ borderRadius: "40px" }}
                placeholder={`@ ${t("search_placeholder")}`}
              />
            </div>
            {!searchTerm ? (
              <SearchIcon className="addingsvg" />
            ) : (
              <HighlightOffIcon
                style={{ paddingRight: "17px", color: "#fd7f4d" }}
                onClick={() => setSearchTerm("")}
              />
            )}
          </div>
          <div style={{ marginTop: "24px" }}>
            <div className="posts">
              {initalSearch === false &&
                searchTerm !== "" &&
                userMap?.length === 0 && (
                  <p style={{ color: "#7f7e7e", fontWeight: "500" }}>
                    {t("no_results")} &#128169;
                  </p>
                )}
              {userMap?.map((user) => (
                <div
                  key={user.id}
                  onClick={() => setUserPage({ id: user.id, open: true })}
                  className="drivepost add"
                  style={{
                    background: "transparent",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      alt="Profile"
                      src={user.data().imgRef}
                      style={{ height: "4rem", width: "4rem" }}
                    />
                    <p style={{ marginLeft: "15px", maxWidth: "initial" }}>
                      {user.data().username && user.data().username.length > 29
                        ? `${user.data().username.substring(0, [28])}...`
                        : user.data().username}
                      <br />
                      {user.id === firebase.auth.currentUser.uid ? (
                        ""
                      ) : (
                        <>
                          {user
                            .data()
                            .friends?.filter((a) => friendslist.includes(a))
                            .length >= 1 ? (
                            <span className="mutualfriends">
                              {
                                user
                                  .data()
                                  .friends?.filter((a) =>
                                    friendslist.includes(a)
                                  ).length
                              }{" "}
                              {user
                                .data()
                                .friends?.filter((a) => friendslist.includes(a))
                                .length === 1
                                ? t("mutual_friend")
                                : t("mutual_friends")}
                            </span>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
