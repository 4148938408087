import firebase from "../firebase";

// TODO: max request size for Vercel API is 4.5MB, so we
// should add feedback for the user when this is exceeded

// in development, API doesn't exist, so use the local no-resize upload logic
async function uploadLocally(file, uid) {
  const hash = Date.now();
  const fileType = file.split(";")[0].split("/")[1];
  const imgRef = `https://firebasestorage.googleapis.com/v0/b/exude-hopon.appspot.com/o/images%2F${uid}%2F${hash}.${fileType}?alt=media`;

  await firebase.storage
    .ref(`images/${uid}/${hash}.${fileType}`)
    .putString(file, "data_url");

  return imgRef;
}

// frontend wrapper to determine which upload logic to use
async function uploadFile(file, user) {
  if (process.env.NODE_ENV === "development") {
    console.debug(`skipping upload api in development`);
    const imgRef = await uploadLocally(file, user.uid);
    return { success: true, imgRef };
  }

  const response = await fetch("api/upload", {
    method: "POST",
    body: JSON.stringify({ file, uid: user.uid }),
  });
  const { success, imgRef } = await response.json();
  return { success, imgRef };
}

export default uploadFile;
