/* eslint-disable no-undef */
import React, { useCallback, useState } from "react";
import FirebaseContext from "../firebase/context";
import EditIcon from "@material-ui/icons/Edit";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import AvatarComponent from "./AvatarComponent";
import AvatarComponentPost from "./AvatarComponentPost";
import Snapchatcustom from "../images/snapchatcustom.svg";
import Snapchatcustomthree from "../images/snapchatcustomthree.svg";
import InstagramIcon from "@material-ui/icons/Instagram";
import Modal from "@material-ui/core/Modal";
import ClearIcon from "@material-ui/icons/Clear";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import { SwipeableDrawer } from "@material-ui/core";
import { useTranslation } from "react-i18next";
//Date FNS
import subHours from "date-fns/subHours";
import differenceInHours from "date-fns/differenceInHours";
import firebaseI from "firebase";
import "react-image-crop/dist/ReactCrop.css";
import Drawer from "@material-ui/core/Drawer";
import Languages from "./Languages";
import DriverPost from "./DriverPost";
import ClubsHome from "./ClubsHome";
import Settings from "./Settings";
import FavouritesPage from "./FavouritesPage";
import useCloseDrawers from "../hooks/useDrawer";
import CamUpload from "../util/CamUpload";

const { Timestamp } = firebaseI.firestore;

export default function Profile() {
  const [driverModal, setDriverModal] = useState({});
  const [promotionModal, setPromotionModal] = useState();
  const { firebase, storage, user } = React.useContext(FirebaseContext);
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [userData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );
  const [posts, setPosts] = React.useState(true);
  const [languagedrawer, setLanguagedrawer] = React.useState(false);
  const [instagramlink, setInstagramlink] = React.useState("");
  const [snapchatlink, setSnapchatlink] = React.useState("");
  const myInstagram = userData && userData.instagram;
  const mySnapchat = userData && userData.snapchat;
  const myCovid = userData && userData.covid;
  const [settingsPage, setSettingsPage] = useState(false);
  const [openinsta, setOpeninsta] = useState(false);
  const [opensnap, setOpensnap] = useState(false);
  const [favouritesDrawer, setFavouritesDrawer] = useState(false);
  const [openCamDrawer, setOpenCamDrawer] = useState(false);

  function handleInstagram() {
    firebase.db
      .doc(`users/${user && user.uid}`)
      .update({ instagram: instagramlink });
    setOpeninsta(false);
  }

  function deleteInstagramlink() {
    firebase.db.doc(`users/${user && user.uid}`).update({ instagram: "" });
    setOpeninsta(false);
  }

  function handleSnapchat() {
    firebase.db
      .doc(`users/${user && user.uid}`)
      .update({ snapchat: snapchatlink });
    setOpensnap(false);
  }

  function deleteSnapchatlink() {
    firebase.db.doc(`users/${user && user.uid}`).update({ snapchat: "" });
    setOpensnap(false);
  }

  //Get date
  const date = React.useMemo(
    () => Timestamp.fromDate(subHours(new Date(), 24)),
    []
  );

  const [driversdata] = useCollection(
    user?.uid
      ? firebase.db
          .collection(`drivers`)
          .where("id", "==", user.uid)
          .where("date", ">=", date)
      : null
  );

  useCloseDrawers({
    drawers: [
      () => setPromotionModal(false),
      () => setFavouritesDrawer(false),
      () => setSettingsPage(false),
      () => setDriverModal((prev) => ({ ...prev, open: false })),
    ],
  });

  return (
    <>
      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 199 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={promotionModal}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setPromotionModal(false)}
      >
        <ClubsHome onClose={() => setPromotionModal(false)} />
      </SwipeableDrawer>

      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 199 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={favouritesDrawer}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setFavouritesDrawer(false)}
      >
        <FavouritesPage onClose={() => setFavouritesDrawer(false)} />
      </SwipeableDrawer>

      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 200 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={settingsPage}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setSettingsPage(false)}
      >
        <Settings onClose={() => setSettingsPage(false)} />
      </SwipeableDrawer>

      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 199 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={driverModal.open}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setDriverModal((prev) => ({ ...prev, open: false }))}
      >
        <DriverPost
          params={{ id: driverModal.id }}
          onClose={() => setDriverModal((prev) => ({ ...prev, open: false }))}
        ></DriverPost>
      </SwipeableDrawer>
      <Drawer
        transitionDuration={300}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "20px 20px 0 0",
            background: "transparent",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="bottom"
        open={openinsta}
        onClose={() => setOpeninsta(false)}
      >
        <div className="alert socials">
          <div className="alertavatar socials">
            <InstagramIcon style={{ color: "#FE4040", fontSize: "50px" }} />
          </div>
          <h1> Instagram </h1>
          <p> {t("instagram")} </p>
          <input
            value={instagramlink}
            onChange={(e) => setInstagramlink(e.target.value)}
            placeholder={"Instagram-Url"}
          />
          {instagramlink.includes("instagram") ? (
            <div
              onClick={handleInstagram}
              style={{ color: "#4380eb" }}
              className="alertbutton"
            >
              {t("update")}
            </div>
          ) : (
            <div style={{ color: "grey" }} className="alertbutton">
              <span style={{ opacity: ".5" }}>{t("update")}</span>
            </div>
          )}

          {myInstagram && (
            <div
              onClick={deleteInstagramlink}
              style={{ color: "rgb(222, 73, 85)" }}
              className="alertbutton"
            >
              {t("remove_big")}
            </div>
          )}
        </div>
        <div onClick={() => setOpeninsta(false)} className="alertbutton single">
          {t("cancel")}
        </div>
      </Drawer>

      <Drawer
        transitionDuration={300}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "20px 20px 0 0",
            background: "transparent",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="bottom"
        open={opensnap}
        onClose={() => setOpensnap(false)}
      >
        <div className="alert socials">
          <div className="alertavatar socials">
            <img
              alt="customSnapchat "
              src={Snapchatcustom}
              style={{ width: "50px", margin: "0 5px" }}
            />
          </div>
          <h1> Snapchat </h1>
          <p> {t("snapchat")} </p>
          <input
            value={snapchatlink}
            onChange={(e) => setSnapchatlink(e.target.value)}
            placeholder={"Snapchat-Url"}
          />
          {snapchatlink.includes("snapchat") ? (
            <div
              onClick={handleSnapchat}
              style={{ color: "#4380eb" }}
              className="alertbutton"
            >
              {t("update")}
            </div>
          ) : (
            <div style={{ color: "grey" }} className="alertbutton">
              <span style={{ opacity: ".5" }}>{t("update")}</span>
            </div>
          )}
          {mySnapchat && (
            <div
              onClick={deleteSnapchatlink}
              style={{ color: "rgb(222, 73, 85)" }}
              className="alertbutton"
            >
              {t("remove_big")}
            </div>
          )}
        </div>
        <div onClick={() => setOpensnap(false)} className="alertbutton single">
          {t("cancel")}
        </div>
      </Drawer>
      <span
        onClick={() => setSettingsPage(true)}
        className="profilesettingsbutton"
      >
        <MenuRoundedIcon style={{ fontSize: "25px" }} />
      </span>
      <div className="postwrapper profile">
        <div className="overflowtop"></div>
        <div
          style={{
            backgroundImage: `url(${userData && userData.imgRef})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            filter: "blur(4px)",
          }}
          className="postheader profile"
        ></div>
        <div className="overflow"></div>
        <div className="postbody">
          <div className="borderposts post">
            <label onClick={() => setOpenCamDrawer(true)}>
              <div className="fileupdate">
                <EditIcon style={{ color: "white", fontSize: "23px" }} />
              </div>
            </label>
            <div className="whitepost"></div>
            <div
              className="newprofileimage"
              style={{
                position: "relative",
              }}
            >
              {loader && (
                <CircularProgress
                  style={{ color: "white" }}
                  className="loader"
                />
              )}
              <AvatarComponentPost driverid={user && user.uid} />
            </div>
            <CamUpload
              user={user}
              firebase={firebase}
              targetHeight={800}
              targetWidth={800}
              setLoader={(val) => setLoader(val)}
              onClose={() => setOpenCamDrawer(false)}
              open={openCamDrawer}
              profileImage
            />
          </div>
          <div className="postbodyhead">
            <h1> {userData?.username} </h1>
            <h2>{user.email}</h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              {myInstagram ? (
                <InstagramIcon
                  onClick={() => setOpeninsta(true)}
                  style={{ color: "#FE4040", margin: "0 5px" }}
                />
              ) : (
                <InstagramIcon
                  onClick={() => setOpeninsta(true)}
                  style={{ color: "#8b8d92", margin: "0 5px" }}
                />
              )}
              {mySnapchat ? (
                <img
                  onClick={() => setOpensnap(true)}
                  alt="customSnapchat "
                  src={Snapchatcustom}
                  style={{ width: "1.5rem", margin: "0 5px" }}
                />
              ) : (
                <img
                  onClick={() => setOpensnap(true)}
                  alt="customSnapchat"
                  src={Snapchatcustomthree}
                  style={{ width: "1.5rem", margin: "0 5px" }}
                />
              )}
            </div>
          </div>
          <div className="postinfo">
            <h2>
              Name: <span> {userData?.username} </span>{" "}
            </h2>
          </div>
          <div className="postinfo relative">
            <h2>
              Email: <span> {user.email} </span>{" "}
            </h2>
          </div>
          {posts && (
            <div className="profileposts">
              <p className="profilepoststext"> {t("active_posts")}: </p>
              {driversdata &&
                driversdata.docs.map((driver, key) => (
                  <div
                    className="posts"
                    style={{ margin: "0 16px" }}
                    key={driver.id}
                  >
                    <div
                      className="drivelink"
                      onClick={() =>
                        setDriverModal({ id: driver.id, open: true })
                      }
                    >
                      <div className="drivepost profile">
                        <AvatarComponent driverid={driver.data().id} />{" "}
                        <p>
                          {" "}
                          {driver.data().driver &&
                          driver.data().driver.length > 11
                            ? `${driver.data().driver.substring(0, [10])}...`
                            : driver.data().driver}{" "}
                          <br /> <span> {driver.data().club} </span>{" "}
                        </p>{" "}
                        <p className="startsat">
                          {t("starts_at")}: <br />{" "}
                          <span> {driver.data().time} </span>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              <p style={{ color: "#7b7f85", textAlign: "center" }}>
                {t("no_active_posts")}
              </p>
            </div>
          )}
          <div className="profilelinks">
            {/* <div
              onClick={() => firebase.logout(user)}
              style={{
                display: "flex",
                alignItems: "center",
              }}
              className="postinfo relative"
            >
              <h2
                style={{
                  color: "#4380eb",
                  fontWeight: "400",
                  fontSize: "18px",
                }}
              >
                Logout
              </h2>
            </div> */}
            <div className="postinfo relative">
              <h2
                style={{
                  color: "#4380eb",
                  fontWeight: "400",
                  fontSize: "18px",
                }}
                onClick={() => setFavouritesDrawer(true)}
              >
                {t("my_favourites")}
              </h2>
            </div>
            <div className="postinfo relative">
              <h2
                style={{
                  color: "#4380eb",
                  textDecoration: "none",
                  fontWeight: "400",
                  fontSize: "18px",
                }}
                onClick={() => setPromotionModal(true)}
              >
                {t("create_promotion")}
              </h2>
            </div>
          </div>
        </div>
        <h1 className="credit">
          <span className="spanfrom">from</span> <br />{" "}
          <span className="firmname">EXUDE</span>
        </h1>
      </div>
    </>
  );
}
