import { ClickAwayListener } from "@material-ui/core";
import React, { useState } from "react";
import vibrate from "../util/feedback";

export default function Selector({ distance, onChange }) {
  const [open, setOpen] = useState(false);
  const formated = new String(distance).substring(0, 2);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className="selectwrapper">
        <div className="selecter" onClick={() => setOpen((prev) => !prev)}>
          <h2>{formated}km</h2>
        </div>
        <div className="selectlist" style={open ? {} : { display: "none" }}>
          <h2
            style={distance === 25000 ? { backgroundColor: "#383838" } : {}}
            onClick={() => {
              onChange(25000);
              setOpen(false);
              vibrate("light");
            }}
          >
            25km
          </h2>
          <h2
            style={distance === 50000 ? { backgroundColor: "#383838" } : {}}
            onClick={() => {
              onChange(50000);
              setOpen(false);
              vibrate("light");
            }}
          >
            50km
          </h2>
          <h2
            style={distance === 75000 ? { backgroundColor: "#383838" } : {}}
            onClick={() => {
              onChange(75000);
              setOpen(false);
              vibrate("light");
            }}
          >
            75km
          </h2>
        </div>
      </div>
    </ClickAwayListener>
  );
}
