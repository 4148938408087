import React, { useState } from "react";
import FirebaseContext from "../firebase/context";
import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useDocumentData } from "react-firebase-hooks/firestore";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function ChangeUsernamePage(props) {
  const { firebase, user } = React.useContext(FirebaseContext);
  const { t } = useTranslation();
  const [newName, setNewName] = useState("");
  const [uniqueusernameErr, setUniqueusernameErr] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [userData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );

  async function updateUserName() {
    setSubmitting(true);

    const uniqueusername = await firebase.db
      .collection("users")
      .where("usernamelowerCase", "==", newName.toLowerCase())
      .get();

    if (uniqueusername.docs.length) {
      setUniqueusernameErr(true);
      setSubmitting(false);
    } else if (newName.replace(/ /g, "") !== "") {
      await firebase.db.doc(`users/${user && user.uid}`).update({
        username: newName,
        usernamelowerCase: newName.toLowerCase(),
        changedUsername: true,
      });
      setNewName("");
      setUniqueusernameErr(false);
      setSubmitting(false);
      props.onClose();
    } else {
      console.log("invalid!");
      setSubmitting(false);
    }
  }

  return (
    <div className="changeusernamewrapper">
      <div className="businesshead">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            borderBottom: "1px solid rgb(48 47 47)",
          }}
        >
          <ArrowBackIosIcon
            onClick={props.onClose}
            style={{
              color: "#FD7F4D",
              position: "absolute",
              left: "26px",
              top: "50%",
              transform: "translate(0%, -50%)",
              padding: "10px",
              marginLeft: "-10px",
            }}
          />
          <h1> {t("username")} </h1>
          {userData?.changedUsername ? (
            <span
              style={{
                color: "#FD7F4D",
                position: "absolute",
                right: "26px",
                top: "50%",
                transform: "translate(0%, -50%)",
                padding: "10px",
                marginLeft: "-10px",
                fontWeight: "500",
                fontSize: "18px",
                transition: "all .5s",
                opacity: "50%",
              }}
            >
              {t("done")}
            </span>
          ) : (
            <span
              onClick={newName === "" ? null : updateUserName}
              style={
                newName.replace(/ /g, "") !== ""
                  ? {
                      color: "#FD7F4D",
                      position: "absolute",
                      right: "26px",
                      top: "50%",
                      transform: "translate(0%, -50%)",
                      padding: "10px",
                      marginLeft: "-10px",
                      fontWeight: "500",
                      fontSize: "18px",
                      transition: "all .5s",
                    }
                  : {
                      color: "#FD7F4D",
                      position: "absolute",
                      right: "26px",
                      top: "50%",
                      transform: "translate(0%, -50%)",
                      padding: "10px",
                      marginLeft: "-10px",
                      fontWeight: "500",
                      fontSize: "18px",
                      transition: "all .5s",
                      opacity: "50%",
                    }
              }
            >
              {t("done")}
            </span>
          )}
        </div>
      </div>
      <div className="changeusernamemain">
        <h2>{t("change_username")}</h2>
        <p>{t("change_username_text")}</p>
        <input
          placeholder={userData?.username}
          onChange={(e) => setNewName(e.target.value)}
        />
        {userData?.changedUsername && (
          <p style={{ color: "#fd7f4d" }}>{t("changed_username")}</p>
        )}
        {uniqueusernameErr &&
          (submitting ? (
            <p style={{ color: "#fd7f4d" }}>
              <CircularProgress
                style={{ color: "white", width: "25px", height: "25px" }}
              />
            </p>
          ) : (
            <p style={{ color: "#fd7f4d" }}>{t("unique_username")}</p>
          ))}
      </div>
    </div>
  );
}
