import React from "react";
import FirebaseContext from "../firebase/context";
import { useDocumentData } from "react-firebase-hooks/firestore";
import AvatarComponentSuperMini from "./AvatarComponentSuperMini";

export default function Guests({ uid }) {
  const { firebase, storage, user } = React.useContext(FirebaseContext);

  return (
    <div style={{ marginLeft: "-7px", zIndex: "3" }}>
      <AvatarComponentSuperMini driverid={uid} />
    </div>
  );
}
