import React, { useState } from "react";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
} from "react-google-maps";
import FirebaseContext from "../firebase/context";
import mapStyles from "./mapStyles";

function Maps(props) {
  const [zoom, setZoom] = React.useState(11);

  return (
    <GoogleMap
      defaultOptions={{
        disableDefaultUI: true,
        styles: mapStyles,
        gestureHandling: "greedy",
      }}
      zoom={zoom}
      defaultCenter={{ lat: props?.lat, lng: props?.lng }}
      mapTypeId={zoom < 16 ? "roadmap" : "satellite"}
    >
      <Marker
        onClick={() => {
          setZoom(zoom + 1);
        }}
        position={{
          lat: props?.lat,
          lng: props?.lng,
        }}
        icon={{
          url: props?.image || "./club.png",
          scaledSize: new window.google.maps.Size(45, 45),
        }}
      />
    </GoogleMap>
  );
}

const WrappedMap = withScriptjs(withGoogleMap((props) => Maps(props)));

export default function InnerMap({ lat, lng, image }) {
  const { firebase, user } = React.useContext(FirebaseContext);
  const [overlay, setOverlay] = useState(true);

  return (
    <div
      id="map1"
      className="promotionMap"
      onTouchStart={(e) => {
        e.stopPropagation();
      }}
      onTouchMove={(e) => {
        e.stopPropagation();
      }}
    >
      <WrappedMap
        lat={lat}
        lng={lng}
        image={image}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing&key=AIzaSyDTMBQt_sQuFpRMhtlzbSo1lGZdWJjOUMY`}
        loadingElement={<div style={{ height: "100%" }} />}
        containerElement={<div style={{ height: "100%" }} />}
        mapElement={<div style={{ height: "100%" }} />}
      />
    </div>
  );
}
