import React, { useContext, useState } from "react";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
} from "react-google-maps";
import FirebaseContext from "../firebase/context";
import mapStyles from "./mapStyles";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import { useHistory } from "react-router-dom";
import EventPost from "./EventPost";
import { SwipeableDrawer } from "@material-ui/core";
import { DEFAULT_LOCATION, LocationContext } from "../App";
import Logo from "../images/Hopon-Logo-Dark.png";
import Avatar from "./AvatarComponentHome";
import Userpage from "./Userpage";
import PartyPost from "./PartyPost";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import useCloseDrawers from "../hooks/useDrawer";
import vibrate from "../util/feedback";
//i18n
import { useTranslation } from "react-i18next";

function Maps() {
  const { firebase, user } = React.useContext(FirebaseContext);
  const history = useHistory();
  const [iconSize, setIconSize] = React.useState(45);
  const [zoom, setZoom] = React.useState(8);
  const [eventModal, setEventModal] = React.useState({});
  const { position, declined } = useContext(LocationContext);
  const [officials, setOfficials] = useState(true);
  const [friendPartypostDrawer, setFriendPartypostDrawer] = useState({});
  const { t } = useTranslation();

  const [userData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );

  const friendslist = (userData && userData.friends) || [];

  const date = React.useMemo(() => {
    const result = new Date();
    result.setDate(result.getDate() - 1);
    return result;
  }, []);

  const [officialpartyData] = useCollection(
    firebase.db.collection("b2bposts").where("date", ">", date)
  );

  const [partyData] = useCollection(
    firebase.db.collection("parties").where("date", ">", date)
  );

  const friendsparty =
    partyData &&
    partyData.docs.filter((party) => {
      if (!party.data().private && friendslist.includes(party.data().id)) {
        return party;
      }
    });

  const myownparty =
    partyData &&
    partyData.docs.filter((party) => {
      if (party.data().id === user?.uid) {
        return party;
      }
    });

  useCloseDrawers({
    drawers: [
      () => setEventModal((prev) => ({ ...prev, open: false })),
      () => setFriendPartypostDrawer((prev) => ({ ...prev, open: false })),
    ],
  });

  return (
    <>
      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 199 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={eventModal.open}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setEventModal((prev) => ({ ...prev, open: false }))}
      >
        <EventPost
          params={{ id: eventModal.id }}
          onClose={() => setEventModal((prev) => ({ ...prev, open: false }))}
        ></EventPost>
      </SwipeableDrawer>

      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 199 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={friendPartypostDrawer.open}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() =>
          setFriendPartypostDrawer((prev) => ({ ...prev, open: false }))
        }
      >
        <PartyPost
          params={{ id: friendPartypostDrawer.id }}
          onClose={() =>
            setFriendPartypostDrawer((prev) => ({ ...prev, open: false }))
          }
        ></PartyPost>
      </SwipeableDrawer>

      <div className="mapcontentbutton">
        <span
          style={
            officials
              ? {
                  background: "linear-gradient(90deg, #fe4040, #fe7640)",
                  color: "white",
                }
              : {}
          }
          onClick={() => {
            vibrate("light");
            setOfficials(true);
          }}
        >
          <LocalActivityIcon style={{ marginRight: "8px" }} /> {t("officials")}
        </span>
        <span
          style={
            officials
              ? {}
              : {
                  background: "linear-gradient(90deg, #fe4040, #fe7640)",
                  color: "white",
                }
          }
          onClick={() => {
            vibrate("light");
            setOfficials(false);
          }}
        >
          <AccountCircleIcon style={{ marginRight: "8px" }} /> {t("friends")}
        </span>
      </div>
      <GoogleMap
        defaultOptions={{
          disableDefaultUI: true,
          styles: mapStyles,
          gestureHandling: "greedy",
        }}
        zoom={position && !declined ? zoom : 6}
        defaultCenter={position || DEFAULT_LOCATION}
        mapTypeId={zoom < 16 ? "roadmap" : "satellite"}
      >
        {officials
          ? officialpartyData &&
            officialpartyData.docs.map((promotion) => (
              <Marker
                onClick={(e) => {
                  console.log(position);
                  e.domEvent.preventDefault();
                  e.domEvent.stopPropagation();
                  setEventModal({ id: promotion?.id, open: true });
                }}
                position={{
                  lat: promotion?.data().lat,
                  lng: promotion?.data().long,
                }}
                icon={{
                  url: promotion?.data().image || "./club.png",
                  scaledSize: new window.google.maps.Size(iconSize, iconSize),
                }}
              />
            ))
          : friendsparty.concat(myownparty)?.map((promotion) => (
              <Marker
                onClick={(e) => {
                  e.domEvent.preventDefault();
                  e.domEvent.stopPropagation();
                  setFriendPartypostDrawer({ id: promotion?.id, open: true });
                }}
                position={{
                  lat: promotion?.data().lat,
                  lng: promotion?.data().long,
                }}
                icon={{
                  url: promotion?.data().image || "./friends.png",
                  scaledSize: new window.google.maps.Size(iconSize, iconSize),
                }}
              />
            ))}
        {position && !declined && (
          <Marker
            onClick={() => setZoom(zoom + 2)}
            position={position}
            icon={{
              url: "./location.svg",
              scaledSize: new window.google.maps.Size(iconSize, iconSize),
            }}
          />
        )}
      </GoogleMap>
    </>
  );
}

const WrappedMap = withScriptjs(withGoogleMap(Maps));

export default function Map() {
  const { firebase, user } = React.useContext(FirebaseContext);
  const [userData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );
  const history = useHistory();
  const [userPage, setUserPage] = useState({});

  useCloseDrawers({
    drawers: [() => setUserPage((prev) => ({ ...prev, open: false }))],
  });

  return (
    <>
      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 199 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={userPage.open}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setUserPage((prev) => ({ ...prev, open: false }))}
      >
        <Userpage
          params={{ UserId: userPage.id }}
          onClose={() => setUserPage((prev) => ({ ...prev, open: false }))}
        ></Userpage>
      </SwipeableDrawer>
      <div
        className="homenavpopup"
        style={{
          position: "fixed",
          background:
            "linear-gradient(180deg, black, black, black, transparent)",
          height: "100px",
          zIndex: "10",
          top: "-13px",
          paddingBottom: "15px",
        }}
      >
        <span>
          <img src={Logo} alt="logo" /> Hopon
        </span>
        <div
          // onClick={() => history.push("/Profile")}
          onClick={() => {
            vibrate("light");
            setUserPage({ id: user?.uid, open: true });
          }}
          className="homeheadprofileimage"
        >
          {userData ? (
            <Avatar driverid={user.uid} />
          ) : (
            <div
              style={{
                height: "35px",
                width: "35px",
                background: "grey",
                borderRadius: "100%",
              }}
            ></div>
          )}
        </div>
      </div>
      <div className="mapholder">
        <WrappedMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing&key=AIzaSyDTMBQt_sQuFpRMhtlzbSo1lGZdWJjOUMY`}
          loadingElement={<div style={{ height: "100%" }} />}
          containerElement={<div style={{ height: "100%" }} />}
          mapElement={<div style={{ height: "100%" }} />}
        />
      </div>
    </>
  );
}
