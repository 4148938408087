import React from "react";
import { FirebaseContext } from "../firebase";
import { NavLink } from "react-router-dom";
import Logo from "../images/logo.png";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function Forgot() {
  const { firebase } = React.useContext(FirebaseContext);
  const [resetPasswordEmail, setResetPasswordEmail] = React.useState("");
  const [isPasswordReset, setIsPasswordReset] = React.useState(false);
  const [passwordResetError, setPasswordResetError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  async function handleResetPassword(e) {
    e.preventDefault();
    setLoading(true);
    try {
      await firebase.resetPassword(resetPasswordEmail);
      setLoading(false);
      setIsPasswordReset(true);
      setPasswordResetError(null);
    } catch (err) {
      console.error("Error sending email", err);
      setLoading(false);
      setPasswordResetError("No User found. You may have misspelled something");
      setIsPasswordReset(false);
    }
  }

  return (
    <div className="forgotwrapper">
      <div className="forgot">
        <img src={Logo} alt="logo" style={{ borderRadius: "22px" }} />
        <h2>Forgot Password?</h2>
        <form onSubmit={handleResetPassword}>
          <input
            type="email"
            placeholder="E-mail"
            onChange={(event) => setResetPasswordEmail(event.target.value)}
          />
          <p
            style={
              passwordResetError ? { margin: "10px 20px" } : { margin: "0" }
            }
            className={passwordResetError ? "error-text" : "error-text not"}
          >
            {" "}
            {passwordResetError}{" "}
          </p>
          <p
            style={
              isPasswordReset
                ? { margin: "10px 20px", color: "lightgreen" }
                : { margin: "0" }
            }
            className={isPasswordReset ? "error-text" : "error-text not"}
          >
            Hooray, check your E-mail to reset your password
          </p>
          <button className="forgotbutton" onClick={handleResetPassword}>
            {loading ? (
              <CircularProgress
                style={{
                  color: "white",
                  width: "25px",
                  height: "25px",
                  position: "absolute",
                }}
              />
            ) : (
              "Reset Password"
            )}
          </button>
        </form>
        <NavLink className="backtologin" to="/">
          Back to Login
        </NavLink>
      </div>
    </div>
  );
}
