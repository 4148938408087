import React from "react";
import { useDocumentData, useCollection } from "react-firebase-hooks/firestore";
import FirebaseContext from "../firebase/context";
import NameGetter from "./NameGetter";
//Moment
import moment from "moment";
//i18n
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Favourite({ uid, onClick }) {
  const { t } = useTranslation();
  const { firebase } = React.useContext(FirebaseContext);
  const [eventData] = useDocumentData(firebase.db.doc(`b2bposts/${uid}`));

  if (!eventData) return null;

  return (
    <div
      onClick={onClick}
      className="singleFavourite"
      style={{
        background: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(${eventData?.image})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <h2>
        {eventData?.clubname && eventData?.clubname.length > 15
          ? `${eventData?.clubname.substring(0, [14])}...`
          : eventData?.clubname}
      </h2>
    </div>
  );
}
