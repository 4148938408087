import React, { useEffect, useRef, useState } from "react";
//FIREBASE
import FirebaseContext from "../firebase/context";
//MUI-ICONS
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import Message from "./Message";
import AvatarComponentMini from "./AvatarComponentMini";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import { useTranslation } from "react-i18next";
import { useDocumentData } from "react-firebase-hooks/firestore";
import styled from "styled-components";

const InputBarContainer = styled.form({
  position: "relative",
  minHeight: 50,
  background: "#000",
  borderTop: "1px solid #181818",
  zIndex: 200,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "10px",
  width: "95%",
  // marginBottom: "calc(env(safe-area-inset-bottom) + 10px)",
  // paddingBottom: "20px",

  "& .formspan": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(90deg, #fe4040, #fe7640)",
    color: "white",
    transition: "all 0.5s",
    borderRadius: "100%",
    padding: "8px",
    marginLeft: "10px",

    ":hover": {
      cursor: "pointer",
    },
  },

  "& input": {
    width: "calc(100% - 80px)",
    padding: "10px 15px",
    borderRadius: "35px",
    border: "1px solid grey",
    backgroundColor: "black",
    fontSize: "15px",
    color: "white",
    paddingLeft: "20px",
    ":placeholder": {
      fontSize: "15px",
    },
  },
});

const InputBar = ({ handleOnSubmit, newMessage, setNewMessage, userId }) => {
  const { t } = useTranslation();
  return (
    <InputBarContainer onSubmit={handleOnSubmit}>
      <span style={{ marginRight: "10px" }}>
        <AvatarComponentMini size="36px" driverid={userId} />
      </span>
      <input
        onChange={(e) => setNewMessage(e.target.value)}
        value={newMessage}
        placeholder={t("message_placeholder")}
      />
      <span
        onClick={handleOnSubmit}
        className="formspan"
        style={
          newMessage.replace(/ /g, "") !== ""
            ? { opacity: "1" }
            : { opacity: "0.5" }
        }
      >
        <SendRoundedIcon
          style={{
            fontSize: "24px",
          }}
        />
      </span>
    </InputBarContainer>
  );
};

export default function Chatroom({
  onClose,
  params,
  guests,
  adminId,
  subject,
  date,
}) {
  const { firebase, user } = React.useContext(FirebaseContext);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [data, setData] = React.useState({});
  const anchor = useRef();
  const admin = [`${adminId}`];
  const finalGuests = admin.concat(guests);

  const [userData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );

  const inputValues = finalGuests.filter(
    (i) => i !== firebase.auth.currentUser.uid
  );

  useEffect(() => {
    if (user.uid) {
      firebase.db
        .collection("users")
        .doc(user.uid)
        .set({ chatsVisited: { [params]: new Date() } }, { merge: true });
    }
  }, [firebase.db, user.uid, messages]);

  useEffect(() => {
    if (user.uid) {
      const logoRef = firebase.db.collection("users").doc(user.uid);
      return logoRef.onSnapshot((snapshot) => {
        if (snapshot.exists) {
          setData(snapshot.data());
        }
      });
    }
  }, [user.uid, firebase.db]);

  useEffect(() => {
    if (firebase.db) {
      const unsubscribe = firebase.db
        .collection(`drivers/${params}/messages`)
        .orderBy("createdAt")
        .onSnapshot((querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          //UPDATE MESSAGES
          setMessages(data);
        });
      return unsubscribe;
    }
  }, [firebase.db, params]);

  function makeId(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  function updateChatNotificationsInFirebase(idsArray, newChatObject) {
    const batch = firebase.db.batch();

    idsArray.forEach((id) => {
      const userRef = firebase.db.collection("users").doc(id);
      batch.update(userRef, {
        chatMessageNotifications: firebase.FieldValue.arrayUnion(newChatObject),
      });
    });

    batch.commit();
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (firebase.db && newMessage.replace(/ /g, "") !== "") {
      firebase.db.collection(`drivers/${params}/messages`).add({
        text: newMessage,
        createdAt: new Date(),
        uid: user.uid,
        displayName: userData?.username,
        photoURL: data.imgRef || null,
      });

      if (inputValues?.[0] !== undefined) {
        updateChatNotificationsInFirebase(inputValues, {
          messageID: makeId(15),
          postId: params,
          postDate: date,
          messageDate: new Date(),
          id: firebase.auth.currentUser.uid,
          text: newMessage,
          subject: subject,
          guests: guests,
          adminId: adminId,
        });
      }
    } else {
      console.log("invalid!");
    }
    setNewMessage("");
  };

  return (
    <div className="chatroomwrapper">
      <div className="chatroomheader">
        <span className="messagebackicon" onClick={onClose}>
          <ArrowBackIosRoundedIcon
            style={{ color: "white", marginLeft: "16px", fontSize: "25px" }}
          />
        </span>
        <p> Chat </p>
      </div>
      <div className="chatroommain">
        <ul>
          {messages.map((message) => (
            <li
              ref={anchor}
              style={
                firebase.auth.currentUser.uid === message.uid
                  ? { justifyContent: "flex-end" }
                  : { justifyContent: "flex-start" }
              }
              key={message.id}
            >
              <div className="singlemessagewrapper">
                <Message {...message} />
              </div>
            </li>
          ))}
          {/* <div ref={anchor}></div> */}
        </ul>
      </div>

      <InputBar
        userId={user?.uid}
        handleOnSubmit={handleOnSubmit}
        newMessage={newMessage}
        setNewMessage={setNewMessage}
      />
    </div>
  );
}
