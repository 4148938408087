import React, { useCallback, useState } from "react";
import useFormValidation from "./useFormValidation";
import validateLogin from "./validateLogin";
import { Link, NavLink } from "react-router-dom";
import FirebaseContext from "../firebase/context";
import Logo from "../images/logoofficial.png";
//Icons
import { FaApple, FaGoogle } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { useCollection } from "react-firebase-hooks/firestore";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SwipeableDrawer } from "@material-ui/core";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import useCloseDrawers from "../hooks/useDrawer";
import Darklogo from "../images/Hopon-Logo-Dark.png";
import { useTranslation } from "react-i18next";
//PLUGINS
import vibrate from "../util/feedback";

const INITIAL_STATE = {
  name: "",
  email: "",
  password: "",
};

function Login(props) {
  const { firebase, storage, user } = React.useContext(FirebaseContext);
  const [login, setLogin] = React.useState(true);
  const [issubmitting, setIssubmitting] = React.useState(false);
  const { handleSubmit, handleBlur, handleChange, values, setValues, errors } =
    useFormValidation(INITIAL_STATE, validateLogin(login), authenticateUser);
  const [firebaseError, setFirebaseError] = React.useState(null);
  const [loginMethod, setLoginMethod] = React.useState(false);
  const { i18n } = useTranslation();

  const [userData] = useCollection(firebase.db.collection(`users`));

  const [covid, setCovid] = React.useState("none");
  const [covidError, setCovidError] = React.useState(false);
  const [nameErr, setNameErr] = React.useState(false);
  const [uniqueusernameErr, setUniqueusernameErr] = React.useState(false);
  const [signuperr, setSignuperr] = React.useState("");
  const [emailsingup, setEmailsingup] = React.useState(false);

  async function authenticateUser() {
    const { name, email, password, image, ...rest } = values;
    try {
      if (login) {
        setIssubmitting(true);
        await firebase.loginWithEmail(email, password);
        props.history.push("/Home");
      } else if (name === "") {
        setNameErr(true);
      } else {
        await firebase.validateUsername(name);
        setIssubmitting(true);
        const uid = await firebase.register(name, email, password, rest);
        firebase.db
          .collection("users")
          .doc(uid)
          .set({
            username: name,
            usernamelowerCase: name.toLowerCase(),
            createdAt: new Date(),
            email: email,
          })
          .then(() => {
            setCovidError(false);
            setNameErr(false);
          });
        props.history.push("/Home");
      }
    } catch (err) {
      console.error("Authentication Error", err);

      switch (err.message) {
        case "username already exists":
          setUniqueusernameErr(true);
          setNameErr(false);
          break;
        case "username is empty":
          setNameErr(true);
          break;
        default:
          break;
      }

      setSignuperr(err.message);
      setFirebaseError("Incorrect account or password!");
    }
    setIssubmitting(false);
  }

  const handleGoogleLogin = useCallback(async () => {
    try {
      const { user } = await firebase.loginWithGoogle(i18n.language);
      await firebase.handleOauthLoginRegister(user);
      props.history.push("/home");
    } catch (error) {
      firebase.handleLoginError(error);
    }
  }, [firebase, i18n.language, props.history]);

  // const handleAppleLogin = useCallback(async () => {
  //   try {
  //     const { user } = await firebase.loginWithApple(i18n.language);
  //     await firebase.handleOauthLoginRegister(user);
  //     props.history.push("/home");
  //   } catch (error) {
  //     firebase.handleLoginError(error);
  //   }
  // }, [firebase, i18n.language, props.history]);

  function handleAppleLogin() {
    const provider = new firebase.auth.OAuthProvider("apple.com");
    return new Promise((resolve, reject) =>
      window.cordova.plugins.SignInWithApple.signin(
        {
          requestedScopes: [0, 1],
        },
        resolve,
        reject
      )
    )
      .then(
        (res) =>
          console.log({
            res,
          }) ||
          firebase
            .auth()
            .signInWithCredential(provider.credential(res.identityToken))
            .then(
              (credential) =>
                console.log({
                  credential,
                }) ||
                firebase
                  .firestore()
                  .collection("users")
                  .doc(credential.user.uid)
                  .set(
                    {
                      email: credential.user.email,
                      apple: true,

                      username:
                        res.fullName?.givenName || res.fullName?.nickname || "",
                      usernamelowerCase:
                        res.fullName?.givenName.toLowerCase() ||
                        res.fullName?.nickname.toLowerCase() ||
                        "",
                      createdAt: new Date(),
                    },
                    {
                      merge: true,
                    }
                  )
            )
      )
      .catch((err) => {
        console.log(err);
        console.log("APPLE SIGNIN ERROR", JSON.stringify(err));
      });
  }

  useCloseDrawers({
    drawers: [() => setEmailsingup(false), () => setLoginMethod(false)],
  });

  return (
    <div className="startingscreen">
      <SwipeableDrawer
        transitionDuration={300}
        PaperProps={{
          elevation: 0,
          style: {
            zIndex: 1,
            background: "transparent",
            width: "100%",
            borderRadius: "0px",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={emailsingup}
        onClose={() => setEmailsingup(false)}
      >
        <div className="fullwrapper">
          <div onClick={() => setEmailsingup(false)}>
            <ArrowBackRoundedIcon
              style={{ fontSize: "25px" }}
              className="postheaderbackicon"
            />
          </div>
          <div className="wrapper">
            <div className="block1">
              <div className="loginlogo">
                {" "}
                <img
                  style={{ borderRadius: "22px" }}
                  src={Logo}
                  alt="Logo"
                />{" "}
              </div>
              <form onSubmit={handleSubmit} className="formfield">
                <input
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  type="text"
                  placeholder={login ? "" : "Name"}
                  className={
                    login ? "logininput username" : "logininput username active"
                  }
                />
                {!login && (
                  <>
                    <p className={nameErr ? "error-text" : "error-text not"}>
                      Username required
                    </p>
                    <p
                      className={
                        uniqueusernameErr ? "error-text" : "error-text not"
                      }
                    >
                      Username already taken
                    </p>{" "}
                  </>
                )}
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  name="email"
                  type="email"
                  placeholder="E-mail"
                  className="logininput"
                />
                <p className={errors.email ? "error-text" : "error-text not"}>
                  {errors.email}
                </p>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  name="password"
                  type="password"
                  placeholder={!login ? "Set Password" : "Password"}
                  className="logininput"
                />
                <p
                  className={errors.password ? "error-text" : "error-text not"}
                >
                  {errors.password}
                </p>
                {login ? (
                  <>
                    {
                      <p
                        style={{ maxWidth: "80%" }}
                        className={
                          firebaseError ? "error-text" : "error-text not"
                        }
                      >
                        {firebaseError}
                      </p>
                    }
                  </>
                ) : (
                  <>
                    <p
                      style={{ textAlign: "center" }}
                      className={signuperr ? "error-text" : "error-text not"}
                    >
                      {signuperr}
                    </p>
                  </>
                )}
                <Link
                  to="/forgot"
                  className={login ? "forgotlabel" : "forgotlabel inactive"}
                >
                  Forgot Password ?
                </Link>
                <div className="login-buttons">
                  <button
                    type="submit"
                    className="loginbtn"
                    disabled={issubmitting}
                    style={{
                      background: issubmitting && "#bf4949",
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "18px",
                    }}
                  >
                    {issubmitting ? (
                      <CircularProgress
                        style={{
                          color: "white",
                          width: "25px",
                          height: "25px",
                          position: "absolute",
                        }}
                      />
                    ) : login ? (
                      "Login"
                    ) : (
                      "Sign Up"
                    )}
                  </button>
                  <button
                    type="button"
                    className="signinbtn"
                    style={{
                      background: "transparent",
                      color: "white",
                      textDecoration: "none",
                    }}
                    onClick={() => setLogin((prevLogin) => !prevLogin)}
                  >
                    {login ? "Sign Up" : "Log In"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </SwipeableDrawer>
      <SwipeableDrawer
        transitionDuration={300}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "10px 10px 0 0",
            background: "transparent",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="bottom"
        open={loginMethod}
        onClose={() => setLoginMethod(false)}
      >
        <div className="loginoptions">
          <span className="swipeline"></span>
          {/* <div
            onClick={handleGoogleLogin}
            className="singleloginoption"
            style={{ background: "#4285f5", color: "white" }}
          >
            <FaGoogle
              style={{
                color: "white",
                marginRight: "10px",
                fontSize: "15px",
              }}
            />{" "}
            Continue with Google
          </div> */}
          {/* <div onClick={handleAppleLogin} className="singleloginoption">
            <FaApple
              style={{
                color: "black",
                marginRight: "10px",
                fontSize: "18px",
              }}
            />{" "}
            Continue with Apple
          </div> */}
          <div
            className="singleloginoption"
            style={{ background: "#d64025", color: "white" }}
            onClick={() => setEmailsingup(true)}
          >
            <MdMail
              style={{
                color: "white",
                marginRight: "10px",
                fontSize: "18px",
              }}
            />{" "}
            Continue with email address
          </div>
        </div>
      </SwipeableDrawer>
      <div className="innerbox">
        <img src={Darklogo} />
        <h2>Hopon brings people together no matter what the event.</h2>
        <button
          type="button"
          className="signinbtn"
          onClick={() => {
            vibrate("light");
            setLoginMethod(true);
          }}
        >
          Get Started
        </button>
      </div>
    </div>
  );
}

export default Login;
