/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
import FirebaseContext from "../firebase/context";
//Icons
import DriveEtaRoundedIcon from "@material-ui/icons/DriveEtaRounded";
import EmojiPeopleRoundedIcon from "@material-ui/icons/EmojiPeopleRounded";
import CakeRoundedIcon from "@material-ui/icons/CakeRounded";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import SearchIcon from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import LockIcon from "@material-ui/icons/Lock";
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import ClearRoundedIcon from "@material-ui/icons/CloseRounded";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { IoNotificationsOutline } from "react-icons/io5";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import Friend from "./Friend";
import Skeleton from "@material-ui/lab/Skeleton";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import Logo from "../images/Hopon-Logo-Dark.png";
//Moment
import moment from "moment";
//Material UI
import AvatarComponent from "./AvatarComponent";
import AvatarComponentPost from "./AvatarComponentPost";
import SwipeableViews from "react-swipeable-views";
//Geolib
import { getDistance } from "geolib";
//i18n
import { useTranslation } from "react-i18next";
import i18next from "i18next";
//Progress
import CircularProgress from "@material-ui/core/CircularProgress";
//Autocomplete
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import DriverPost from "./DriverPost";
import { SwipeableDrawer } from "@material-ui/core";
import Userpage from "./Userpage";
import FriendSearchPage from "./SearchFriend";
import PartyPost from "./PartyPost";
import CreatePartyPage from "./MakeParty";
import AvatarComponentMini from "./AvatarComponent";
import CreateSecreteParty from "./MakeSecretParty";
import EventPost from "./EventPost";
import { LocationContext } from "../App";
import Switcher from "./Switcher";
import Avatar from "./AvatarComponentHome";
import useCloseDrawers from "../hooks/useDrawer";
//Flags
import Germanflag from "../images/germany.png";
import Usaflag from "../images/usa.png";
import uploadFile from "../util/uploadFile";
//PLUGINS
import vibrate from "../util/feedback";
import NotificationPage from "./NotificationPage";
import PrivateEventDisplay from "./PrivateEventDisplay";
import CamUpload from "../util/CamUpload";
import BugInfo from "./BugInfo";
import PromotionSkeleton from "./PromotionSkeleton";
import Selector from "./Selector";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  root: {
    borderColor: "white",
  },
});

const languages = [
  {
    code: "de",
    name: "German",
  },
  {
    code: "en",
    name: "English",
  },
];

export default function Home(props) {
  const { t, i18n } = useTranslation();
  const { firebase, user } = React.useContext(FirebaseContext);
  const [friendDrivepostDrawer, setFriendDrivepostDrawer] = useState({});
  const [friendPartypostDrawer, setFriendPartypostDrawer] = useState({});
  const [officialEventDrawer, setOfficialEventDrawer] = useState({});
  const [userPage, setUserPage] = useState({});
  const [friendDrawer, setFriendDrawer] = useState();
  const [makePartyPage, setMakePartyPage] = useState();
  const [makeSecretPartyPage, setMakeSecretPartyPage] = useState();
  const { position } = useContext(LocationContext);
  const [scrollStyle, setScrollStyle] = useState("top");
  const [openCamDrawer, setOpenCamDrawer] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isScrollingDown = currPos.y < prevPos.y;
      const isTop = currPos.y >= -100;
      setScrollStyle(isTop ? "top" : isScrollingDown ? "hide" : "show");
    },
    [scrollStyle],
    false,
    false,
    100
  );

  const [userData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );
  const [create, setCreate] = useState(false);

  //Creating Constants
  const [heading, setHeading] = useState("");
  const [starting, setStarting] = useState("");
  const [club, setClub] = useState("");
  const [time, setTime] = useState("12:00");
  const [car, setCar] = useState("");
  const [fit, setFit] = useState(1);
  const [privateDrive, setPrivateDrive] = useState(false);
  const [data, setData] = React.useState({});
  const [bottomone, setBottomone] = useState(false);
  const [bottomtwo, setBottomtwo] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [timeone, setTimeone] = useState("12");
  const [timetwo, setTimetwo] = useState("00");
  const [success, setSuccess] = useState(false);
  const [notificationSite, setNotificationSite] = useState(false);
  const [clickanimation, setClickanimation] = useState(false);
  const [headingClickanimation, setHeadingClickanimation] = useState(false);
  const [createstate, setCreatestate] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [tutorial, setTutorial] = useState(false);
  const [tutorialIndex, setTutorialIndex] = useState(0);
  const [distance, setDistance] = useState(50000);
  const [tagvalue, setTagvalue] = useState();
  const lang = i18n.language;

  function changeLanguage(code) {
    i18next.changeLanguage(code);
    firebase.db.doc(`users/${user && user.uid}`).update({ language: code });
  }

  const [loader, setLoader] = useState(false);

  async function updateUserImage(file) {
    try {
      const { success, imgRef, error } = await uploadFile(file, user);
      if (!success) throw new Error(error);
      await firebase.db.collection("users").doc(user.uid).update({ imgRef });
    } catch (err) {
      console.debug("DEBUG catch error", { err });
    }
    setLoader(false);
  }

  useEffect(() => {
    setTimeout(() => {
      if (!userData?.tutorialDone) {
        setTutorial(true);
      } else {
        setTutorial(false);
      }
    }, 2000);
  }, [userData]);

  function completeTutorial() {
    setTutorial(false);
    setTimeout(() => {
      firebase.db
        .doc(`users/${user && user.uid}`)
        .update({ tutorialDone: true });
    }, 600);
  }

  //Navigationprops
  const stateprop = props.location.state;
  const indexprop = stateprop && stateprop.indexprop;
  const [tabindex, setTabindex] = useState(indexprop || 2);
  const [activeTab, setActiveTab] = useState("event");

  function handleDrive() {
    vibrate("light");
    setActiveTab("drive");
    setTimeout(() => {
      setTabindex(0);
    }, 200);
  }

  function handleFriends() {
    vibrate("light");
    setActiveTab("friend");
    setTimeout(() => {
      setTabindex(1);
    }, 200);
  }

  function handleEvent() {
    vibrate("light");
    setActiveTab("event");
    setTimeout(() => {
      setTabindex(2);
    }, 200);
  }

  function confirmTime() {
    vibrate("light");
    setBottomone((prevState) => !prevState);
    const finalTime = timeone + ":" + timetwo;
    setTime(finalTime);
  }

  function confirmSeats() {
    vibrate("light");
    setBottomtwo((prevState) => !prevState);
  }

  const [coordinates, setCoordinates] = React.useState({
    lat: null,
    lng: null,
  });

  const [headingCoordinates, setHeadingCoordinates] = React.useState({
    lat: null,
    lng: null,
  });

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setStarting(value);
    setCoordinates(latLng);
    setClickanimation(false);
  };

  const handleSelectHeading = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setHeading(value);
    setHeadingCoordinates(latLng);
    setHeadingClickanimation(false);
  };

  function handleSubmit() {
    setSubmitting(true);
    firebase.db
      .collection(`drivers`)
      .add({
        heading: heading,
        starting: starting,
        club: club,
        time: time,
        driver: userData?.username,
        car: car,
        fit: fit,
        profilepicture: data.imgRef || null,
        date: new Date(),
        id: user.uid,
        lat: coordinates.lat,
        long: coordinates.lng,
        private: privateDrive,
      })
      .then(() => {
        setSuccess(true);
        setSubmitting(false);
        setCreatestate(false);
        setTimeout(() => {
          vibrate("heavy");
        }, 700);
        setTimeout(() => {
          vibrate("heavy");
        }, 1200);
        setTimeout(() => {
          vibrate("heavy");
        }, 1300);
      })
      .then(() => {
        setAnimation(false);
        setPrivateDrive(false);
      });
  }

  //Get date
  const date = React.useMemo(() => {
    const result = new Date();
    result.setDate(result.getDate() - 1);
    return result;
  }, []);

  const [driversdata] = useCollection(
    firebase.db
      .collection(`drivers`)
      .orderBy("date", "desc")
      .where("date", ">", date)
  );

  const [mydriversdata] = useCollection(
    user?.uid
      ? firebase.db
          .collection(`drivers`)
          .where("id", "==", user.uid)
          .where("date", ">", date)
      : null
  );

  const [partyData] = useCollection(
    firebase.db.collection("parties").where("date", ">", date)
  );

  const [officialpartyData] = useCollection(
    firebase.db.collection("b2bposts").where("date", ">", date)
  );

  const filteredDriversdata = useMemo(
    () =>
      driversdata &&
      driversdata.docs
        .map((driver) => {
          if (
            !driver.data().lat ||
            !driver.data().long ||
            !position.lat ||
            !position.lng
          )
            return driver.data();
          var dis = getDistance(
            { latitude: position.lat, longitude: position.lng },
            { latitude: driver.data().lat, longitude: driver.data().long }
          );
          return { ...driver.data(), dis, driverDocId: driver.id };
        })
        .filter((driver) => driver.dis < 50000),
    [position.lat, position.lng, driversdata]
  );

  const filteredOfficialEventsData = useMemo(
    () =>
      officialpartyData?.docs
        ?.map((driver) => {
          if (
            !driver?.data()?.lat ||
            !driver?.data()?.long ||
            !position?.lat ||
            !position?.lng
          )
            return driver?.data();
          var dis = getDistance(
            { latitude: position?.lat, longitude: position?.lng },
            { latitude: driver?.data()?.lat, longitude: driver?.data()?.long }
          );
          return { ...driver.data(), dis, eventDocId: driver.id };
        })
        .filter((driver) => driver.dis < distance),
    [position, officialpartyData, distance]
  );

  //Gets Data
  const [friendSearch, setFriendSearch] = React.useState("");

  //Gets Friend-Request Data
  const [seatData] = useCollection(
    firebase.db.collection(`drivers`).where("id", "==", user?.uid || "")
  );

  //Gets Guest-Request Data
  const [guestData] = useCollection(
    firebase.db.collection(`parties`).where("id", "==", user?.uid || "")
  );

  const [showSuggestions, setShowSuggestions] = useState(true);
  const [userdata] = useCollection(firebase.db.collection("users"));
  const friendrequests = (userData && userData.friendRequests) || [];
  const friendRequestsAccepted =
    (userData && userData.friendRequestsAccepted) || [];

  const [friendsList, setFriendsList] = useState([]);

  // populate list of friends with username for search
  useEffect(() => {
    const initial = (userData && userData.friends) || [];

    async function populateFriendsList() {
      const _friendsList = [];
      for await (const id of initial) {
        const doc = await firebase.db.collection("users").doc(id).get();
        _friendsList.push({ id, username: doc?.data()?.username });
      }
      setFriendsList(_friendsList);
    }

    populateFriendsList();
  }, [firebase.db, userData]);

  const seatrequests =
    seatData &&
    seatData.docs.reduce(
      (acc, val) => acc + (val.data().seatRequests || []).length,
      0
    );

  const guestrequests =
    guestData &&
    guestData.docs.reduce(
      (acc, val) => acc + (val.data().guestRequests || []).length,
      0
    );

  const partyinvitedata =
    partyData &&
    partyData.docs.filter((party) => {
      if (
        party.data().guests &&
        party.data().guests.includes(firebase.auth.currentUser.uid)
      ) {
        return party;
      }
    }).length;
  const kickslength = userData && userData.kicked && userData.kicked.length;
  const kickedPartyslength =
    userData && userData.kickedParty && userData.kickedParty.length;
  const declineslength =
    userData && userData.declines && userData.declines.length;
  const acceptedlength =
    userData && userData.accepted && userData.accepted.length;

  const declinedRequestslength =
    userData && userData.declinedRequests && userData.declinedRequests.length;

  const declinedEventRequestslength =
    userData &&
    userData.declinedEventRequests &&
    userData.declinedEventRequests.length;

  const chatNotificationData = userData?.chatMessageNotifications?.filter(
    (notification) => {
      if (
        notification &&
        new Date() - notification?.postDate.toDate() < 1000 * 60 * 60 * 24
      ) {
        return notification;
      }
    }
  );
  const chatNotificationLength = chatNotificationData?.length || 0;

  const friendRides =
    driversdata &&
    driversdata.docs.filter((driver) => {
      if (friendsList.some(({ id }) => id === driver.data().id)) {
        return user;
      }
    });

  const [size, setSize] = useState(5);
  const [indexdelay, setIndexdelay] = useState(0);

  const friendsmap = friendsList
    .slice(0, size)
    .filter(({ username }) =>
      username?.toLowerCase().includes(friendSearch?.toLowerCase())
    )
    .map((friend, index) => {
      return (
        <Friend
          key={`${friend.id}${index}`}
          uid={friend.id}
          index={index}
          indexdelay={indexdelay}
          onClick={() => {
            vibrate("light");
            setUserPage({ id: friend.id, open: true });
          }}
        />
      );
    });

  function showMore() {
    setSize(size + 3);
    setIndexdelay(size);
  }

  function showless() {
    setSize(5);
  }

  function handleSearch(e) {
    setSize(friendsList.length);
    const val = e.target.value;
    setFriendSearch(val);
  }

  const friendsparty =
    partyData &&
    partyData.docs.filter((party) => {
      if (
        !party.data().private &&
        friendsList.some(({ id }) => id === party.data().id)
      ) {
        return party;
      }
    });

  const partyInvites =
    partyData &&
    partyData.docs.filter((party) => {
      if (party.data().guests.includes(firebase.auth.currentUser.uid)) {
        return party;
      }
    });

  const adminparty =
    partyData &&
    partyData.docs.filter((party) => {
      if (party.data().id === firebase.auth.currentUser.uid) {
        return party;
      }
    });

  function scrollFixer() {
    document.scrollingElement.scrollTo(0, 0);
  }

  function Video({ url, active }) {
    const ref = useRef();
    useEffect(() => {
      if (ref.current) {
        if (active) {
          ref.current.play();
        } else {
          ref.current.currentTime = 0;
        }
      }
    }, [active]);
    return (
      <video loop muted playsInline ref={ref}>
        <source src={url} type="video/mp4"></source>
      </video>
    );
  }

  useCloseDrawers({
    drawers: [
      () => setFriendDrawer(false),
      () => setMakePartyPage(false),
      () => setMakeSecretPartyPage(false),
      () => setUserPage((prev) => ({ ...prev, open: false })),
      () => setFriendDrivepostDrawer((prev) => ({ ...prev, open: false })),
      () => setFriendPartypostDrawer((prev) => ({ ...prev, open: false })),
      () => setOfficialEventDrawer((prev) => ({ ...prev, open: false })),
    ],
  });

  if (!user?.uid) return null;

  return (
    <div className="completeHomeWrapper">
      {!userData?.tutorialDone ? (
        <Drawer
          anchor="bottom"
          open={tutorial}
          onClose={() => setTutorial(false)}
          transitionDuration={600}
          style={{ zIndex: 200 }}
          PaperProps={{
            elevation: 0,
            style: {
              borderRadius: "0",
              background: "transparent",
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              marginTop: "env(safe-area-inset-top)",
            },
          }}
          disableSwipeToOpen
        >
          <SwipeableViews
            index={tutorialIndex}
            onChangeIndex={(index) => setTutorialIndex(index)}
            style={{
              alignItems: "center",
              width: "100%",
            }}
          >
            <div className="tutorialdiv first">
              <h1>
                You're <br /> Almost <br /> there
              </h1>
              <div className="tutorialbuttondiv">
                <button onClick={() => setTutorialIndex(1)}>Next</button>
              </div>
            </div>
            <div className="tutorialdiv">
              <div className="tutorialhead">
                <h2> {t("select_language")} </h2>
              </div>
              <div className="tutorialmain">
                <div
                  onClick={() => changeLanguage("de")}
                  className="singletutoriallanguage"
                  style={lang === "de" ? { border: "2px solid orange" } : {}}
                >
                  German <img src={Germanflag} />
                </div>
                <div
                  onClick={() => changeLanguage("en")}
                  className="singletutoriallanguage"
                  style={lang === "en" ? { border: "2px solid orange" } : {}}
                >
                  English <img src={Usaflag} />
                </div>
              </div>
              <div className="tutorialbuttondiv">
                <button onClick={() => setTutorialIndex(2)}> Next </button>
                {/* <button onClick={() => setTutorialIndex(2)}> Skip </button> */}
              </div>
            </div>
            <div className="tutorialdiv">
              <div className="tutorialhead">
                <h2> {t("your_profile")} </h2>
              </div>
              <div className="tutorialmain">
                <CamUpload
                  user={user}
                  firebase={firebase}
                  targetHeight={800}
                  targetWidth={800}
                  setLoader={(val) => setLoader(val)}
                  onClose={() => setOpenCamDrawer(false)}
                  open={openCamDrawer}
                  profileImage
                />
                <label
                  onClick={() => setOpenCamDrawer(true)}
                  className="tutorialprofile"
                >
                  <span className="tutorialprofileoverlay"></span>
                  {loader ? (
                    <CircularProgress
                      color="secondary"
                      style={{
                        position: "absolute",
                        zIndex: "10",
                        color: "white",
                      }}
                    />
                  ) : (
                    <CameraAltOutlinedIcon
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: "10",
                        fontSize: "30px",
                        color: "white",
                      }}
                    />
                  )}
                  <AvatarComponentPost driverid={user && user.uid} />
                </label>
                <p> {t("profileimage_text")} </p>
              </div>
              <div className="tutorialbuttondiv">
                <button onClick={completeTutorial}> Get Started </button>
              </div>
            </div>
          </SwipeableViews>
        </Drawer>
      ) : (
        ""
      )}
      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 200 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={friendDrawer}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setFriendDrawer(false)}
      >
        <FriendSearchPage onClose={() => setFriendDrawer(false)} />
      </SwipeableDrawer>
      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 199 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={userPage.open}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setUserPage((prev) => ({ ...prev, open: false }))}
      >
        <Userpage
          params={{ UserId: userPage.id }}
          onClose={() => setUserPage((prev) => ({ ...prev, open: false }))}
        ></Userpage>
      </SwipeableDrawer>

      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 200 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={notificationSite}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setNotificationSite(false)}
      >
        <NotificationPage
          params={{ UserId: userPage.id }}
          onClose={() => setNotificationSite(false)}
        ></NotificationPage>
      </SwipeableDrawer>

      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 199 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={friendDrivepostDrawer.open}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() =>
          setFriendDrivepostDrawer((prev) => ({ ...prev, open: false }))
        }
      >
        <DriverPost
          params={{ id: friendDrivepostDrawer.id }}
          onClose={() =>
            setFriendDrivepostDrawer((prev) => ({ ...prev, open: false }))
          }
        ></DriverPost>
      </SwipeableDrawer>

      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 199 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={friendPartypostDrawer.open}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() =>
          setFriendPartypostDrawer((prev) => ({ ...prev, open: false }))
        }
      >
        <PartyPost
          params={{ id: friendPartypostDrawer.id }}
          onClose={() =>
            setFriendPartypostDrawer((prev) => ({ ...prev, open: false }))
          }
        ></PartyPost>
      </SwipeableDrawer>

      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 199 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={officialEventDrawer.open}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() =>
          setOfficialEventDrawer((prev) => ({ ...prev, open: false }))
        }
      >
        <EventPost
          params={{ id: officialEventDrawer.id }}
          onClose={() =>
            setOfficialEventDrawer((prev) => ({ ...prev, open: false }))
          }
        ></EventPost>
      </SwipeableDrawer>

      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 200 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={makePartyPage}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setMakePartyPage(false)}
      >
        <CreatePartyPage
          onClose={() => setMakePartyPage(false)}
        ></CreatePartyPage>
      </SwipeableDrawer>

      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 200 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={makeSecretPartyPage}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setMakeSecretPartyPage(false)}
      >
        <CreateSecreteParty
          onClose={() => setMakeSecretPartyPage(false)}
        ></CreateSecreteParty>
      </SwipeableDrawer>

      <Drawer
        open={createstate}
        onClose={() => {
          setCreatestate(false);
          setAnimation(false);
        }}
        PaperProps={{
          elevation: 0,
          style: {
            height: "100vh",
          },
        }}
        anchor="bottom"
        transitionDuration={350}
      >
        <div
          style={{
            height: "100vh",
            background: "black",
          }}
        >
          <div
            id="createtab"
            className={createstate ? "createAdrivePost" : "deActive"}
          >
            <div
              className={createstate ? "createInner" : "createInnerdeActive"}
            >
              <div className="addheader">
                <div className="headtitleparty">
                  <div
                    onClick={() => {
                      setCreatestate(false);
                      setAnimation(false);
                      setClickanimation(false);
                      setHeadingClickanimation(false);
                    }}
                    className="backlink"
                  >
                    <ArrowBackIosRoundedIcon style={{ color: "#FD7F4D" }} />
                  </div>
                  <h2> {t("create_post")} </h2>
                  {heading === "" ? (
                    <button
                      disabled
                      style={{ color: "rgb(123, 127, 133)" }}
                      className="poster buttonanimation"
                    >
                      {t("create")}
                    </button>
                  ) : starting === "" ? (
                    <button
                      disabled
                      style={{ color: "rgb(123, 127, 133)" }}
                      className="poster buttonanimation"
                    >
                      {t("create")}
                    </button>
                  ) : club === "" ? (
                    <button
                      disabled
                      style={{ color: "rgb(123, 127, 133)" }}
                      className="poster buttonanimation"
                    >
                      {t("create")}
                    </button>
                  ) : time === "" ? (
                    <button
                      disabled
                      style={{ color: "rgb(123, 127, 133)" }}
                      className="poster buttonanimation"
                    >
                      {t("create")}
                    </button>
                  ) : car === "" ? (
                    <button
                      disabled
                      style={{ color: "rgb(123, 127, 133)" }}
                      className="poster buttonanimation"
                    >
                      {t("create")}
                    </button>
                  ) : fit === "" ? (
                    <button
                      disabled
                      style={{ color: "rgb(123, 127, 133)" }}
                      className="poster buttonanimation"
                    >
                      {t("create")}
                    </button>
                  ) : submitting ? (
                    <button disabled className="poster buttonanimation">
                      <CircularProgress
                        style={{
                          color: "white",
                          width: "25px",
                          height: "25px",
                        }}
                      />
                    </button>
                  ) : (
                    <button
                      onClick={handleSubmit}
                      className="poster buttonanimation"
                    >
                      {t("create")}
                    </button>
                  )}
                </div>
              </div>
              <div className="addholder">
                <h2 className={animation ? "scaleanimation" : ""}>
                  {t("heading")}
                </h2>
                <div
                  style={
                    clickanimation
                      ? {
                          height: "100vh",
                          width: "100vw",
                          position: "absolute",
                          left: "0",
                          top: "58px",
                          zIndex: "10",
                          transition: ".8s",
                          opacity: "1",
                          background: "black",
                          userSelect: "initial",
                        }
                      : headingClickanimation
                      ? {
                          height: "100vh",
                          width: "100vw",
                          position: "absolute",
                          left: "0",
                          top: "58px",
                          zIndex: "10",
                          transition: ".8s",
                          opacity: "1",
                          background: "black",
                          userSelect: "initial",
                        }
                      : {
                          height: "100vh",
                          width: "100vw",
                          position: "absolute",
                          left: "0",
                          top: "57px",
                          zIndex: "-1",
                          transition: ".8s",
                          opacity: "0",
                          background: "black",
                          userSelect: "none",
                        }
                  }
                >
                  <span
                    style={{
                      position: "absolute",
                      top: "26px",
                      right: "18px",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        position: "relative",
                        height: "35px",
                        width: "39px",
                      }}
                      onClick={() => {
                        if (clickanimation) {
                          setClickanimation(false);
                        } else {
                          setHeadingClickanimation(false);
                        }
                      }}
                    >
                      <ClearRoundedIcon style={{ fontSize: "35px" }} />
                    </span>
                  </span>
                </div>
                <PlacesAutocomplete
                  value={starting}
                  onChange={(e) => {
                    setClickanimation(true);
                    setStarting(e);
                  }}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div
                      style={
                        clickanimation
                          ? {
                              width: "calc(100% - 30px)",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              zIndex: "11",
                              marginTop:
                                "calc(-50px - env(safe-area-inset-top))",
                              transition: ".5s",
                            }
                          : {
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              transition: ".5s",
                            }
                      }
                    >
                      <input
                        className={animation ? "scaleanimationinput" : ""}
                        style={
                          clickanimation
                            ? {
                                width: "calc(100% - 30px)",
                                marginLeft: "-15px",
                              }
                            : { animationDelay: ".1s" }
                        }
                        {...getInputProps({
                          placeholder: t("from") + " " + t("munich"),
                        })}
                        onClick={() => setClickanimation(true)}
                      />
                      <div
                        className="suggestions driver"
                        style={
                          starting === ""
                            ? { display: "none" }
                            : {
                                display: "flex",
                                flexDirection: "column",
                                width: "calc(100% + 40px)",
                                marginLeft: "40px",
                              }
                        }
                      >
                        {clickanimation && (
                          <>
                            {loading ? (
                              <>
                                <BugInfo />
                                <div className="suggestionloader">
                                  <CircularProgress
                                    style={{ color: "white" }}
                                  />
                                </div>
                              </>
                            ) : null}
                          </>
                        )}
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#efefef"
                              : "#ffff",
                            cursor: suggestion.active ? "pointer" : "initial",
                          };
                          return (
                            <div
                              key={suggestion.description}
                              {...getSuggestionItemProps(suggestion, { style })}
                              style={{ display: "flex", alignItems: "center" }}
                              className="suggestionsingle"
                            >
                              {/* <LocationOnIcon
                                style={{
                                  fontSize: "25px",
                                  marginRight: "5px",
                                  marginLeft: "10px",
                                  color: "#a8a8a8",
                                }}
                              />{" "} */}
                              <p className="suggestiontext">
                                {suggestion.description}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>

                {/* <input
                  className={animation ? "scaleanimationinput" : ""}
                  style={{ animationDelay: ".2s" }}
                  onChange={(event) => {
                    setHeading(event.target.value);
                  }}
                  placeholder={t("destination")}
                /> */}

                <PlacesAutocomplete
                  value={heading}
                  onChange={(e) => {
                    setHeadingClickanimation(true);
                    setHeading(e);
                  }}
                  onSelect={handleSelectHeading}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div
                      style={
                        headingClickanimation
                          ? {
                              width: "calc(100% - 30px)",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              zIndex: "11",
                              marginTop:
                                "calc(-110px - env(safe-area-inset-top))",
                              transition: ".5s",
                            }
                          : {
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              transition: ".5s",
                            }
                      }
                    >
                      <input
                        className={animation ? "scaleanimationinput" : ""}
                        style={
                          headingClickanimation
                            ? {
                                width: "calc(100% - 30px)",
                                marginLeft: "-15px",
                              }
                            : { animationDelay: ".2s" }
                        }
                        {...getInputProps({
                          placeholder: t("destination"),
                        })}
                        onClick={() => setHeadingClickanimation(true)}
                      />
                      <div
                        className="suggestions driver"
                        style={
                          heading === ""
                            ? { display: "none" }
                            : {
                                display: "flex",
                                flexDirection: "column",
                                width: "calc(100% + 40px)",
                                marginLeft: "40px",
                              }
                        }
                      >
                        {headingClickanimation && (
                          <>
                            {loading ? (
                              <>
                                <BugInfo />
                                <div className="suggestionloader">
                                  <CircularProgress
                                    style={{ color: "white" }}
                                  />
                                </div>
                              </>
                            ) : null}
                          </>
                        )}
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#efefef"
                              : "#ffff",
                            cursor: suggestion.active ? "pointer" : "initial",
                          };
                          return (
                            <div
                              key={suggestion.description}
                              {...getSuggestionItemProps(suggestion, { style })}
                              style={{ display: "flex", alignItems: "center" }}
                              className="suggestionsingle"
                            >
                              <p className="suggestiontext">
                                {suggestion.description}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>

                <div className="divider"></div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                    fontSize: "16px",
                    margin: "10px 0",
                    marginLeft: "15px",
                    color: "#FD7F4D",
                  }}
                >
                  <p
                    onClick={() => setBottomone((prevState) => !prevState)}
                    style={{
                      margin: "10px 0",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {t("starting_time")}
                  </p>
                  <Drawer
                    PaperProps={{
                      elevation: 0,
                      style: {
                        borderRadius: "0",
                        backgroundColor: "black",
                      },
                    }}
                    anchor={"bottom"}
                    onClose={() => setBottomone((prevState) => !prevState)}
                    open={bottomone}
                  >
                    <div className="createDrawer">
                      <ClearRoundedIcon
                        onClick={() => {
                          setBottomone((prevState) => !prevState);
                        }}
                        style={{ fontSize: "40px", color: "#FD7F4D" }}
                      />
                      <h2 style={{ marginTop: "20px" }}>
                        {t("when_start_driving")}
                      </h2>
                      <div className="createDrawerinputs">
                        <input
                          onChange={(e) => {
                            const val = e.target.value;
                            setTimeone(val);
                          }}
                          placeholder="12"
                          value={timeone}
                          inputMode="numeric"
                          maxlength="2"
                        />
                        <h2>:</h2>
                        <input
                          onChange={(e) => {
                            const val = e.target.value;
                            setTimetwo(val);
                          }}
                          placeholder="00"
                          value={timetwo}
                          inputMode="numeric"
                          maxlength="2"
                        />
                      </div>
                      <div
                        onClick={
                          timeone === ""
                            ? null
                            : timetwo === ""
                            ? null
                            : confirmTime
                        }
                        style={
                          timeone === ""
                            ? { background: "grey" }
                            : timetwo === ""
                            ? { background: "grey" }
                            : {}
                        }
                        className="confirmbutton"
                      >
                        {t("confirm")}
                      </div>
                    </div>
                  </Drawer>
                  <p
                    onClick={() => {
                      setBottomtwo((prevState) => !prevState);
                    }}
                    style={{
                      margin: "10px 0",
                      marginLeft: "100px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {fit} {t("seat")}{" "}
                    <ArrowUpwardRoundedIcon
                      style={{ fontSize: "20px", marginLeft: "5px" }}
                    />{" "}
                    <ArrowDownwardRoundedIcon
                      style={{ fontSize: "20px", margin: "0px" }}
                    />
                  </p>
                  <Drawer
                    PaperProps={{
                      elevation: 0,
                      style: {
                        borderRadius: "0",
                        backgroundColor: "black",
                      },
                    }}
                    anchor={"bottom"}
                    onClose={() => bottomtwo((prevState) => !prevState)}
                    open={bottomtwo}
                  >
                    <div className="createDrawer">
                      <ClearRoundedIcon
                        onClick={() => setBottomtwo((prevState) => !prevState)}
                        style={{ fontSize: "40px", color: "#FD7F4D" }}
                      />
                      <h2 style={{ marginTop: "20px" }}>
                        {t("seats_available")}
                      </h2>
                      <div
                        className="createDrawerinputs"
                        style={{ justifyContent: "space-around" }}
                      >
                        {fit === 1 ? (
                          <span
                            style={{ color: "grey", border: "2px solid grey" }}
                          >
                            <ArrowDownwardRoundedIcon
                              style={{ fontSize: "20px", margin: "0px" }}
                            />
                          </span>
                        ) : (
                          <span
                            onClick={() => {
                              vibrate("light");
                              setFit(fit - 1);
                            }}
                          >
                            <ArrowDownwardRoundedIcon
                              style={{ fontSize: "20px", margin: "0px" }}
                            />
                          </span>
                        )}
                        <h2
                          className="fitcount"
                          style={
                            fit > 999 ? { maxWidth: "none", width: "auto" } : {}
                          }
                        >
                          {fit}
                        </h2>
                        <span
                          onClick={() => {
                            vibrate("light");
                            setFit(fit + 1);
                          }}
                        >
                          <ArrowUpwardRoundedIcon
                            style={{ fontSize: "20px", margin: "0px" }}
                          />
                        </span>
                      </div>
                      <div onClick={confirmSeats} className="confirmbutton">
                        {t("confirm")}
                      </div>
                    </div>
                  </Drawer>
                </div>
                <div className="divider"></div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                    fontSize: "16px",
                    margin: "10px 0",
                    color: "#FD7F4D",
                  }}
                >
                  <input
                    className={animation ? "scaleanimationinputsmall" : ""}
                    onChange={(event) => {
                      setClub(event.target.value);
                    }}
                    style={{ margin: "0 5px", animationDelay: ".2s" }}
                    placeholder="Event"
                  />
                  <input
                    className={animation ? "scaleanimationinputsmall" : ""}
                    onChange={(event) => {
                      setCar(event.target.value);
                    }}
                    style={{ margin: "0 5px", animationDelay: ".3s" }}
                    placeholder={t("car")}
                  />
                </div>
                <div
                  className={animation ? "privatepartysection" : ""}
                  style={{
                    animationDelay: ".6s",
                    width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h3>{t("private_drive_text")}</h3>
                  <Switcher
                    onClick={() => {
                      vibrate("light");
                      setPrivateDrive((prevState) => !prevState);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer
        open={success}
        anchor="bottom"
        transitionDuration={550}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "black",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
      >
        <div className="successcontainer">
          <div className="successmain drivepost">
            <h1>
              {/* <span className="secondspan"> {t("success")} </span> <br /> */}
              <span className="spantitle driveposttitle toptwo">
                Ready to drive
              </span>
              <span className="spantitle driveposttitle topone">
                Ready to drive
              </span>
              <span className="spantitle driveposttitlenormal">
                Ready to drive
              </span>
              <span className="spantitle driveposttitle bottomone">
                Ready to drive
              </span>
              <span className="spantitle driveposttitle bottomtwo">
                Ready to drive
              </span>
            </h1>
            {/* <span className="tag">@{userData?.username} </span> */}
            <div className="successbottom">
              <span></span>
              <div
                style={{
                  border: "1px solid white",
                  zIndex: "10",
                  borderRadius: "100%",
                  padding: "1px",
                  background: "white",
                }}
              >
                <AvatarComponentMini driverid={user.uid} />
              </div>
            </div>
            <div
              onClick={() => {
                setSuccess(false);
              }}
              className="successbutton"
            >
              {t("done")}
            </div>
          </div>
        </div>
      </Drawer>

      {/* WELCOME TEXT */}

      <div className="homewrapper">
        <div
          className="homenavpopup"
          scrollStyle={scrollStyle}
          style={
            scrollStyle === "top"
              ? {
                  position: "fixed",
                  background:
                    "linear-gradient(180deg, black, black, black, transparent)",
                  height: "60px",
                  zIndex: "10",
                  top: "-65px",
                  paddingBottom: "15px",
                }
              : scrollStyle === "show"
              ? {
                  position: "fixed",
                  background:
                    "linear-gradient(180deg, black, black, black, transparent)",
                  height: "60px",
                  zIndex: "10",
                  top: "-1px",
                  paddingBottom: "15px",
                }
              : {
                  position: "fixed",
                  background:
                    "linear-gradient(180deg, black, black, black, transparent)",
                  height: "60px",
                  zIndex: "10",
                  top: "-1px",
                  paddingBottom: "15px",
                }
          }
        >
          <span onClick={scrollFixer}>
            <img src={Logo} alt="logo" /> Hopon
          </span>
          <div
            className="homeheadRight"
            style={{ marginTop: "0", marginRight: "0" }}
          >
            <div
              className="unreadbadge"
              onClick={() => setNotificationSite(true)}
            >
              <IoNotificationsOutline
                style={{
                  color: "white",
                  fontSize: "30px",
                  marginRight: "20px",
                }}
              />
              <span
                className="badgespan"
                style={{
                  opacity:
                    (seatrequests || 0) +
                      (kickslength || 0) +
                      (acceptedlength || 0) +
                      (declinedRequestslength || 0) +
                      (friendrequests.length || 0) +
                      (friendRequestsAccepted.length || 0) +
                      (partyinvitedata || 0) +
                      (declineslength || 0) +
                      (kickedPartyslength || 0) +
                      (guestrequests || 0) +
                      chatNotificationLength +
                      (declinedEventRequestslength || 0) >
                    0
                      ? 1
                      : 0,
                }}
              >
                {(seatrequests || 0) +
                  (kickslength || 0) +
                  (acceptedlength || 0) +
                  (declinedRequestslength || 0) +
                  (friendrequests.length || 0) +
                  (friendRequestsAccepted.length || 0) +
                  (partyinvitedata || 0) +
                  (declineslength || 0) +
                  (kickedPartyslength || 0) +
                  (guestrequests || 0) +
                  chatNotificationLength +
                  (declinedEventRequestslength || 0) >
                9
                  ? "+9"
                  : (seatrequests || 0) +
                    (kickslength || 0) +
                    (acceptedlength || 0) +
                    (declinedRequestslength || 0) +
                    (friendrequests.length || 0) +
                    (friendRequestsAccepted.length || 0) +
                    (partyinvitedata || 0) +
                    (declineslength || 0) +
                    (kickedPartyslength || 0) +
                    (guestrequests || 0) +
                    chatNotificationLength +
                    (declinedEventRequestslength || 0)}
              </span>
            </div>
            <div
              onClick={() => {
                vibrate("light");
                setUserPage({ id: user?.uid, open: true });
              }}
              className="homeheadprofileimage"
            >
              {userData ? (
                <Avatar driverid={user.uid} />
              ) : (
                <div
                  style={{
                    height: "35px",
                    width: "35px",
                    background: "grey",
                    borderRadius: "100%",
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
        <div className="homehead">
          <span onClick={scrollFixer}>
            <img src={Logo} alt="logo" /> Hopon
          </span>
          <div className="homeheadRight">
            <div
              className="unreadbadge"
              onClick={() => setNotificationSite(true)}
            >
              <IoNotificationsOutline
                style={{
                  color: "white",
                  fontSize: "30px",
                  marginRight: "20px",
                }}
              />
              {(seatrequests || 0) +
                (kickslength || 0) +
                (acceptedlength || 0) +
                (declinedRequestslength || 0) +
                (friendrequests.length || 0) +
                (friendRequestsAccepted.length || 0) +
                (partyinvitedata || 0) +
                (declineslength || 0) +
                (kickedPartyslength || 0) +
                (guestrequests || 0) +
                chatNotificationLength +
                (declinedEventRequestslength || 0) >
              0 ? (
                <span className="badgespan">
                  {(seatrequests || 0) +
                    (kickslength || 0) +
                    (acceptedlength || 0) +
                    (declinedRequestslength || 0) +
                    (friendrequests.length || 0) +
                    (friendRequestsAccepted.length || 0) +
                    (partyinvitedata || 0) +
                    (declineslength || 0) +
                    (kickedPartyslength || 0) +
                    (guestrequests || 0) +
                    chatNotificationLength +
                    (declinedEventRequestslength || 0) >
                  9
                    ? "+9"
                    : (seatrequests || 0) +
                      (kickslength || 0) +
                      (acceptedlength || 0) +
                      (declinedRequestslength || 0) +
                      (friendrequests.length || 0) +
                      (friendRequestsAccepted.length || 0) +
                      (partyinvitedata || 0) +
                      (declineslength || 0) +
                      (kickedPartyslength || 0) +
                      (guestrequests || 0) +
                      chatNotificationLength +
                      (declinedEventRequestslength || 0)}
                </span>
              ) : (
                <></>
              )}
            </div>
            <div
              onClick={() => {
                vibrate("light");
                setUserPage({ id: user?.uid, open: true });
              }}
              className="homeheadprofileimage"
            >
              {userData ? (
                <Avatar driverid={user.uid} />
              ) : (
                <div
                  style={{
                    height: "35px",
                    width: "35px",
                    background: "grey",
                    borderRadius: "100%",
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
        <div className="homemain">
          <div className="homeactionwrapper">
            <div className="homeaction">
              <div className="homebutton">
                {activeTab === "event" ? (
                  <div className="borderposts">
                    <div className="white"></div>
                    <button
                      style={{ backgroundColor: "#4a172f" }}
                      onClick={handleEvent}
                      className="cake"
                    >
                      {" "}
                      <CakeRoundedIcon />{" "}
                    </button>
                  </div>
                ) : (
                  <div className="borderposts" style={{ background: "black" }}>
                    <div className="white"></div>
                    <button
                      style={{ backgroundColor: "#4a172f" }}
                      onClick={handleEvent}
                      className="cake"
                    >
                      {" "}
                      <CakeRoundedIcon />{" "}
                    </button>
                  </div>
                )}
                <p
                  style={
                    tabindex === 2 ? { color: "white" } : { color: "#7f7e7e" }
                  }
                >
                  Events
                </p>
              </div>

              <div className="homebutton">
                {activeTab === "friend" ? (
                  <div className="borderposts">
                    <div className="white"></div>
                    <button
                      style={{ backgroundColor: "#173244" }}
                      onClick={handleFriends}
                      className="friends"
                    >
                      <EmojiPeopleRoundedIcon />
                    </button>
                  </div>
                ) : (
                  <div className="borderposts" style={{ background: "black" }}>
                    <div className="white"></div>
                    <button
                      style={{ backgroundColor: "#173244" }}
                      onClick={handleFriends}
                      className="friends"
                    >
                      {" "}
                      <EmojiPeopleRoundedIcon />{" "}
                    </button>
                  </div>
                )}
                <p
                  style={
                    tabindex === 1 ? { color: "white" } : { color: "#7f7e7e" }
                  }
                >
                  {t("friend_tab")}
                </p>
              </div>

              <div className="homebutton">
                {activeTab === "drive" ? (
                  <div className="borderposts">
                    <div className="white"></div>
                    <button
                      style={{ backgroundColor: "#113a14" }}
                      onClick={handleDrive}
                      className="driver"
                    >
                      {" "}
                      <DriveEtaRoundedIcon />{" "}
                    </button>
                  </div>
                ) : (
                  <div className="borderposts" style={{ background: "black" }}>
                    <div className="white"></div>
                    <button
                      style={{ backgroundColor: "#113a14" }}
                      onClick={handleDrive}
                      className="driver"
                    >
                      {" "}
                      <DriveEtaRoundedIcon />{" "}
                    </button>
                  </div>
                )}
                <p
                  style={
                    tabindex === 0 ? { color: "white" } : { color: "#7f7e7e" }
                  }
                >
                  {t("driver_tab")}
                </p>
              </div>
            </div>
          </div>

          {tabindex === 0 && (
            <div
              className="spacer"
              style={{ opacity: activeTab === "drive" ? 1 : 0 }}
            >
              <div style={{ paddingBottom: "15px" }} className="DriverTab">
                <h1> {t("driving_tab_title")} </h1>
                <div className="drivebuttondiv">
                  {create ? (
                    <div
                      onClick={
                        heading === ""
                          ? null
                          : starting === ""
                          ? null
                          : club === ""
                          ? null
                          : time === ""
                          ? null
                          : car === ""
                          ? null
                          : fit === ""
                          ? null
                          : handleSubmit
                      }
                      style={
                        heading === ""
                          ? { background: "grey" }
                          : starting === ""
                          ? { background: "grey" }
                          : club === ""
                          ? { background: "grey" }
                          : time === ""
                          ? { background: "grey" }
                          : car === ""
                          ? { background: "grey" }
                          : fit === ""
                          ? { background: "grey" }
                          : {}
                      }
                      className={
                        create ? "activedriver create" : "activedriver"
                      }
                      id="wiggleButton"
                    >
                      {t("become_the_driver")}
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        scrollFixer();
                        setCreatestate(true);
                        setAnimation(true);
                        vibrate("light");
                      }}
                      className={
                        create ? "activedriver create" : "activedriver"
                      }
                    >
                      {t("become_the_driver")}
                    </div>
                  )}
                </div>
                <div className="drivefriends">
                  <h2> {t("friends_driving")} </h2>
                  {mydriversdata &&
                    mydriversdata.docs.map((driver, key) => (
                      <div
                        className="drivepost"
                        style={{ position: "relative", marginTop: "30px" }}
                        onClick={() =>
                          setFriendDrivepostDrawer({
                            id: driver.id,
                            open: true,
                          })
                        }
                      >
                        <p
                          className="your"
                          style={{ left: "0", color: "white" }}
                        >
                          {t("your_ride")}
                        </p>
                        <AvatarComponent driverid={driver.data().id} />{" "}
                        <p>
                          {" "}
                          {driver.data().driver &&
                          driver.data().driver.length > 11
                            ? `${driver.data().driver.substring(0, [10])}...`
                            : driver.data().driver}
                          <br />{" "}
                          <span>
                            {driver.data().club &&
                            driver.data().club.length > 11
                              ? `${driver.data().club.substring(0, [10])}...`
                              : driver.data().club}
                          </span>{" "}
                        </p>{" "}
                        <p>
                          {t("starts_at")}: <br />{" "}
                          <span>
                            {" "}
                            {driver.data().time} <br />{" "}
                            {moment(driver.data().date.toDate())
                              .startOf("seconds")
                              .fromNow()}{" "}
                          </span>{" "}
                        </p>
                      </div>
                    ))}
                  {friendRides ? (
                    <div className="posts">
                      {friendRides &&
                        (friendRides.length ? (
                          friendRides.map((driver, index) => (
                            <div
                              className="drivepost"
                              onClick={() =>
                                setFriendDrivepostDrawer({
                                  id: driver.id,
                                  open: true,
                                })
                              }
                            >
                              <AvatarComponent driverid={driver.data().id} />{" "}
                              <p>
                                {" "}
                                {driver.data().driver &&
                                driver.data().driver.length > 11
                                  ? `${driver
                                      .data()
                                      .driver.substring(0, [10])}...`
                                  : driver.data().driver}
                                <br />{" "}
                                <span>
                                  {driver.data().club &&
                                  driver.data().club.length > 11
                                    ? `${driver
                                        .data()
                                        .club.substring(0, [10])}...`
                                    : driver.data().club}
                                </span>{" "}
                              </p>{" "}
                              <p>
                                {t("starts_at")}: <br />{" "}
                                <span>
                                  {" "}
                                  {driver.data().time} <br />{" "}
                                  {moment(driver.data().date.toDate())
                                    .startOf("seconds")
                                    .fromNow()}{" "}
                                </span>{" "}
                              </p>
                            </div>
                          ))
                        ) : (
                          <p className="no_event">{t("no_friends_driving")}</p>
                        ))}
                    </div>
                  ) : (
                    <div className="posts">
                      <div className="drivepost">
                        <Skeleton
                          variant="circle"
                          width="3.5rem"
                          height="3.5rem"
                        />{" "}
                        <p>
                          {" "}
                          <Skeleton
                            variant="text"
                            style={{ marginLeft: "-3rem" }}
                            width="10rem"
                          />{" "}
                          <br /> <Skeleton variant="text" width="100%" />{" "}
                        </p>
                      </div>
                      <div className="drivepost">
                        <Skeleton
                          variant="circle"
                          width="3.5rem"
                          height="3.5rem"
                        />{" "}
                        <p>
                          {" "}
                          <Skeleton
                            variant="text"
                            style={{ marginLeft: "-3rem" }}
                            width="10rem"
                          />{" "}
                          <br /> <Skeleton variant="text" width="100%" />{" "}
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="drivefriends">
                  <h2>{t("in_your_area")}</h2>
                  {filteredDriversdata ? (
                    filteredDriversdata.length ? (
                      filteredDriversdata
                        ?.filter((drive) => drive.private === false)
                        .map((driver, key) => (
                          <div key={driver.driverDocId} className="posts">
                            <div
                              onClick={() =>
                                setFriendDrivepostDrawer({
                                  id: driver.driverDocId,
                                  open: true,
                                })
                              }
                              className="drivepost"
                            >
                              <AvatarComponent driverid={driver.id} />{" "}
                              <p>
                                {" "}
                                {driver.driver && driver.driver.length > 11
                                  ? `${driver.driver.substring(0, [10])}...`
                                  : driver.driver}
                                <br />{" "}
                                <span>
                                  {" "}
                                  {driver.club.length > 16
                                    ? `${driver.club.substring(0, [16])}...`
                                    : driver.club}
                                  <br /> {Math.round(driver.dis / 1000)}
                                  km {t("away")}{" "}
                                </span>{" "}
                              </p>{" "}
                              <p>
                                {t("starts_at")}: <br />{" "}
                                <span>
                                  {" "}
                                  {driver.time} <br />{" "}
                                  {moment(driver.date.toDate())
                                    .startOf("seconds")
                                    .fromNow()}{" "}
                                </span>{" "}
                              </p>
                            </div>
                          </div>
                        ))
                    ) : (
                      <p className="no_event">{t("no_people_driving")}</p>
                    )
                  ) : (
                    <div className="drivepost">
                      <Skeleton
                        variant="circle"
                        width="3.5rem"
                        height="3.5rem"
                      />{" "}
                      <p>
                        {" "}
                        <Skeleton
                          variant="text"
                          style={{ marginLeft: "-3rem" }}
                          width="10rem"
                        />{" "}
                        <br /> <Skeleton variant="text" width="100%" />{" "}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {tabindex === 1 && (
            <div
              className="spacer"
              style={{ opacity: activeTab === "friend" ? 1 : 0 }}
            >
              <div className="FriendsTab">
                <div className="Friendhead">
                  <h1> {t("friends_title")} </h1>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PersonAddIcon
                      style={{
                        color: "#fd7f4d",
                        fontSize: "30px",
                      }}
                      onClick={() => setFriendDrawer(true)}
                    />
                  </div>
                </div>
                <div className="inputfriendfirst friends">
                  <input
                    style={{ marginBottom: "1rem" }}
                    className="searchbar friend"
                    onChange={handleSearch}
                    placeholder={t("search_friend_placeholder")}
                    value={friendSearch}
                  />
                  {!friendSearch ? (
                    <SearchIcon
                      style={{ paddingRight: "17px", top: "calc(35% - 12px)" }}
                    />
                  ) : (
                    <HighlightOffIcon
                      style={{ paddingRight: "17px", top: "calc(35% - 12px)" }}
                      onClick={() => setFriendSearch("")}
                    />
                  )}
                </div>
                {driversdata ? (
                  friendsmap?.length === 0 ? (
                    <div
                      className="selectnow"
                      style={{ marginTop: "30px", position: "relative" }}
                    >
                      <div className="likediv">
                        <SearchIcon className="addNewFriendsIcon" />
                      </div>
                      <h2>{t("no_friends_yet")}</h2>
                      <p>{t("add_new_friends")}</p>
                    </div>
                  ) : (
                    friendsmap
                  )
                ) : (
                  <div className="posts">
                    <div className="drivepost">
                      <Skeleton
                        variant="circle"
                        width="3.5rem"
                        height="3.5rem"
                      />{" "}
                      <p>
                        {" "}
                        <Skeleton variant="text" width="8rem" /> <br />
                        <Skeleton variant="text" width="100%" />
                      </p>
                    </div>
                    <div className="drivepost">
                      <Skeleton
                        variant="circle"
                        width="3.5rem"
                        height="3.5rem"
                      />{" "}
                      <p>
                        {" "}
                        <Skeleton variant="text" width="8rem" /> <br />
                        <Skeleton variant="text" width="100%" />
                      </p>
                    </div>
                    <div className="drivepost">
                      <Skeleton
                        variant="circle"
                        width="3.5rem"
                        height="3.5rem"
                      />{" "}
                      <p>
                        {" "}
                        <Skeleton variant="text" width="8rem" /> <br />
                        <Skeleton variant="text" width="100%" />
                      </p>
                    </div>
                  </div>
                )}
                {friendSearch ? (
                  ""
                ) : friendsList.length <= 5 ? (
                  ""
                ) : friendsList.length - size <= 0 ? (
                  <button className="showmore" onClick={showless}>
                    <ExpandLessRoundedIcon style={{ fontSize: "30px" }} />
                  </button>
                ) : (
                  <button className="showmore" onClick={showMore}>
                    {" "}
                    <KeyboardArrowDownRoundedIcon
                      style={{ fontSize: "30px" }}
                    />
                  </button>
                )}
              </div>
            </div>
          )}

          {tabindex === 2 && (
            <div
              className="spacer"
              style={{ opacity: activeTab === "event" ? 1 : 0 }}
            >
              <div className="PartyTab">
                <div className="friendspartytitlebox">
                  <h1>{t("friends_event")}</h1>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LockIcon
                      onClick={() => setMakeSecretPartyPage(true)}
                      style={{
                        color: "#fd7f4d",
                        fontSize: "28px",
                        marginRight: "15px",
                      }}
                    />
                    <AddRoundedIcon
                      onClick={() => setMakePartyPage(true)}
                      style={{
                        color: "#fd7f4d",
                        fontSize: "34px",
                      }}
                    />
                  </div>
                </div>
                {driversdata ? (
                  <div>
                    {adminparty &&
                      (adminparty.length
                        ? adminparty.map((party) => (
                            <PrivateEventDisplay
                              party={party}
                              onClick={() => {
                                setFriendPartypostDrawer({
                                  id: party.id,
                                  open: true,
                                });
                              }}
                            />
                          ))
                        : "")}
                    {friendsparty &&
                      (friendsparty.length ? (
                        friendsparty.map((party, index) => (
                          <PrivateEventDisplay
                            party={party}
                            onClick={() => {
                              setFriendPartypostDrawer({
                                id: party.id,
                                open: true,
                              });
                            }}
                          />
                        ))
                      ) : (
                        <p className="no_event"> {t("no_friends_events")} </p>
                      ))}
                  </div>
                ) : (
                  <div className="posts">
                    <div className="drivepost">
                      <Skeleton
                        variant="circle"
                        width="3.5rem"
                        height="3.5rem"
                      />{" "}
                      <p>
                        {" "}
                        <Skeleton variant="text" width="8rem" /> <br />
                        <Skeleton variant="text" width="100%" />
                      </p>
                    </div>
                    <div className="drivepost">
                      <Skeleton
                        variant="circle"
                        width="3.5rem"
                        height="3.5rem"
                      />{" "}
                      <p>
                        {" "}
                        <Skeleton variant="text" width="8rem" /> <br />
                        <Skeleton variant="text" width="100%" />
                      </p>
                    </div>
                  </div>
                )}
                <div className="partytitlewrapper">
                  <h1>{t("official_events")}</h1>
                  <Selector
                    distance={distance}
                    onChange={(val) => setDistance(val)}
                  />
                </div>
                <div className="eventtags">
                  <span
                    onClick={() => {
                      vibrate("light");
                      setTagvalue("music");
                    }}
                    style={
                      tagvalue === "music"
                        ? {
                            background: "linear-gradient(90deg, grey, #485a6a)",
                          }
                        : {}
                    }
                  >
                    {t("music")}
                  </span>
                  <span
                    onClick={() => {
                      vibrate("light");
                      setTagvalue("sports");
                    }}
                    style={
                      tagvalue === "sports"
                        ? {
                            background: "linear-gradient(90deg, grey, #485a6a)",
                          }
                        : {}
                    }
                  >
                    {t("sports")}
                  </span>
                  <span
                    onClick={() => {
                      vibrate("light");
                      setTagvalue("gaming");
                    }}
                    style={
                      tagvalue === "gaming"
                        ? {
                            background: "linear-gradient(90deg, grey, #485a6a)",
                          }
                        : {}
                    }
                  >
                    {t("gaming")}
                  </span>
                  <span
                    onClick={() => {
                      vibrate("light");
                      setTagvalue("business");
                    }}
                    style={
                      tagvalue === "business"
                        ? {
                            background: "linear-gradient(90deg, grey, #485a6a)",
                          }
                        : {}
                    }
                  >
                    {t("business")}
                  </span>
                  <span
                    onClick={() => {
                      vibrate("light");
                      setTagvalue("entertainment");
                    }}
                    style={
                      tagvalue === "entertainment"
                        ? {
                            background: "linear-gradient(90deg, grey, #485a6a)",
                          }
                        : {}
                    }
                  >
                    {t("entertainment")}
                  </span>
                  <span
                    onClick={() => {
                      vibrate("light");
                      setTagvalue("other");
                    }}
                    style={
                      tagvalue === "other"
                        ? {
                            background: "linear-gradient(90deg, grey, #485a6a)",
                          }
                        : {}
                    }
                  >
                    {t("other")}
                  </span>
                </div>
                <div className="partypostslider">
                  {filteredOfficialEventsData?.filter((post) => {
                    if (tagvalue) {
                      return post?.topic?.includes(tagvalue);
                    } else return post;
                  })?.length > 0 ? (
                    filteredOfficialEventsData
                      ?.filter((post) => {
                        if (tagvalue) {
                          return post?.topic?.includes(tagvalue);
                        } else return post;
                      })
                      ?.map((post) => (
                        <div
                          key={post?.eventDocId}
                          onClick={() =>
                            setOfficialEventDrawer({
                              id: post?.eventDocId,
                              open: true,
                            })
                          }
                          className="partypostslidersingle"
                          style={{
                            background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${post?.image})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                          }}
                        >
                          <h1>
                            {post.clubname && post.clubname.length > 19
                              ? `${post?.clubname?.substring(0, [18])}...`
                              : post?.clubname}
                          </h1>
                          <p>
                            {moment(post.date.toDate()).format("MMM DD,")}{" "}
                            {post.opens} <br />
                            <span>
                              {post.address && post.address.length > 19
                                ? `${post.address.substring(0, [18])}...`
                                : post.address}
                            </span>
                          </p>
                          <p className="promoprice">{post.entranceprice}</p>
                        </div>
                      ))
                  ) : filteredOfficialEventsData?.filter((post) => {
                      if (tagvalue) {
                        return post?.topic?.includes(tagvalue);
                      } else return post;
                    })?.length === 0 ? (
                    <p className="no_event" style={{ margin: "0" }}>
                      {" "}
                      {t("no_official_events")}{" "}
                    </p>
                  ) : (
                    <>
                      <PromotionSkeleton />
                      <PromotionSkeleton />
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
