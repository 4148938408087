import React, { useState } from "react";

export default function Switcher(props) {
  const [active, setActive] = useState(false);

  return (
    <div
      className="switch"
      onClick={() => {
        props?.onClick();
        setActive((prev) => !prev);
      }}
      style={
        active ? { background: "rgb(253, 127, 77)" } : { background: "#1f1f1f" }
      }
    >
      <div
        className="switchcircle"
        style={
          active
            ? { marginLeft: "20px", transition: ".3s" }
            : { marginLeft: "2px", transition: ".3s" }
        }
      ></div>
    </div>
  );
}
