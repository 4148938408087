import React, { useEffect, useState } from "react";
import FirebaseContext from "../firebase/context";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useParams, useHistory } from "react-router-dom";
//Moment
import moment from "moment";
//Icons
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import EditIcon from "@material-ui/icons/Edit";
import Seat from "./Seat";
import Modal from "@material-ui/core/Modal";
import Guests from "./Guests";
import { Badge, SwipeableDrawer } from "@material-ui/core";
import AvatarComponentPost from "./AvatarComponentParty";
import { useTranslation } from "react-i18next";
import FromTo from "../images/fromto.png";
import Drawer from "@material-ui/core/Drawer";
import { DeleteOutline } from "@material-ui/icons";
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import Chatroom from "./Chatroom";
import Userpage from "./Userpage";
//Autocomplete
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
//Progress
import CircularProgress from "@material-ui/core/CircularProgress";
import useCloseDrawers from "../hooks/useDrawer";
import vibrate from "../util/feedback";
import BugInfo from "./BugInfo";

export default function DriverPost(props) {
  const { firebase } = React.useContext(FirebaseContext);
  const { t } = useTranslation();
  const _params = useParams();
  const params = props.params || _params;
  const history = useHistory();
  const [bottom, setBottom] = React.useState(false);
  const SwiperArea = document.getElementById("swipareadiv");
  const [openModal, setOpenModal] = React.useState(false);
  const [animation, setAnimation] = useState(false);
  const [createstate, setCreatestate] = useState(false);
  const [userPage, setUserPage] = useState({});
  const [userPageTwo, setUserPageTwo] = useState({});
  const [clickanimation, setClickanimation] = useState(false);
  const [headingClickanimation, setHeadingClickanimation] = useState(false);

  const [userData] = useDocumentData(
    firebase.db.doc(`users/${firebase.auth.currentUser.uid}`)
  );

  const [driversdata] = useDocumentData(
    firebase.db.doc(`drivers/${params.id}`)
  );

  const [edit, setEdit] = useState(false);

  //Editing Constants
  const [heading, setHeading] = React.useState("");
  const [starting, setStarting] = React.useState("");
  const [club, setClub] = React.useState("");
  const [time, setTime] = React.useState("12:00");
  const [car, setCar] = React.useState("");
  const [fit, setFit] = React.useState(1);
  const [data, setData] = React.useState({});
  const [bottomone, setBottomone] = useState(false);
  const [bottomtwo, setBottomtwo] = useState(false);
  const [seatedit, setSeatedit] = useState(false);
  const [chatroom, setChatroom] = useState(false);
  const [more, setMore] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [timeone, setTimeone] = useState("12");
  const [timetwo, setTimetwo] = useState("00");

  useEffect(() => {
    setStarting(driversdata?.starting);
    setHeading(driversdata?.heading);
    setTimeone(driversdata?.time.slice(0, 2));
    setTimetwo(driversdata?.time.slice(-2));
    setFit(driversdata?.fit);
    setCar(driversdata?.car);
    setClub(driversdata?.club);
  }, [driversdata]);

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (firebase.db) {
      const unsubscribe = firebase.db
        .collection(`drivers/${params.id}/messages`)
        .orderBy("createdAt")
        .onSnapshot((querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          //UPDATE MESSAGES
          setMessages(data);
        });
      return unsubscribe;
    }
  }, [firebase.db, params]);

  function confirmTime() {
    vibrate("light");
    setBottomone((prevState) => !prevState);
    const finalTime = timeone + ":" + timetwo;
    setTime(finalTime);
  }

  function confirmSeats() {
    vibrate("light");
    setSeatedit(true);
    setBottomtwo((prevState) => !prevState);
  }

  function makeId(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  function handleHopon() {
    vibrate("light");

    firebase.db.doc(`drivers/${params.id}`).update({
      seatRequests: firebase.FieldValue.arrayUnion(
        firebase.auth.currentUser.uid
      ),
    });

    firebase.db.doc(`users/${driversdata?.id}`).update({
      seatRequestsNotifications: firebase.FieldValue.arrayUnion({
        id: firebase.auth.currentUser.uid,
        requestId: makeId(15),
      }),
    });
  }

  function removeRequest() {
    firebase.db.doc(`drivers/${params.id}`).update({
      seatRequests: firebase.FieldValue.arrayRemove(
        firebase.auth.currentUser.uid
      ),
    });
  }

  function leaveSeat() {
    firebase.db.doc(`drivers/${params.id}`).update({
      seats: firebase.FieldValue.arrayRemove(firebase.auth.currentUser.uid),
    });
  }

  function handleDelete(uid) {
    vibrate("light");
    setOpenModal(true);
    const postRef = firebase.db.doc(`drivers/${uid}`);
    setTimeout(() => {
      postRef
        .delete()
        .then(() => {
          console.log(`Document deleted`);
        })
        .catch((err) => {
          console.error("Error deleting document:", err);
        })
        .then(() => {
          if (props.onClose) return props.onClose();
          history.push("/Home");
        });
    }, 500);
  }

  const [coordinates, setCoordinates] = React.useState({
    lat: null,
    lng: null,
  });

  const [headingCoordinates, setHeadingCoordinates] = React.useState({
    lat: null,
    lng: null,
  });

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setStarting(value);
    setCoordinates(latLng);
    setClickanimation(false);
  };

  const handleSelectHeading = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setHeading(value);
    setHeadingCoordinates(latLng);
    setHeadingClickanimation(false);
  };

  function handleUpdate() {
    setSubmitting(true);
    firebase.db
      .doc(`drivers/${params.id}`)
      .update({
        heading: heading,
        starting: starting,
        club: club,
        time: time,
        car: car,
        fit: fit,
        lat: coordinates.lat,
        long: coordinates.lng,
      })
      .then(() => {
        setSubmitting(false);
        setSeatedit(false);
        setEdit(false);
        setClickanimation(false);
      });
  }

  useCloseDrawers({
    drawers: [
      () => setUserPage((prev) => ({ ...prev, open: false })),
      () => setUserPageTwo((prev) => ({ ...prev, open: false })),
      () => setChatroom(false),
      () => setBottom(false),
    ],
  });

  return (
    <div className="driver-post">
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="modalwarning">
          <p> {t("deleting")} </p>
        </div>
      </Modal>

      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 199 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={userPage.open}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setUserPage((prev) => ({ ...prev, open: false }))}
      >
        <Userpage
          params={{ UserId: userPage.id }}
          onClose={() => setUserPage((prev) => ({ ...prev, open: false }))}
        ></Userpage>
      </SwipeableDrawer>

      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 200 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={userPageTwo.open}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setUserPageTwo((prev) => ({ ...prev, open: false }))}
      >
        <Userpage
          params={{ UserId: userPageTwo.id }}
          noPaddingBottom
          onClose={() => setUserPageTwo((prev) => ({ ...prev, open: false }))}
        ></Userpage>
      </SwipeableDrawer>

      <SwipeableDrawer
        open={chatroom}
        onClose={() => setChatroom(false)}
        anchor="right"
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "transparent",
            width: "100vw",
            height: "100%",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
      >
        <Chatroom
          onClose={() => setChatroom(false)}
          params={params.id}
          guests={driversdata?.seats}
          adminId={driversdata?.id}
          subject={driversdata?.club}
          date={driversdata?.date}
        />
      </SwipeableDrawer>

      <Drawer
        anchor="bottom"
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            overflow: "hidden",
            background: "black",
          },
        }}
        open={edit}
        onClose={() => setEdit(false)}
      >
        <div className="editdrawer">
          <div
            id="createtab"
            className={createstate ? "createAdrivePost" : "deActive"}
          >
            <div
              className={createstate ? "createInner" : "createInnerdeActive"}
            >
              <div className="addheader">
                <div className="headtitleparty">
                  <div
                    onClick={() => {
                      setEdit(false);
                      setAnimation((prevState) => !prevState);
                      setClickanimation(false);
                      setHeadingClickanimation(false);
                    }}
                    className="backlink"
                  >
                    <ArrowBackIosRoundedIcon style={{ color: "#FD7F4D" }} />
                  </div>
                  <h2>
                    <EditIcon />
                  </h2>
                  {heading === "" ? (
                    <button
                      disabled
                      style={{ color: "rgb(123, 127, 133)" }}
                      className="poster buttonanimation"
                    >
                      {t("edit")}
                    </button>
                  ) : starting === "" ? (
                    <button
                      disabled
                      style={{ color: "rgb(123, 127, 133)" }}
                      className="poster buttonanimation"
                    >
                      {t("edit")}
                    </button>
                  ) : club === "" ? (
                    <button
                      disabled
                      style={{ color: "rgb(123, 127, 133)" }}
                      className="poster buttonanimation"
                    >
                      {t("edit")}
                    </button>
                  ) : car === "" ? (
                    <button
                      disabled
                      style={{ color: "rgb(123, 127, 133)" }}
                      className="poster buttonanimation"
                    >
                      {t("edit")}
                    </button>
                  ) : fit === "" ? (
                    <button
                      disabled
                      style={{ color: "rgb(123, 127, 133)" }}
                      className="poster buttonanimation"
                    >
                      {t("edit")}
                    </button>
                  ) : submitting ? (
                    <button disabled className="poster buttonanimation">
                      <CircularProgress
                        style={{
                          color: "white",
                          width: "25px",
                          height: "25px",
                        }}
                      />
                    </button>
                  ) : (
                    <button
                      onClick={handleUpdate}
                      className="poster buttonanimation"
                    >
                      {t("edit")}
                    </button>
                  )}
                </div>
              </div>
              <div className="addholder">
                <h2 className={animation ? "scaleanimation" : ""}>
                  {t("heading")}
                </h2>
                <div
                  style={
                    clickanimation
                      ? {
                          height: "100vh",
                          width: "100vw",
                          position: "absolute",
                          left: "0",
                          top: "58px",
                          zIndex: "10",
                          transition: ".8s",
                          opacity: "1",
                          background: "black",
                          userSelect: "initial",
                        }
                      : headingClickanimation
                      ? {
                          height: "100vh",
                          width: "100vw",
                          position: "absolute",
                          left: "0",
                          top: "58px",
                          zIndex: "10",
                          transition: ".8s",
                          opacity: "1",
                          background: "black",
                          userSelect: "initial",
                        }
                      : {
                          height: "100vh",
                          width: "100vw",
                          position: "absolute",
                          left: "0",
                          top: "57px",
                          zIndex: "-1",
                          transition: ".8s",
                          opacity: "0",
                          background: "black",
                          userSelect: "none",
                        }
                  }
                >
                  <span
                    style={{
                      position: "absolute",
                      top: "26px",
                      right: "18px",
                      color: "white",
                    }}
                  >
                    <span
                      style={{
                        position: "relative",
                        height: "35px",
                        width: "39px",
                      }}
                      onClick={() => {
                        if (clickanimation) {
                          setClickanimation(false);
                        } else {
                          setHeadingClickanimation(false);
                        }
                      }}
                    >
                      <ClearRoundedIcon style={{ fontSize: "35px" }} />
                    </span>
                  </span>
                </div>

                <PlacesAutocomplete
                  value={starting}
                  onChange={(e) => {
                    setClickanimation(true);
                    setStarting(e);
                  }}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div
                      style={
                        clickanimation
                          ? {
                              width: "calc(100% - 30px)",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              zIndex: "11",
                              marginTop:
                                "calc(-50px - env(safe-area-inset-top))",
                              transition: ".5s",
                            }
                          : {
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              transition: ".5s",
                            }
                      }
                    >
                      <input
                        className={animation ? "scaleanimationinput" : ""}
                        style={
                          clickanimation
                            ? {
                                width: "calc(100% - 30px)",
                                marginLeft: "-15px",
                              }
                            : { animationDelay: ".1s" }
                        }
                        {...getInputProps({
                          placeholder: t("from") + " " + t("munich"),
                        })}
                        onClick={() => setClickanimation(true)}
                      />
                      <div
                        className="suggestions driver"
                        style={
                          starting === ""
                            ? { display: "none" }
                            : {
                                display: "flex",
                                flexDirection: "column",
                                width: "calc(100% + 40px)",
                                marginLeft: "40px",
                              }
                        }
                      >
                        {clickanimation && (
                          <>
                            {loading ? (
                              <>
                                <BugInfo />
                                <div className="suggestionloader">
                                  <CircularProgress
                                    style={{ color: "white" }}
                                  />
                                </div>
                              </>
                            ) : null}
                          </>
                        )}
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#efefef"
                              : "#ffff",
                            cursor: suggestion.active ? "pointer" : "initial",
                          };
                          return (
                            <div
                              key={suggestion.description}
                              {...getSuggestionItemProps(suggestion, { style })}
                              style={{ display: "flex", alignItems: "center" }}
                              className="suggestionsingle"
                            >
                              {/* <LocationOnIcon
                                style={{
                                  fontSize: "25px",
                                  marginRight: "5px",
                                  marginLeft: "10px",
                                  color: "#a8a8a8",
                                }}
                              />{" "} */}
                              <p className="suggestiontext">
                                {suggestion.description}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>

                <PlacesAutocomplete
                  value={heading}
                  onChange={(e) => {
                    setHeadingClickanimation(true);
                    setHeading(e);
                  }}
                  onSelect={handleSelectHeading}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div
                      style={
                        headingClickanimation
                          ? {
                              width: "calc(100% - 30px)",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              zIndex: "11",
                              marginTop:
                                "calc(-110px - env(safe-area-inset-top))",
                              transition: ".5s",
                            }
                          : {
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              transition: ".5s",
                            }
                      }
                    >
                      <input
                        className={animation ? "scaleanimationinput" : ""}
                        style={
                          headingClickanimation
                            ? {
                                width: "calc(100% - 30px)",
                                marginLeft: "-15px",
                              }
                            : { animationDelay: ".2s" }
                        }
                        {...getInputProps({
                          placeholder: t("destination"),
                        })}
                        onClick={() => setHeadingClickanimation(true)}
                      />
                      <div
                        className="suggestions driver"
                        style={
                          heading === ""
                            ? { display: "none" }
                            : {
                                display: "flex",
                                flexDirection: "column",
                                width: "calc(100% + 40px)",
                                marginLeft: "40px",
                              }
                        }
                      >
                        {headingClickanimation && (
                          <>
                            {loading ? (
                              <>
                                <BugInfo />
                                <div className="suggestionloader">
                                  <CircularProgress
                                    style={{ color: "white" }}
                                  />
                                </div>
                              </>
                            ) : null}
                          </>
                        )}
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#efefef"
                              : "#ffff",
                            cursor: suggestion.active ? "pointer" : "initial",
                          };
                          return (
                            <div
                              key={suggestion.description}
                              {...getSuggestionItemProps(suggestion, { style })}
                              style={{ display: "flex", alignItems: "center" }}
                              className="suggestionsingle"
                            >
                              <p className="suggestiontext">
                                {suggestion.description}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>

                <div className="divider"></div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                    fontSize: "16px",
                    margin: "10px 0",
                    marginLeft: "15px",
                    color: "#FD7F4D",
                  }}
                >
                  <p
                    onClick={() => setBottomone((prevState) => !prevState)}
                    style={{ margin: "10px 0" }}
                  >
                    {t("starting_time")}
                  </p>
                  <Drawer
                    PaperProps={{
                      elevation: 0,
                      style: {
                        borderRadius: "0",
                        backgroundColor: "black",
                      },
                    }}
                    anchor={"bottom"}
                    onClose={() => setBottomone((prevState) => !prevState)}
                    open={bottomone}
                  >
                    <div className="createDrawer">
                      <ClearRoundedIcon
                        onClick={() => {
                          setBottomone((prevState) => !prevState);
                        }}
                        style={{ fontSize: "40px", color: "#FD7F4D" }}
                      />
                      <h2 style={{ marginTop: "20px" }}>
                        {t("when_start_driving")}
                      </h2>
                      <div className="createDrawerinputs">
                        <input
                          onChange={(e) => {
                            const val = e.target.value;
                            setTimeone(val);
                          }}
                          placeholder="12"
                          value={timeone}
                          inputMode="numeric"
                          maxlength="2"
                        />
                        <h2>:</h2>
                        <input
                          onChange={(e) => {
                            const val = e.target.value;
                            setTimetwo(val);
                          }}
                          placeholder="00"
                          value={timetwo}
                          inputMode="numeric"
                          maxlength="2"
                        />
                      </div>
                      <div
                        onClick={
                          timeone === ""
                            ? null
                            : timetwo === ""
                            ? null
                            : confirmTime
                        }
                        style={
                          timeone === ""
                            ? { background: "grey" }
                            : timetwo === ""
                            ? { background: "grey" }
                            : {}
                        }
                        className="confirmbutton"
                      >
                        {t("confirm")}
                      </div>
                    </div>
                  </Drawer>
                  <p
                    onClick={() => {
                      setBottomtwo((prevState) => !prevState);
                    }}
                    style={{ margin: "10px 0", marginLeft: "100px" }}
                  >
                    {fit} {t("seat")}
                  </p>
                  <Drawer
                    PaperProps={{
                      elevation: 0,
                      style: {
                        borderRadius: "0",
                        backgroundColor: "black",
                      },
                    }}
                    anchor={"bottom"}
                    onClose={() => bottomtwo((prevState) => !prevState)}
                    open={bottomtwo}
                  >
                    <div className="createDrawer">
                      <ClearRoundedIcon
                        onClick={() => setBottomtwo((prevState) => !prevState)}
                        style={{ fontSize: "40px", color: "#FD7F4D" }}
                      />
                      <h2 style={{ marginTop: "20px" }}>
                        {t("seats_available")}
                      </h2>
                      <div
                        className="createDrawerinputs"
                        style={{ justifyContent: "space-around" }}
                      >
                        {fit === 1 ? (
                          <span
                            style={{ color: "grey", border: "2px solid grey" }}
                          >
                            <ArrowDownwardRoundedIcon
                              style={{ fontSize: "20px", margin: "0px" }}
                            />
                          </span>
                        ) : (
                          <span
                            onClick={() => {
                              vibrate("light");
                              setFit(fit - 1);
                            }}
                          >
                            <ArrowDownwardRoundedIcon
                              style={{ fontSize: "20px", margin: "0px" }}
                            />
                          </span>
                        )}
                        <h2 className="fitcount">{fit}</h2>
                        <span
                          onClick={() => {
                            vibrate("light");
                            setFit(fit + 1);
                          }}
                        >
                          <ArrowUpwardRoundedIcon
                            style={{ fontSize: "20px", margin: "0px" }}
                          />
                        </span>
                      </div>
                      <div onClick={confirmSeats} className="confirmbutton">
                        {t("confirm")}
                      </div>
                    </div>
                  </Drawer>
                </div>
                <div className="divider"></div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                    fontSize: "16px",
                    margin: "10px 0",
                    color: "#FD7F4D",
                  }}
                >
                  <input
                    className={animation ? "scaleanimationinputsmall" : ""}
                    onChange={(event) => {
                      setClub(event.target.value);
                    }}
                    style={{ margin: "0 5px", animationDelay: ".2s" }}
                    placeholder="Event"
                    value={club}
                  />
                  <input
                    className={animation ? "scaleanimationinputsmall" : ""}
                    onChange={(event) => {
                      setCar(event.target.value);
                    }}
                    style={{ margin: "0 5px", animationDelay: ".3s" }}
                    placeholder={t("car")}
                    value={car}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>

      <div
        className="DrivePostWrapper"
        style={chatroom ? { overflow: "hidden" } : { overflow: "initial" }}
      >
        <div className="overflowtop"></div>
        <div className="DrivePostHeader">
          <div
            onClick={() => {
              props.onClose?.();
            }}
          >
            <ArrowBackIosRoundedIcon
              style={{ fontSize: "25px" }}
              className="postheaderbackicon"
            />
          </div>
          {driversdata?.id === firebase.auth.currentUser.uid ? (
            <button
              onClick={() => {
                setEdit(true);
                setAnimation(true);
              }}
              className="posteditbutton"
            >
              <EditIcon style={{ fontSize: "20px", marginLeft: "4px" }} />
              <span className="editspan">Edit</span>
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="overflow"></div>
        <div style={{ paddingBottom: "100px" }} className="DrivePostBody">
          <div className="DrivePostBodyName">
            <span
              onClick={() => setUserPage({ id: driversdata?.id, open: true })}
              style={{ border: "1px solid white", borderRadius: "100%" }}
            >
              <AvatarComponentPost driverid={driversdata && driversdata?.id} />
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "10px",
              }}
            >
              <h1
                onClick={() => setUserPage({ id: driversdata?.id, open: true })}
              >
                {driversdata && driversdata?.driver}
              </h1>
              <h2>
                {t("drive_created")}{" "}
                {driversdata &&
                  moment(driversdata?.date.toDate())
                    .startOf("seconds")
                    .fromNow()}
              </h2>
            </div>
          </div>
          {driversdata?.seats?.includes(firebase.auth.currentUser.uid) ? (
            <h4 className="eventfavourite" onClick={() => setChatroom(true)}>
              {messages?.filter(
                (msg) =>
                  !(
                    msg.createdAt.toDate() <
                    userData?.chatsVisited?.[params.id]?.toDate()
                  )
              ).length === 0 ? (
                <div className="chatBadge">Chat</div>
              ) : (
                <Badge
                  color="secondary"
                  badgeContent={`${
                    messages?.filter(
                      (msg) =>
                        !(
                          msg.createdAt.toDate() <
                          userData?.chatsVisited?.[params.id]?.toDate()
                        )
                    ).length
                  }`}
                >
                  <div className="chatBadge">Chat</div>
                </Badge>
              )}
            </h4>
          ) : driversdata?.id === firebase.auth.currentUser.uid ? (
            <h4 className="eventfavourite" onClick={() => setChatroom(true)}>
              {messages?.filter(
                (msg) =>
                  !(
                    msg.createdAt.toDate() <
                    userData?.chatsVisited?.[params.id]?.toDate()
                  )
              ).length === 0 ? (
                <div className="chatBadge">Chat</div>
              ) : (
                <Badge
                  color="secondary"
                  badgeContent={`${
                    messages?.filter(
                      (msg) =>
                        !(
                          msg.createdAt.toDate() <
                          userData?.chatsVisited?.[params.id]?.toDate()
                        )
                    ).length
                  }`}
                >
                  <div className="chatBadge">Chat</div>
                </Badge>
              )}
            </h4>
          ) : (
            ""
          )}
          <div className="DrivePostBodyMain">
            <h1 style={{ fontSize: "24px", fontWeight: "600" }}>
              {moment(driversdata?.date.toDate()).format("dddd DD. MMMM")}
            </h1>
          </div>
          <div className="DrivePostFromTo">
            <img src={FromTo} alt="fromtosvg" />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "10px",
                width: "100%",
              }}
            >
              <p>
                {driversdata && driversdata?.starting.length >= 29 ? (
                  more ? (
                    <span onClick={() => setMore((prevState) => !prevState)}>
                      {driversdata?.starting}
                    </span>
                  ) : (
                    <span onClick={() => setMore((prevState) => !prevState)}>
                      {`${driversdata?.starting.substring(0, [28])}...`}
                    </span>
                  )
                ) : (
                  <span> {driversdata && driversdata?.starting} </span>
                )}
              </p>
              <p>
                {t("to")} {driversdata && driversdata?.heading}
              </p>
            </div>
          </div>
          <div className="divertwo"></div>
          <div className="DrivePostInfo">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                fontSize: "16px",
                margin: "10px 0",
                marginLeft: "10px",
                color: "#A5A5A5",
              }}
            >
              <p style={{ margin: "10px 0" }}>
                {moment(driversdata?.date.toDate()).format("dddd, DD.MM.YYYY")}
              </p>
              <p
                style={{
                  margin: "10px 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>
                  {(driversdata?.fit || 0) - (driversdata?.seats?.length || 0) <
                  0
                    ? 0
                    : (driversdata?.fit || 0) -
                      (driversdata?.seats?.length || 0)}{" "}
                  {(driversdata?.fit || 0) -
                    (driversdata?.seats?.length || 0) ===
                  1
                    ? t("seats_available_post_single")
                    : t("seats_available_post")}{" "}
                </span>
              </p>
            </div>
          </div>
          <div className="divertwo"></div>
          <div className="DrivePostInfo">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                fontSize: "16px",
                margin: "10px 0",
                marginLeft: "10px",
                color: "#A5A5A5",
              }}
            >
              <p style={{ margin: "10px 0" }}>
                {t("starts_at_post")} {driversdata && driversdata?.time}
              </p>
              <p
                style={{
                  margin: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "2px solid #272727",
                  borderRadius: "7px",
                  padding: "5px",
                }}
                onClick={() => setBottom(true)}
              >
                <span style={{ width: "100%" }}>
                  {driversdata?.seats?.length || 0}/
                  {driversdata && driversdata?.fit} {t("people")}
                </span>
                {driversdata?.seats?.length >= 4 ? (
                  ""
                ) : (
                  <div className="imagesnext">
                    {driversdata?.seats?.length >= 4
                      ? ""
                      : driversdata &&
                        driversdata?.seats?.map((seat) => {
                          return <Guests key={seat} uid={seat} />;
                        })}
                  </div>
                )}
              </p>
            </div>
          </div>
          <div className="divertwo"></div>
          <div className="DrivePostEvent">
            <p style={{ borderRadius: "10px" }}>
              {" "}
              {driversdata && driversdata?.club}{" "}
            </p>
            <p style={{ borderRadius: "10px" }}>
              {" "}
              {driversdata && driversdata?.car}{" "}
            </p>
          </div>

          {driversdata?.seats?.includes(firebase.auth.currentUser.uid) ? (
            <div onClick={leaveSeat} className="activedriver">
              {t("request_to_hopon_leave")}
            </div>
          ) : driversdata?.seatRequests?.includes(
              firebase.auth.currentUser.uid
            ) ? (
            <div onClick={removeRequest} className="activedriver">
              {t("request_to_hopon_remove_request")}
            </div>
          ) : driversdata?.id === firebase.auth.currentUser.uid ? (
            <div
              onClick={() => handleDelete(params.id)}
              className="activedriver"
              style={{ zIndex: "1" }}
            >
              <DeleteOutline style={{ fontSize: "28px" }} />
            </div>
          ) : driversdata && driversdata?.seats?.length === driversdata?.fit ? (
            <div
              style={{ opacity: ".3" }}
              onClick={removeRequest}
              className="activedriver"
            >
              {t("request_to_hopon_full")}
            </div>
          ) : (
            <div onClick={handleHopon} className="activedriver">
              {t("request_to_hopon")}
            </div>
          )}
        </div>
        <SwipeableDrawer
          style={{ zIndex: 200 }}
          PaperProps={{
            elevation: 0,
            style: {
              borderRadius: "20px 20px 0 0",
              background: "#262626",
              marginTop: "env(safe-area-inset-top)",
            },
          }}
          anchor={"bottom"}
          onClose={() => setBottom(false)}
          open={bottom}
          SwipeAreaProps={SwiperArea}
        >
          <div className="PostListGuest">
            <div id="swipareadiv" className="GuestListHead">
              <div className="swipelinediv">
                <span className="swipeline"></span>
              </div>
              <h1> {t("on_this_ride")} </h1>
            </div>
            {driversdata && driversdata?.seats ? (
              <div
                style={{
                  flex: "0 1 auto",
                  overflowY: "scroll",
                  width: "100%",
                  margin: "0",
                }}
                className="postinfo seats"
                onTouchMove={(e) => {
                  e.stopPropagation();
                }}
              >
                {driversdata &&
                  driversdata?.seats?.map((seat) => {
                    return (
                      <Seat
                        admin={driversdata?.id}
                        params={params}
                        key={seat}
                        uid={seat}
                        onClick={() => setUserPageTwo({ id: seat, open: true })}
                      />
                    );
                  })}
              </div>
            ) : (
              <div className="no_one_yet">
                <p> {t("no_one_yet")} </p>
              </div>
            )}
          </div>
        </SwipeableDrawer>
      </div>
    </div>
  );
}
