import { useCallback, useEffect } from "react";

export const emitCloseDrawerEvent = () => {
  const evt = new CustomEvent("closedrawers");
  window.dispatchEvent(evt);
};

// drawers: Array: callback functions to close drawers
const useCloseDrawers = ({ drawers = [] }) => {
  const closeAllDrawers = useCallback(() => {
    drawers.forEach((closeDrawer) => {
      if (closeDrawer) closeDrawer();
    });
  }, [drawers]);

  useEffect(() => {
    window.addEventListener("closedrawers", closeAllDrawers);
    return () => window.removeEventListener("closedrawers", closeAllDrawers);
  }, [closeAllDrawers]);
};

export default useCloseDrawers;
