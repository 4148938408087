import { useCallback, useEffect } from "react";

const CLOSE_KEYS = ["Enter", "Escape"];

const useHideKeyboard = () => {
  const hideKeyboard = useCallback(({ key }) => {
    if (CLOSE_KEYS.includes(key)) window.Keyboard?.hide();
  }, []);

  useEffect(() => {
    document.addEventListener("keypress", hideKeyboard);
    return () => document.removeEventListener("keypress", hideKeyboard);
  }, [hideKeyboard]);

  return { hideKeyboard };
};

export default useHideKeyboard;
