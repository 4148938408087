import React, { useCallback, useEffect, useState } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FirebaseContext from "../firebase/context";
import AddIcon from "@material-ui/icons/Add";
import KeyboardArrowUpRoundedIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import { useHistory } from "react-router-dom";
import LocationOnIcon from "@material-ui/icons/LocationOnRounded";
import Modal from "@material-ui/core/Modal";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useTranslation } from "react-i18next";
//Progress
import CircularProgress from "@material-ui/core/CircularProgress";
//Autocomplete
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import uploadFile from "../util/uploadFile";
import ClearRoundedIcon from "@material-ui/icons/CloseRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import RadioButtonUncheckedRoundedIcon from "@material-ui/icons/RadioButtonUncheckedRounded";
import { Drawer, SwipeableDrawer } from "@material-ui/core";
import VideocamIcon from "@material-ui/icons/Videocam";
import ImageIcon from "@material-ui/icons/Image";
import SwipeableViews from "react-swipeable-views";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import Switcher from "./Switcher";
import vibrate from "../util/feedback";
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import InfoIcon from "@material-ui/icons/Info";
import CamUpload from "../util/CamUpload";
import BugInfo from "./BugInfo";

export default function BusinessPost(onClose) {
  const { firebase, storage, user } = React.useContext(FirebaseContext);
  const { t } = useTranslation();

  const [index, setIndex] = React.useState(0);

  const [clubname, setClubname] = React.useState("");
  const [clubnameErr, setClubnameErr] = React.useState(false);
  const [city, setCity] = React.useState("");
  const [cityErr, setCityErr] = React.useState(false);
  const [address, setAddress] = React.useState("");
  const [addressErr, setAddressErr] = React.useState(false);
  const [future, setFuture] = React.useState(false);
  const [futureTwo, setFutureTwo] = React.useState(false);
  const [futureDrawer, setFutureDrawer] = React.useState(false);
  const [telefon, setTelefon] = React.useState("");
  const [telefonErr, setTelefonErr] = React.useState(false);
  const [musik, setMusik] = React.useState("");
  const [musikErr, setMusikErr] = React.useState(false);
  const [entranceprice, setEntranceprice] = React.useState("");
  const [entrancepriceErr, setEntrancepriceErr] = React.useState(false);
  const [opening, setOpening] = React.useState("");
  const [openingErr, setOpeningErr] = React.useState(false);
  const [info, setInfo] = React.useState("");
  const [image, setImage] = React.useState("");
  const [imageErr, setImageErr] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [businessImage, setBusinessImage] = React.useState("");
  const [businessLink, setBusinessLink] = React.useState("");
  const [coordinatesValue, setCoordinatesValue] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [businessLinkErr, setBusinessLinkErr] = React.useState(false);
  const [topicState, setTopicState] = React.useState(false);
  const history = useHistory();
  const [widthValue, setWidthValue] = useState("0%");
  const [openCamDrawer, setOpenCamDrawer] = useState(false);
  const [success, setSuccess] = useState(false);
  const [userData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );

  const eventDate = new Date();
  const [days, setDays] = useState(0);

  const makeDateObject = (date, days) => {
    const calculation = date.setDate(date.getDate() + days);
    const calculatedDate = new Date(calculation);

    const month = calculatedDate?.getMonth() + 1;
    const day = calculatedDate?.getDate();

    return {
      displayDate: `${("0" + day).slice(-2)}.${("0" + month).slice(-2)}`,
      value: calculatedDate,
    };
  };

  const promotions = (userData && userData.promotions) || 0;

  const [coordinates, setCoordinates] = React.useState({
    lat: null,
    lng: null,
  });

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
    setCoordinatesValue(true);
  };

  const [data, setData] = useState([]);

  const removeItem = (value) => {
    setData((prev) => [...prev.filter((item) => item !== value)]);
  };

  const addItem = (value) => {
    setData((prev) => [...prev, value]);
  };

  function handleChange(value) {
    if (data.includes(value)) {
      removeItem(value);
    } else {
      addItem(value);
    }
  }

  function handleSubmitBusinessPost() {
    if (clubname === "") {
      setClubnameErr(true);
    } else if (city === "") {
      setCityErr(true);
    } else if (address === "") {
      setAddressErr(true);
    } else if (telefon === "") {
      setTelefonErr(true);
    } else if (entranceprice === "") {
      setEntrancepriceErr(true);
    } else if (data.length === 0) {
      setMusikErr(true);
    } else if (opening === "") {
      setOpeningErr(true);
    } else if (image === "") {
      setImageErr(true);
    } else if (coordinates.lat === null) {
      return null;
    } else if (coordinates.lng === null) {
      return null;
    } else if (businessLink && !businessLink.includes("https://")) {
      setBusinessLinkErr(true);
    } else {
      setClubnameErr(false);
      setCityErr(false);
      setAddressErr(false);
      setTelefonErr(false);
      setEntrancepriceErr(false);
      setOpeningErr(false);
      setImageErr(false);
      setMusikErr(false);
      setSubmitting(true);
      firebase.db
        .collection(`b2bposts`)
        .add({
          clubname: clubname,
          city: city,
          address: address,
          entranceprice: entranceprice,
          topic: data,
          date: new Date(new Date().setDate(new Date().getDate() + days)),
          id: user.uid,
          user: userData?.username,
          opens: opening,
          info: info,
          telefon: telefon,
          lat: coordinates.lat,
          long: coordinates.lng,
          image: businessImage,
          link: businessLink,
          views: [],
        })
        .then(() => {
          firebase.db
            .doc(`users/${user && user.uid}`)
            .update({ promotions: promotions - 1 });
          setSuccess(true);
          setSubmitting(false);
        });
    }
  }

  return (
    <>
      <Modal
        open={loader}
        onClose={() => setLoader(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="modalwarning" style={{ padding: "20px" }}>
          <CircularProgress style={{ color: "#fd7f4d" }} />
        </div>
      </Modal>
      <Drawer
        PaperProps={{
          elevation: 0,
          style: { borderRadius: "0", backgroundColor: "black" },
        }}
        anchor={"bottom"}
        onClose={() => setTopicState((prevState) => !prevState)}
        open={topicState}
      >
        <div className="createDrawer">
          <ClearRoundedIcon
            onClick={() => {
              setTopicState((prevState) => !prevState);
            }}
            style={{ fontSize: "40px", color: "#FD7F4D" }}
          />
          <h2 style={{ marginTop: "20px" }}> {t("event_topic")} </h2>
          <div className="eventtypecontainer">
            <div
              onClick={() => handleChange("music")}
              className="singleeventtype"
              style={
                data.includes("music")
                  ? { background: "rgb(253, 127, 77)" }
                  : {}
              }
            >
              {data.includes("music") ? (
                <CheckCircleRoundedIcon
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                />
              ) : (
                <RadioButtonUncheckedRoundedIcon
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                />
              )}
              <span> {t("music")} </span>
            </div>
            <div
              onClick={() => handleChange("entertainment")}
              className="singleeventtype"
              style={
                data.includes("entertainment")
                  ? { background: "rgb(253, 127, 77)" }
                  : {}
              }
            >
              {data.includes("entertainment") ? (
                <CheckCircleRoundedIcon
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                />
              ) : (
                <RadioButtonUncheckedRoundedIcon
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                />
              )}
              <span>{t("entertainment")}</span>
            </div>
            <div
              onClick={() => handleChange("sports")}
              className="singleeventtype"
              style={
                data.includes("sports")
                  ? { background: "rgb(253, 127, 77)" }
                  : {}
              }
            >
              {data.includes("sports") ? (
                <CheckCircleRoundedIcon
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                />
              ) : (
                <RadioButtonUncheckedRoundedIcon
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                />
              )}
              <span>{t("sports")}</span>
            </div>
            <div
              onClick={() => handleChange("gaming")}
              className="singleeventtype"
              style={
                data.includes("gaming")
                  ? { background: "rgb(253, 127, 77)" }
                  : {}
              }
            >
              {data.includes("gaming") ? (
                <CheckCircleRoundedIcon
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                />
              ) : (
                <RadioButtonUncheckedRoundedIcon
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                />
              )}
              <span>{t("gaming")}</span>
            </div>
            <div
              onClick={() => handleChange("business")}
              className="singleeventtype"
              style={
                data.includes("business")
                  ? { background: "rgb(253, 127, 77)" }
                  : {}
              }
            >
              {data.includes("business") ? (
                <CheckCircleRoundedIcon
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                />
              ) : (
                <RadioButtonUncheckedRoundedIcon
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                />
              )}
              <span>{t("business")}</span>
            </div>
            <div
              onClick={() => handleChange("other")}
              className="singleeventtype"
              style={
                data.includes("other")
                  ? { background: "rgb(253, 127, 77)" }
                  : {}
              }
            >
              {data.includes("other") ? (
                <CheckCircleRoundedIcon
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                />
              ) : (
                <RadioButtonUncheckedRoundedIcon
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                />
              )}
              <span>{t("other")}</span>
            </div>
          </div>
          <div
            onClick={() => {
              vibrate("light");
              setTopicState(false);
            }}
            className="confirmbutton"
          >
            {t("confirm")}
          </div>
        </div>
      </Drawer>
      <Drawer
        PaperProps={{
          elevation: 0,
          style: { borderRadius: "0", backgroundColor: "black" },
        }}
        anchor={"bottom"}
        onClose={() => setFutureDrawer((prevState) => !prevState)}
        open={futureDrawer}
      >
        <div className="createDrawer">
          <ClearRoundedIcon
            onClick={() => {
              setFutureDrawer((prevState) => !prevState);
            }}
            style={{ fontSize: "40px", color: "#FD7F4D" }}
          />
          <h2 style={{ marginTop: "20px" }}> {t("plan_for_future")} </h2>
          <div className="futureEventInfo">
            <InfoIcon style={{ fontSize: "25px", margin: "10px" }} />
            {t("event_in_future_info")}
          </div>
          <div
            className="createDrawerinputs"
            style={{ justifyContent: "space-around" }}
          >
            <span
              onClick={() => {
                if (days >= 1) {
                  setDays(days - 1);
                } else {
                  return null;
                }
                vibrate("light");
              }}
              style={
                days === 0 ? { color: "grey", border: "2px solid grey" } : {}
              }
            >
              <ArrowDownwardRoundedIcon
                style={{ fontSize: "20px", margin: "0px" }}
              />
            </span>
            <h2
              className="fitcount"
              style={{
                width: "160px",
                maxWidth: "160px",
                padding: "10px 20px",
              }}
            >
              {makeDateObject(eventDate, days).displayDate}
            </h2>
            <span
              onClick={() => {
                if (days === 7) {
                  return null;
                } else {
                  setDays(days + 1);
                }
                vibrate("light");
              }}
              style={
                days === 7 ? { color: "grey", border: "2px solid grey" } : {}
              }
            >
              <ArrowUpwardRoundedIcon
                style={{ fontSize: "20px", margin: "0px" }}
              />
            </span>
          </div>
          <div
            onClick={() => {
              setFutureDrawer(false);
              vibrate("light");
            }}
            className="confirmbutton"
          >
            {t("confirm")}
          </div>
        </div>
      </Drawer>
      <Drawer
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            height: "100vh",
            width: "100vw",
            background: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
        transitionDuration={650}
        anchor={"bottom"}
        onClose={() => setSuccess((prevState) => !prevState)}
        open={success}
      >
        <div className="businessSuccesswrapper">
          <div className="overflowbusinesstop"></div>
          <div className="overflowbusinessbottom"></div>
          <div
            className="businessSuccessimage"
            style={{
              background: `url(${businessImage})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          ></div>
          <h1>{t("post_created")}</h1>
          <div className="successText">
            <h2>
              {clubname.length > 17
                ? `${clubname.substring(0, [17])}...`
                : clubname}
            </h2>
          </div>
          <button
            style={{
              position: "absolute",
              bottom: "20px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            onClick={() => {
              setTimeout(() => {
                onClose.onClose();
              }, 500);
              setSuccess(false);
              setWidthValue("100%");
            }}
            className="businessCreate businessSuccessButton"
          >
            {t("done")}
          </button>
        </div>
      </Drawer>
      <div className="businesswrapper">
        <div className="businesshead">
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              borderBottom: "1px solid rgb(48 47 47)",
            }}
          >
            <ArrowBackIosIcon
              onClick={onClose.onClose}
              style={{
                color: "#FD7F4D",
                position: "absolute",
                left: "26px",
                top: "50%",
                transform: "translate(0%, -50%)",
                padding: "10px",
                marginLeft: "-10px",
              }}
            />
            <h1> {t("promotions")} </h1>
          </div>
        </div>
        {/* MAIN */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              width: widthValue,
              height: "3px",
              marginBottom: "15px",
              background: "linear-gradient(90deg, #fe4040, #fe7640)",
              transition: ".8s ease-in-out",
            }}
          ></div>
        </div>
        <SwipeableViews
          disabled
          index={index}
          // onTouchStart={(event) => {
          //   event.stopPropagation();
          // }}
          // onTouchMove={(event) => {
          //   event.stopPropagation();
          // }}
          // onTouchEnd={(event) => {
          //   event.stopPropagation();
          // }}
        >
          <div className="innerbusinesswrapperdiv">
            <div className="innerbusinesswrapperinputs">
              <div
                style={
                  businessImage
                    ? {
                        background: `url(${businessImage})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                      }
                    : {}
                }
                onClick={() => setOpenCamDrawer(true)}
                className="innerblock1"
              >
                <CamUpload
                  user={user}
                  firebase={firebase}
                  targetHeight={900}
                  targetWidth={900}
                  onClose={() => setOpenCamDrawer(false)}
                  open={openCamDrawer}
                  setLoader={(val) => setLoader(val)}
                  setImgRef={(val) => setBusinessImage(val)}
                  setPropImage={(val) => setImage(val)}
                />
                <div className="inneroverflowtop"></div>
                <div className="inneroverflowbottom"></div>
                {!businessImage && (
                  <CameraAltOutlinedIcon
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: "10",
                      fontSize: "30px",
                      color: "white",
                    }}
                  />
                )}
              </div>
              <input
                onChange={(e) => setClubname(e.target.value)}
                style={{ marginTop: "20px" }}
                placeholder="Event Name"
              />
              <button
                onClick={() => {
                  if (businessImage && clubname) {
                    setIndex(1);
                    setWidthValue("40%");
                  }
                }}
                className="businessCreate"
                style={businessImage && clubname ? {} : { opacity: 0.3 }}
              >
                Next
              </button>
            </div>
          </div>
          <div className="innerbusinesswrapperdiv">
            <div className="innerbusinesswrapperinputs">
              <input
                placeholder={t("city_placeholder")}
                onChange={(e) => setCity(e.target.value)}
              />
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input {...getInputProps({ placeholder: t("address") })} />
                    <div
                      className="suggestions"
                      style={
                        address === ""
                          ? {
                              userSelect: "none",
                              opacity: 0,
                            }
                          : {
                              display: "flex",
                              flexDirection: "column",
                              opacity: 1,
                            }
                      }
                    >
                      {loading ? (
                        <>
                          <BugInfo />
                          <div className="suggestionloader">
                            <CircularProgress style={{ color: "white" }} />
                          </div>
                        </>
                      ) : null}
                      {suggestions.map((suggestion) => {
                        const style = {
                          backgroundColor: suggestion.active
                            ? "#efefef"
                            : "#ffff",
                          cursor: suggestion.active ? "pointer" : "initial",
                        };
                        return (
                          <div
                            key={suggestion.description}
                            {...getSuggestionItemProps(suggestion, { style })}
                            style={{ display: "flex", alignItems: "center" }}
                            className="suggestionsingle"
                          >
                            <p className="suggestiontext">
                              {suggestion.description}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <input
                type="number"
                inputMode="tel"
                placeholder={t("telefon")}
                onChange={(e) => setTelefon(e.target.value)}
              />
              <div
                style={{
                  width: "calc(100% - 45px)",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={() => {
                    setIndex(0);
                  }}
                  className="businessCreate"
                  style={{ marginRight: "5px", textTransform: "capitalize" }}
                >
                  {t("back")}
                </button>
                <button
                  onClick={() => {
                    if (
                      address &&
                      city &&
                      telefon &&
                      coordinates.lat &&
                      coordinates.lng
                    ) {
                      setIndex(2);
                      setWidthValue("80%");
                    }
                  }}
                  className="businessCreate"
                  style={
                    address &&
                    city &&
                    telefon &&
                    coordinates.lat &&
                    coordinates.lng
                      ? { marginLeft: "5px" }
                      : { opacity: 0.3, marginLeft: "5px" }
                  }
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          <div className="innerbusinesswrapperdiv">
            <div className="innerbusinesswrapperinputs">
              <input
                placeholder={t("price")}
                onChange={(e) => setEntranceprice(e.target.value)}
              />
              <input
                onChange={(e) => setOpening(e.target.value)}
                placeholder={t("time")}
                maxLength="13"
              />
              <div
                onClick={() => setTopicState(true)}
                style={{
                  height: "47px",
                  width: "calc(100% - 60px)",
                  background: "#1F1F1F",
                  margin: "5px 0",
                  padding: "0 10px",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "grey",
                    fontSize: "17px",
                    paddingLeft: "10px",
                  }}
                >
                  {t("topic")}{" "}
                  <ArrowForwardRoundedIcon
                    style={{
                      marginLeft: "5px",
                      fontSize: "18px",
                      color: "rgb(253, 127, 77)",
                    }}
                  />
                </span>
              </div>
              <input
                onChange={(e) => setBusinessLink(e.target.value)}
                placeholder={`${t("attach_link")} (optional)`}
                style={
                  businessLinkErr
                    ? { border: "1px solid orange", transition: ".5s all" }
                    : { border: "1px solid transparent", transition: ".5s all" }
                }
              />
              <textarea
                rows="5"
                placeholder={t("event_info") + " " + "(optional)"}
                onChange={(e) => setInfo(e.target.value)}
              />
              <div
                className="privatepartysection"
                style={{
                  width: "calc(90% - 10px)",
                  margin: "5px 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h3>{t("event_in_future")}</h3>
                <Switcher
                  onClick={() => {
                    vibrate("light");
                    setFuture((prev) => !prev);
                    setTimeout(() => {
                      setFutureTwo((prev) => !prev);
                    }, 100);
                  }}
                />
              </div>
              <div
                onClick={() => setFutureDrawer(true)}
                style={{
                  height: future ? "47px" : "0",
                  transform: futureTwo ? "scaleY(1)" : "scaleY(0)",
                  width: "calc(100% - 60px)",
                  transition: ".3s ease",
                  background: "#1F1F1F",
                  margin: future ? "5px 0" : "0",
                  padding: "0 10px",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "grey",
                    fontSize: "17px",
                    paddingLeft: "10px",
                  }}
                >
                  {t("plan_for_future")}{" "}
                  <ArrowForwardRoundedIcon
                    style={{
                      marginLeft: "5px",
                      fontSize: "18px",
                      color: "rgb(253, 127, 77)",
                    }}
                  />
                </span>
              </div>
              <div
                style={{
                  width: "calc(100% - 45px)",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={() => {
                    setIndex(1);
                  }}
                  className="businessCreate"
                  style={{ marginRight: "10px", textTransform: "capitalize" }}
                >
                  {t("back")}
                </button>
                {submitting ? (
                  <button
                    disabled
                    className="businessCreate"
                    style={
                      promotions === 0
                        ? { opacity: ".3" }
                        : clubname === ""
                        ? { opacity: ".3" }
                        : city === ""
                        ? { opacity: ".3" }
                        : address === ""
                        ? { opacity: ".3" }
                        : telefon === ""
                        ? { opacity: ".3" }
                        : data.length === 0
                        ? { opacity: ".3" }
                        : entranceprice === ""
                        ? { opacity: ".3" }
                        : opening === ""
                        ? { opacity: ".3" }
                        : businessImage === ""
                        ? { opacity: ".3" }
                        : coordinatesValue === false
                        ? { opacity: ".3" }
                        : { opacity: "1" }
                    }
                  >
                    <CircularProgress
                      style={{ color: "white", width: "25px", height: "25px" }}
                    />
                  </button>
                ) : (
                  <button
                    onClick={handleSubmitBusinessPost}
                    className="businessCreate"
                    style={
                      promotions === 0
                        ? { opacity: ".3" }
                        : clubname === ""
                        ? { opacity: ".3" }
                        : city === ""
                        ? { opacity: ".3" }
                        : address === ""
                        ? { opacity: ".3" }
                        : telefon === ""
                        ? { opacity: ".3" }
                        : data.length === 0
                        ? { opacity: ".3" }
                        : entranceprice === ""
                        ? { opacity: ".3" }
                        : opening === ""
                        ? { opacity: ".3" }
                        : businessImage === ""
                        ? { opacity: ".3" }
                        : coordinatesValue === false
                        ? { opacity: ".3" }
                        : { opacity: "1" }
                    }
                  >
                    {t("create_post")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </SwipeableViews>
      </div>
    </>
  );
}
