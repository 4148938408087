import React, { useEffect, useState } from "react";
import FirebaseContext from "../firebase/context";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useParams, useHistory } from "react-router-dom";
//Moment
import moment from "moment";
//Icons
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnRounded";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import AccessTimeRoundedIcon from "@material-ui/icons/AccessTimeRounded";
import Modal from "@material-ui/core/Modal";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { Backdrop, Drawer, Slide, SwipeableDrawer } from "@material-ui/core";
import Guest from "./Guest";
import Guests from "./Guests";
import Viewer from "./Viewer";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import { DeleteOutline } from "@material-ui/icons";
import AvatarComponentMini from "./AvatarComponentMini";
import { useTranslation } from "react-i18next";
import Name from "./Name";
import Userpage from "./Userpage";
import MorePeoplePage from "./MorePeoplePage";
//Autocomplete
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
//Progress
import CircularProgress from "@material-ui/core/CircularProgress";
import useCloseDrawers, { emitCloseDrawerEvent } from "../hooks/useDrawer";
import vibrate from "../util/feedback";
import BugInfo from "./BugInfo";

export default function PartyPost(props) {
  const { firebase, user } = React.useContext(FirebaseContext);
  const { t, i18n } = useTranslation();
  const _params = useParams();
  const params = props.params || _params;
  const history = useHistory();
  const [edit, setEdit] = React.useState(false);
  const [animation, setAnimation] = React.useState(false);
  const [bottom, setBottom] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [moreLocation, setMoreLocation] = React.useState(false);
  const [userPage, setUserPage] = useState({});
  const [userPageTwo, setUserPageTwo] = useState({});

  //Edit veriables
  const [partyName, setPartyName] = React.useState("");
  const [partyTime, setPartyTime] = React.useState("");
  const [info, setInfo] = React.useState("");
  const [partyLocation, setPartyLocation] = React.useState("");
  const [viewsDrawer, setViewsDrawer] = React.useState(false);
  const [morePeople, setMorePeople] = React.useState(false);
  const [clickanimation, setClickAnimation] = React.useState(false);
  const [locationCoordinates, setLocationCoordinates] = React.useState({
    lat: null,
    lng: null,
  });

  const handleSelectAddress = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setPartyLocation(value);
    setLocationCoordinates(latLng);
    setClickAnimation(false);
  };

  const [partyData, loading] = useDocumentData(
    firebase.db.doc(`parties/${params.id}`)
  );

  useEffect(() => {
    firebase.db.doc(`parties/${params.id}`).update({
      views: firebase.FieldValue.arrayUnion(firebase.auth.currentUser.uid),
    });
  }, [
    firebase.FieldValue,
    firebase.auth.currentUser.uid,
    firebase.db,
    params.id,
  ]);

  useEffect(() => {
    setPartyName(partyData?.partyname);
    setPartyTime(partyData?.time);
    setInfo(partyData?.eventinfo);
    setPartyLocation(partyData?.partylocation);
  }, [partyData]);

  useEffect(() => {
    if (!loading && !partyData) {
      history.push("/Home");
      emitCloseDrawerEvent();
    }
  }, [history, loading, partyData]);

  const [more, setMore] = React.useState(false);

  function ScrollFixer() {
    //document.scrollingElement.scrollTo(0, 0);
  }

  function handleUpdate() {
    firebase.db.doc(`parties/${params.id}`).update({
      partyname: partyName,
      partylocation: partyLocation,
      time: partyTime,
      eventinfo: info,
      lat: locationCoordinates.lat,
      long: locationCoordinates.lng,
    });
    setEdit(false);
    setClickAnimation(false);
  }

  function leaveParty() {
    vibrate("light");
    firebase.db.doc(`parties/${params.id}`).update({
      guests: firebase.FieldValue.arrayRemove(firebase.auth.currentUser.uid),
    });

    firebase.db.doc(`users/${partyData.id}`).update({
      declines: firebase.FieldValue.arrayUnion(firebase.auth.currentUser.uid),
    });
  }

  const [deletemodal, setDeletemodal] = useState(false);

  function handleDelete(uid) {
    setDeletemodal(false);
    setOpenModal(true);
    const postRef = firebase.db.doc(`parties/${uid}`);

    setTimeout(() => {
      postRef
        .delete()
        .catch((err) => {
          console.error("Error deleting document:", err);
        })
        .then(() => {
          props.onClose?.();
        });
    }, 500);
  }

  function removeRequest() {
    vibrate("light");
    firebase.db.doc(`parties/${params.id}`).update({
      guestRequests: firebase.FieldValue.arrayRemove(
        firebase.auth.currentUser.uid
      ),
    });
    firebase.db.doc(`users/${partyData?.id}`).update({
      guestRequestsNotifications: firebase.FieldValue.arrayRemove(
        firebase.auth.currentUser.uid
      ),
    });
  }

  function handleHopon() {
    vibrate("light");
    firebase.db.doc(`parties/${params.id}`).update({
      guestRequests: firebase.FieldValue.arrayUnion(
        firebase.auth.currentUser.uid
      ),
    });
    firebase.db.doc(`users/${partyData?.id}`).update({
      guestRequestsNotifications: firebase.FieldValue.arrayUnion(
        firebase.auth.currentUser.uid
      ),
    });
  }

  useCloseDrawers({
    drawers: [
      () => setUserPage((prev) => ({ ...prev, open: false })),
      () => setUserPageTwo((prev) => ({ ...prev, open: false })),
      () => setMorePeople(false),
      () => setBottom(false),
      () => setViewsDrawer(false),
    ],
  });

  if (!partyData) return <div className="DrivePostWrapper" />;

  return (
    <>
      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 199 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={userPage.open}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setUserPage((prev) => ({ ...prev, open: false }))}
      >
        <Userpage
          params={{ UserId: userPage.id }}
          onClose={() => setUserPage((prev) => ({ ...prev, open: false }))}
        ></Userpage>
      </SwipeableDrawer>

      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 200 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={userPageTwo.open}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setUserPageTwo((prev) => ({ ...prev, open: false }))}
      >
        <Userpage
          params={{ UserId: userPageTwo.id }}
          noPaddingBottom
          onClose={() => setUserPageTwo((prev) => ({ ...prev, open: false }))}
        ></Userpage>
      </SwipeableDrawer>

      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 200 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={morePeople}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setMorePeople(false)}
      >
        <MorePeoplePage
          data={{ partyData }}
          partyId={params.id}
          onClose={() => setMorePeople(false)}
        ></MorePeoplePage>
      </SwipeableDrawer>

      <div className="DrivePostWrapper">
        <Modal
          open={deletemodal}
          onClose={() => setDeletemodal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Slide style={{ outline: "none" }} direction="up" in={deletemodal}>
            <div className="deletemodal">
              <img src={partyData.image} alt="deleteimage" />
              <p> {t("delete_event")} ? </p>
              <button
                onClick={() => {
                  vibrate("light");
                  handleDelete(params.id);
                }}
              >
                {t("delete_event_button")}
              </button>
              <p className="stopdelete" onClick={() => setDeletemodal(false)}>
                {t("cancel")}
              </p>
            </div>
          </Slide>
        </Modal>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="modalwarning">
            <p> {t("deleting")} </p>
          </div>
        </Modal>
        <Drawer
          style={{ zIndex: 200 }}
          anchor="bottom"
          PaperProps={{
            elevation: 0,
            style: {
              borderRadius: "0",
              backgroundColor: "black",
            },
          }}
          open={edit}
          onClose={() => setEdit(false)}
        >
          <div className="editdrawer">
            <div className="addheader" style={{ zIndex: "12" }}>
              <div className="headtitleparty">
                <div
                  onClick={() => {
                    setEdit(false);
                    setAnimation(false);
                    setClickAnimation(false);
                  }}
                  className="backlink"
                >
                  <ArrowBackIosRoundedIcon style={{ color: "#FD7F4D" }} />
                </div>
                <h2>
                  <EditIcon />
                </h2>
                {partyName === "" ? (
                  <button
                    disabled
                    className="poster buttonanimation"
                    style={{ color: "rgb(123, 127, 133)" }}
                  >
                    {t("edit")}
                  </button>
                ) : partyLocation === "" ? (
                  <button
                    disabled
                    className="poster buttonanimation"
                    style={{ color: "rgb(123, 127, 133)" }}
                  >
                    {t("edit")}
                  </button>
                ) : partyTime === "" ? (
                  <button
                    disabled
                    className="poster buttonanimation"
                    style={{ color: "rgb(123, 127, 133)" }}
                  >
                    {t("edit")}
                  </button>
                ) : (
                  <button
                    onClick={handleUpdate}
                    className="poster buttonanimation"
                  >
                    {t("edit")}
                  </button>
                )}
              </div>
            </div>
            <div className="editholder">
              <input
                className={animation ? "scaleanimationinput" : ""}
                style={{ animationDelay: "0s" }}
                onChange={(e) => setPartyName(e.target.value)}
                value={partyName}
                placeholder="Event"
              />

              <div
                style={
                  clickanimation
                    ? {
                        height: "100vh",
                        width: "100vw",
                        position: "absolute",
                        left: "0",
                        top: "0",
                        zIndex: "10",
                        transition: ".8s",
                        opacity: "1",
                        background: "black",
                        userSelect: "initial",
                        marginTop: "3.7rem",
                      }
                    : {
                        height: "100vh",
                        width: "100vw",
                        position: "absolute",
                        left: "0",
                        top: "0",
                        zIndex: "-1",
                        transition: ".8s",
                        opacity: "0",
                        background: "black",
                        userSelect: "none",
                      }
                }
              >
                <span
                  style={{
                    position: "absolute",
                    top: "26px",
                    right: "18px",
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      position: "relative",
                      height: "35px",
                      width: "39px",
                    }}
                    onClick={() => {
                      setClickAnimation(false);
                    }}
                  >
                    <ClearRoundedIcon style={{ fontSize: "35px" }} />
                  </span>
                </span>
              </div>

              <PlacesAutocomplete
                value={partyLocation}
                onChange={(e) => {
                  setClickAnimation(true);
                  setPartyLocation(e);
                }}
                onSelect={handleSelectAddress}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div
                    style={
                      clickanimation
                        ? {
                            width: "calc(100% - 30px)",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: "11",
                            marginTop: "calc(-55px - env(safe-area-inset-top))",
                            transition: ".5s",
                          }
                        : {
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            transition: ".5s",
                          }
                    }
                  >
                    <input
                      className={animation ? "scaleanimationinput" : ""}
                      onChange={(e) => {
                        setPartyLocation(e.target.value);
                        setClickAnimation(true);
                      }}
                      placeholder={t("address")}
                      value={partyLocation}
                      onClick={() => setClickAnimation(true)}
                      {...getInputProps({
                        placeholder: t("address"),
                      })}
                      style={
                        clickanimation
                          ? {
                              width: "calc(100% - 30px)",
                              marginLeft: "-10px",
                            }
                          : { animationDelay: ".2s" }
                      }
                    />
                    <div
                      className="suggestions driver"
                      style={
                        partyLocation === ""
                          ? { display: "none" }
                          : {
                              display: "flex",
                              flexDirection: "column",
                              width: "calc(100% + 40px)",
                              marginLeft: "40px",
                            }
                      }
                    >
                      {clickanimation && (
                        <>
                          {loading ? (
                            <>
                              <BugInfo />
                              <div className="suggestionloader">
                                <CircularProgress style={{ color: "white" }} />
                              </div>
                            </>
                          ) : null}
                        </>
                      )}
                      {suggestions.map((suggestion) => {
                        const style = {
                          backgroundColor: suggestion.active
                            ? "#efefef"
                            : "#ffff",
                          cursor: suggestion.active ? "pointer" : "initial",
                        };
                        return (
                          <div
                            key={suggestion.description}
                            {...getSuggestionItemProps(suggestion, { style })}
                            style={{ display: "flex", alignItems: "center" }}
                            className="suggestionsingle"
                          >
                            <p className="suggestiontext">
                              {suggestion.description}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>

              <input
                className={animation ? "scaleanimationinput" : ""}
                style={{ animationDelay: "0.2s" }}
                value={partyTime}
                placeholder={t("time")}
                maxLength="13"
                onChange={(e) => setPartyTime(e.target.value)}
              />
              <textarea
                className={animation ? "scaleanimationinput" : ""}
                style={{ animationDelay: "0.3s" }}
                value={info}
                placeholder="Event Information (optional)"
                onChange={(e) => setInfo(e.target.value)}
              />
              <div className="morepeoplewrapper">
                <div
                  className={animation ? "morepeople" : ""}
                  style={{ animationDelay: ".9s" }}
                  onClick={() => setMorePeople(true)}
                >
                  <p>{t("add_more_people")}</p>
                  <div
                    className="partypostmembers party"
                    style={{ marginLeft: "20px" }}
                  >
                    {partyData &&
                      partyData.guests?.slice(0, 3).map((seat) => {
                        return <Guests key={seat} uid={seat} />;
                      })}
                  </div>
                  <ChevronRightRoundedIcon
                    style={{
                      color: "white",
                      position: "absolute",
                      right: "5px",
                      fontSize: "30px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Drawer>
        <div className="overflowtop">
          <ArrowBackIosRoundedIcon
            onClick={() => {
              props.onClose?.();
            }}
            style={{ fontSize: "25px" }}
            className="postheaderbackicon"
          />
          {partyData.id === firebase.auth.currentUser.uid ? (
            <>
              <button
                onClick={() => {
                  ScrollFixer();
                  setEdit(true);
                  setAnimation(true);
                }}
                className="posteditbutton"
                style={{ right: "70px" }}
              >
                <EditIcon style={{ fontSize: "20px", marginLeft: "4px" }} />
                <span className="editspan">Edit</span>
              </button>
              <button
                onClick={() => {
                  setViewsDrawer(true);
                }}
                className="postheaderbackicon"
                style={{
                  right: "16px",
                  left: "initial",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                }}
              >
                <VisibilityRoundedIcon style={{ fontSize: "20px" }} />
              </button>
            </>
          ) : (
            ""
          )}
        </div>
        <div
          className="DrivePostHeader Event"
          style={{
            backgroundImage: `url(${partyData.image})`,
            backgroundPosition: "center",
          }}
        ></div>
        <div className="overflow"></div>
        <div
          style={
            edit
              ? { paddingBottom: "0" }
              : props.noPaddingBottom
              ? { paddingBottom: "20px" }
              : { paddingBottom: "100px" }
          }
          className="DrivePostBody"
        >
          {partyData.private && (
            <p className="privatestatus"> {t("private")} </p>
          )}
          <div className="DrivePostBodyMain">
            <h1>{moment(partyData.date.toDate()).format("DD")}</h1>
            <h2>{moment(partyData.date.toDate()).format("MMMM")}</h2>
            <h3> {partyData.partyname} </h3>
            <div
              onClick={() => setUserPage({ id: partyData?.id, open: true })}
              className="eventhostedby"
            >
              <span style={{ border: "1px solid white", borderRadius: "100%" }}>
                <AvatarComponentMini driverid={partyData.id} />
              </span>
              <p>
                {/* {" "}
                {t("event_hosted_by")}:  */}
                <span className="dotspan">•</span> {partyData.host}
              </p>
            </div>
          </div>
          <div className="divertwo" style={{ margin: "5px 0" }}></div>
          <div className="DrivePostInfo">
            <div
              className="postinfo"
              style={{
                margin: "5px 0",
                width: "100%",
                minHeight: "48px",
                height: "auto",
              }}
            >
              <h2
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "18px",
                  margin: "5px 0",
                }}
              >
                <LocationOnRoundedIcon
                  style={{
                    fontSize: "28px",
                    marginLeft: "10px",
                  }}
                />{" "}
                <span style={{ marginLeft: "0", display: "flex" }}>
                  {partyData?.partylocation?.length >= 29 ? (
                    moreLocation ? (
                      <span
                        onClick={() =>
                          setMoreLocation((prevState) => !prevState)
                        }
                      >
                        {partyData?.partylocation}
                      </span>
                    ) : (
                      <span
                        onClick={() =>
                          setMoreLocation((prevState) => !prevState)
                        }
                      >
                        {`${partyData?.partylocation.substring(0, [28])}...`}
                      </span>
                    )
                  ) : (
                    <span> {partyData?.partylocation} </span>
                  )}
                </span>
              </h2>
            </div>
          </div>

          <div className="DrivePostInfo">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                fontSize: "16px",
                color: "#A5A5A5",
              }}
            >
              <p
                style={{
                  padding: "10px 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  fontSize: "18px",
                  background: "#1f1f1f",
                  width: "50%",
                  margin: "5px 0",
                  marginRight: "5px",
                  borderRadius: "10px",
                }}
              >
                <DateRangeIcon
                  style={{ marginRight: "10px", paddingLeft: "10px" }}
                />{" "}
                {moment(partyData.date.toDate()).format("DD.MM.YYYY")}{" "}
              </p>
              <p
                style={{
                  padding: "10px 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  fontSize: "18px",
                  background: "#1f1f1f",
                  width: "50%",
                  margin: "5px 0",
                  marginLeft: "5px",
                  borderRadius: "10px",
                }}
              >
                <AccessTimeRoundedIcon
                  style={{ marginRight: "10px", paddingLeft: "10px" }}
                />{" "}
                {partyData.time}
              </p>
            </div>
          </div>
          {partyData.eventinfo && (
            <>
              <div className="divertwo" style={{ margin: "5px 0" }}></div>
              <div className="DrivePostEvent Info">
                {partyData.eventinfo.length >= 57 ? (
                  <p style={{ margin: "5px 0" }}>
                    {!more ? (
                      <span style={{ color: "white" }}>
                        {partyData.eventinfo.substring(0, [57])}{" "}
                        <span
                          style={{ color: "#a5a5a5" }}
                          onClick={() => setMore(true)}
                        >
                          ...{t("more")}
                        </span>
                      </span>
                    ) : (
                      <span style={{ color: "white" }}>
                        {partyData.eventinfo}
                      </span>
                    )}
                  </p>
                ) : (
                  <p style={{ margin: "5px 0" }}>
                    <span style={{ color: "white" }}>
                      {partyData.eventinfo}
                    </span>
                  </p>
                )}
              </div>
            </>
          )}

          <div className="divertwo" style={{ margin: "15px 0" }}></div>

          {/* Guestlist */}

          {partyData.guests.length === 0 ? (
            ""
          ) : partyData.guests.length === 2 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                marginLeft: "10px",
              }}
              onClick={() => setBottom((prevState) => !prevState)}
            >
              <span style={{ marginRight: "5px" }}>
                {partyData &&
                  partyData.guests?.slice(0, 1).map((seat) => {
                    return <Guests key={seat} uid={seat} />;
                  })}
              </span>
              <p className="partyguestlistlabel second">
                {partyData.guests?.slice(0, 1).map((name) => {
                  return (
                    <span style={{ fontWeight: "500" }}>
                      {" "}
                      <Name name={name} />{" "}
                    </span>
                  );
                })}{" "}
                {t("and")}{" "}
                <span style={{ fontWeight: "500" }}>
                  {partyData && partyData.guests.length - 1}
                </span>{" "}
                {t("another")}
              </p>
            </div>
          ) : partyData.guests.length === 1 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                marginLeft: "10px",
              }}
              onClick={() => setBottom((prevState) => !prevState)}
            >
              <span style={{ marginRight: "5px" }}>
                {partyData &&
                  partyData.guests?.slice(0, 1).map((seat) => {
                    return <Guests key={seat} uid={seat} />;
                  })}
              </span>
              <p className="partyguestlistlabel second">
                {partyData.guests?.slice(0, 1).map((name) => {
                  return (
                    <span style={{ fontWeight: "500" }}>
                      {" "}
                      <Name name={name} />{" "}
                    </span>
                  );
                })}{" "}
                {t("joining_single")}
              </p>
            </div>
          ) : (
            <div
              className="partylabelguestlist"
              onClick={() => setBottom((prevState) => !prevState)}
            >
              <div className="completepartymemberdiv party">
                <div className="partypostmembers party">
                  {partyData &&
                    partyData.guests?.slice(0, 3).map((seat) => {
                      return <Guests key={seat} uid={seat} />;
                    })}
                  {partyData.guests.length - 3 === 0 ? (
                    ""
                  ) : (
                    <span className="partymembercount">
                      {" "}
                      +{partyData.guests.length - 3}
                    </span>
                  )}
                </div>
              </div>
              <p className="partyguestlistlabel">
                {partyData.guests?.slice(0, 1).map((name) => {
                  return (
                    <span style={{ fontWeight: "500" }}>
                      {" "}
                      <Name name={name} />{" "}
                    </span>
                  );
                })}{" "}
                {t("and")}{" "}
                <span style={{ fontWeight: "500" }}>
                  {partyData && partyData.guests.length - 1}
                </span>{" "}
                {t("joining")}
              </p>
            </div>
          )}
          {partyData.guests?.includes(firebase.auth.currentUser.uid) ? (
            <div onClick={leaveParty} className="activedriver">
              {t("request_event_leave")}
            </div>
          ) : partyData.guestRequests?.includes(
              firebase.auth.currentUser.uid
            ) ? (
            <div onClick={removeRequest} className="activedriver">
              {t("request_to_hopon_remove_request")}
            </div>
          ) : partyData.id === firebase.auth.currentUser.uid ? (
            <div
              onClick={() => setDeletemodal(true)}
              className="activedriver"
              style={{ zIndex: "1" }}
            >
              <DeleteOutline style={{ fontSize: "28px" }} />
            </div>
          ) : (
            <div onClick={handleHopon} className="activedriver">
              {t("request")}
            </div>
          )}
        </div>{" "}
        <SwipeableDrawer
          style={{ zIndex: 200 }}
          PaperProps={{
            elevation: 0,
            style: {
              borderRadius: "20px 20px 0 0",
              background: "#262626",
              marginTop: "env(safe-area-inset-top)",
            },
          }}
          anchor={"bottom"}
          onClose={() => setBottom(false)}
          open={bottom}
        >
          <div className="PostListGuest">
            <div id="swipareadiv" className="GuestListHead">
              <div className="swipelinediv">
                <span className="swipeline"></span>
              </div>
              <h1> {t("guest_list")} </h1>
            </div>
            <div
              style={{
                flex: "0 1 auto",
                overflowY: "scroll",
                width: "100%",
                margin: "0",
              }}
              className="postinfo seats"
              onTouchMove={(e) => {
                e.stopPropagation();
              }}
            >
              {partyData &&
                partyData.guests?.map((guest) => {
                  return (
                    <Guest
                      admin={partyData.id}
                      params={params}
                      key={guest}
                      uid={guest}
                      onClick={() => setUserPageTwo({ id: guest, open: true })}
                    />
                  );
                })}
            </div>
          </div>
        </SwipeableDrawer>
        <SwipeableDrawer
          style={{ zIndex: 200 }}
          PaperProps={{
            elevation: 0,
            style: {
              borderRadius: "20px 20px 0 0",
              background: "#262626",
              marginTop: "env(safe-area-inset-top)",
            },
          }}
          anchor={"bottom"}
          onClose={() => setViewsDrawer(false)}
          open={viewsDrawer}
        >
          <div className="PostListGuest">
            <div id="swipareadiv" className="GuestListHead">
              <div className="swipelinediv">
                <span className="swipeline"></span>
              </div>
              <div className="viewsheader">
                <p>
                  <VisibilityRoundedIcon
                    style={{
                      color: "white",
                      fontSize: "30px",
                      marginRight: "5px",
                    }}
                  />{" "}
                  {partyData.views?.length - 1 || 0}
                </p>
                <ClearRoundedIcon
                  style={{ color: "white", fontSize: "30px" }}
                  onClick={() => setViewsDrawer(false)}
                />
              </div>
            </div>
            {partyData.views?.length - 1 === 0 ? (
              <div className="no_views">
                <p> {t("no_views")} </p>
              </div>
            ) : (
              <div
                style={{
                  flex: "0 1 auto",
                  overflowY: "scroll",
                  width: "100%",
                  margin: "0",
                }}
                className="postinfo seats"
                onTouchMove={(e) => {
                  e.stopPropagation();
                }}
              >
                {partyData &&
                  partyData.views?.map((guest) => {
                    return (
                      <Viewer
                        admin={partyData.id}
                        params={params}
                        key={guest}
                        uid={guest}
                        disabledButtons
                        onClick={() =>
                          setUserPageTwo({ id: guest, open: true })
                        }
                      />
                    );
                  })}
              </div>
            )}
          </div>
        </SwipeableDrawer>
      </div>
    </>
  );
}
