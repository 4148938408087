import React from "react";
import { useDocumentData, useCollection } from "react-firebase-hooks/firestore";
import FirebaseContext from "../firebase/context";
import RemoveIcon from "@material-ui/icons/Remove";
import Snapchatcustom from "../images/snapchatcustomtwo.svg";
import Snapchatcustomtwogrey from "../images/snapchatcustomtwogrey.svg";
import InstagramIcon from "@material-ui/icons/Instagram";
import Drawer from "@material-ui/core/Drawer";
import AvatarComponentSeat from "./AvatarComponentSeat";
//i18n
import { useTranslation } from "react-i18next";

export default function Friend({
  uid,
  filter = "",
  index,
  indexdelay,
  onClick,
}) {
  const { t } = useTranslation();
  const { firebase, storage, user } = React.useContext(FirebaseContext);
  const [userData] = useDocumentData(firebase.db.doc(`users/${uid}`));

  const friendInstagram = userData && userData.instagram;
  const friendSnapchat = userData && userData.snapchat;

  //Get data
  const date = React.useMemo(() => {
    const result = new Date();
    result.setDate(result.getDate() - 1);
    return result;
  }, []);

  const [driversData] = useCollection(
    firebase.db.collection("drivers").where("date", ">", date)
  );

  const drivestatus =
    driversData &&
    driversData.docs.filter((driver) => {
      if (driver.data().id.includes(uid)) {
        return driver;
      }
    });

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function RemoveRequest() {
    firebase.db
      .doc(`users/${user && user.uid}`)
      .update({ friendRequests: firebase.FieldValue.arrayRemove(uid) });
  }

  function AcceptRequest() {
    firebase.db
      .doc(`users/${user && user.uid}`)
      .update({ friendRequests: firebase.FieldValue.arrayRemove(uid) })
      .then(() => {
        firebase.db.doc(`users/${user && user.uid}`).update({
          friends: firebase.FieldValue.arrayUnion(
            firebase.auth.currentUser.uid
          ),
        });
      });
  }

  function handleFriendRemove(usercardid) {
    handleClose();
    firebase.db
      .doc(`users/${usercardid}`)
      .update({
        friends: firebase.FieldValue.arrayRemove(firebase.auth.currentUser.uid),
      })
      .then(() => {
        firebase.db
          .doc(`users/${user && user.uid}`)
          .update({ friends: firebase.FieldValue.arrayRemove(usercardid) });
      });
  }

  const [openFriend, setOpenFriend] = React.useState(false);

  React.useEffect(() => {
    setTimeout(
      () =>
        setOpenFriend(
          userData &&
            userData.username &&
            userData.username.toLowerCase().includes(filter.toLowerCase())
        ),
      100
    );
  }, [userData, filter]);

  if (
    !(
      userData &&
      userData.username &&
      userData.username.toLowerCase().includes(filter.toLowerCase())
    )
  )
    return null;

  return (
    <div className="posts" onClick={onClick}>
      <div
        className={openFriend ? "drivepost" : "drivepost friend"}
        style={{
          transitionDelay: `${(index - indexdelay) * 50}ms`,
        }}
      >
        <div style={{ position: "relative" }}>
          <AvatarComponentSeat driverid={uid} />
        </div>
        <p>
          {" "}
          {userData.username && userData.username.length > 11
            ? `${userData.username.substring(0, [10])}...`
            : userData.username}
        </p>
        <div style={{ fontSize: "12px", color: "grey" }}>
          {friendInstagram ? (
            <a
              style={{ textDecoration: "none", color: "grey" }}
              href={friendInstagram}
            >
              <InstagramIcon
                style={{
                  fontSize: "22px",
                  color: "#FE4040",
                  margin: "0 2px",
                }}
              />
            </a>
          ) : (
            <InstagramIcon
              style={{
                fontSize: "22px",
                color: "#8e8e8e",
                margin: "0 2px",
              }}
            />
          )}
          {friendSnapchat ? (
            <a
              style={{ textDecoration: "none", color: "grey" }}
              href={friendSnapchat}
            >
              <img
                alt="snapchat"
                src={Snapchatcustom}
                style={{ width: "21px", margin: "0 2px" }}
              />
            </a>
          ) : (
            <img
              alt="snapchat"
              src={Snapchatcustomtwogrey}
              style={{ width: "21px", margin: "0 2px" }}
            />
          )}
        </div>
        <button
          onClick={handleOpen}
          className="remove"
          style={{ display: "none" }}
        >
          {" "}
          <RemoveIcon style={{ width: "1.5rem", height: "1.5rem" }} />{" "}
        </button>
      </div>
      <Drawer
        anchor="bottom"
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            backgroundColor: "transparent",
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className="alert">
          <div className="alertavatar">
            <AvatarComponentSeat driverid={uid} />
          </div>
          <h1>{t("remove_friend_title")}</h1>
          <p>
            {t("remove_message_start")}{" "}
            <span style={{ fontWeight: "800" }}> {userData.username}</span>{" "}
            {t("remove_message_end")}{" "}
          </p>
          <div
            onClick={() => handleFriendRemove(uid)}
            style={{ color: "#de4955" }}
            className="alertbutton"
          >
            {t("remove_big")}
          </div>
        </div>
        <div onClick={handleClose} className="alertbutton single">
          {t("cancel")}
        </div>
      </Drawer>
    </div>
  );
}
