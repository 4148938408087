import { Skeleton } from "@material-ui/lab";
import React from "react";

export default function PromotionSkeleton() {
  return (
    <div className="partypostslidersingle">
      <Skeleton
        variant="text"
        width="40%"
        animation="wave"
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
        }}
      />
      <Skeleton
        variant="text"
        width="55%"
        animation="wave"
        style={{
          position: "absolute",
          bottom: "40px",
          left: "10px",
        }}
      />
      <Skeleton
        variant="text"
        width="35%"
        animation="wave"
        style={{
          position: "absolute",
          bottom: "10px",
          left: "10px",
        }}
      />
      <Skeleton
        variant="rect"
        width="75px"
        height="40px"
        animation="wave"
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          borderRadius: "20px",
        }}
      />
    </div>
  );
}
