import React, { useState } from "react";
import FirebaseContext from "../firebase/context";
import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useDocumentData } from "react-firebase-hooks/firestore";

export default function SpottetBug(props) {
  const { firebase, user } = React.useContext(FirebaseContext);
  const { t } = useTranslation();
  const [report, setReport] = useState("");
  const [success, setSuccess] = useState(false);

  const [userData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );

  async function sendReport() {
    if (report.replace(/ /g, "") !== "") {
      await firebase.db.collection("app-reports").add({
        user: userData?.username,
        id: user?.uid,
        report: report,
        date: new Date(),
      });
      setSuccess(true);

      setTimeout(() => {
        props.onClose();
      }, 1000);
    } else {
      console.log("invalid!");
    }
  }

  return (
    <div className="changeusernamewrapper">
      <div className="businesshead">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            borderBottom: "1px solid rgb(48 47 47)",
          }}
        >
          <ArrowBackIosIcon
            onClick={props.onClose}
            style={{
              color: "#FD7F4D",
              position: "absolute",
              left: "26px",
              top: "50%",
              transform: "translate(0%, -50%)",
              padding: "10px",
              marginLeft: "-10px",
            }}
          />
          <h1> {t("bug_title")} </h1>
        </div>
      </div>
      <div className="changeusernamemain">
        <h2>{t("bug_maintitle")}</h2>
        <textarea
          onChange={(e) => setReport(e.target.value)}
          placeholder={t("bug_placeholder")}
          rows="5"
        />
        {success && <p style={{ color: "#85d563" }}> {t("report_success")} </p>}
        <div className="innerinformationbug">
          <p>{t("report_text")}</p>
          <button
            style={
              report.replace(/ /g, "") !== ""
                ? { background: "linear-gradient(90deg, #fe4040, #fe7640)" }
                : {}
            }
            onClick={report === "" ? null : sendReport}
          >
            {t("submit")}
          </button>
        </div>
      </div>
    </div>
  );
}
