import React from "react";
import { useTranslation } from "react-i18next";
import AvatarComponentMini from "./AvatarComponentMini";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import Guests from "./Guests";
import Name from "./Name";

export default function PrivateEventDisplay({ party, onClick }) {
  const { t } = useTranslation();

  return (
    <div className="privateEventDisplayWrapper" onClick={onClick}>
      <div
        className="privateEventDisplayBody"
        style={{
          backgroundImage: `url(${party?.data()?.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="privateEventDisplayOverlay">
          <div className="privatePartyMembersWrapper">
            {party?.data()?.guests.length === 0 ? (
              ""
            ) : party?.data()?.guests.length === 2 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  marginLeft: "20px",
                }}
              >
                <span style={{ marginRight: "5px" }}>
                  {party
                    ?.data()
                    ?.guests?.slice(0, 1)
                    .map((seat) => {
                      return <Guests key={seat} uid={seat} />;
                    })}
                </span>
                <p
                  className="partyguestlistlabel second"
                  style={{ maxWidth: "100%", width: "auto", fontSize: "14px" }}
                >
                  {party
                    ?.data()
                    ?.guests?.slice(0, 1)
                    .map((name) => {
                      return (
                        <span style={{ fontWeight: "500" }}>
                          {" "}
                          <Name name={name} />{" "}
                        </span>
                      );
                    })}{" "}
                  {t("and")}{" "}
                  <span style={{ fontWeight: "500" }}>
                    {party?.data()?.guests.length - 1}
                  </span>{" "}
                  {t("another")}
                </p>
              </div>
            ) : party?.data()?.guests.length === 1 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  marginLeft: "20px",
                }}
              >
                <span style={{ marginRight: "5px" }}>
                  {party
                    ?.data()
                    ?.guests?.slice(0, 1)
                    .map((seat) => {
                      return <Guests key={seat} uid={seat} />;
                    })}
                </span>
                <p
                  className="partyguestlistlabel second"
                  style={{ maxWidth: "100%", width: "auto", fontSize: "14px" }}
                >
                  {party
                    ?.data()
                    ?.guests?.slice(0, 1)
                    .map((name) => {
                      return (
                        <span style={{ fontWeight: "500" }}>
                          {" "}
                          <Name name={name} />{" "}
                        </span>
                      );
                    })}{" "}
                  {t("joining_single")}
                </p>
              </div>
            ) : (
              <div className="partylabelguestlist">
                <div
                  style={{
                    margin: "20px 10px",
                    marginLeft: "20px",
                    alignItems: "flex-start",
                    width: "auto",
                  }}
                  className="completepartymemberdiv"
                >
                  <div className="partypostmembers party">
                    {party
                      ?.data()
                      ?.guests?.slice(0, 3)
                      .map((seat) => {
                        return <Guests key={seat} uid={seat} />;
                      })}
                    {party?.data()?.guests.length - 3 === 0 ? (
                      ""
                    ) : (
                      <span className="partymembercount">
                        {" "}
                        +{party?.data()?.guests.length - 3}
                      </span>
                    )}
                  </div>
                </div>
                <p
                  className="partyguestlistlabel"
                  style={{ maxWidth: "100%", width: "auto", fontSize: "14px" }}
                >
                  {party
                    ?.data()
                    ?.guests?.slice(0, 1)
                    .map((name) => {
                      return (
                        <span style={{ fontWeight: "500" }}>
                          {" "}
                          <Name name={name} />{" "}
                        </span>
                      );
                    })}{" "}
                  {t("and")}{" "}
                  <span style={{ fontWeight: "500" }}>
                    {party?.data()?.guests.length - 1}
                  </span>{" "}
                  {t("joining")}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="privateEventInnerInfo">
        <h2>
          {party?.data()?.partyname?.length > 20
            ? `${party?.data()?.partyname?.substring(0, [20])}...`
            : party?.data()?.partyname}
        </h2>
        <p>
          <AvatarComponentMini size="23px" driverid={party?.data()?.id} />
          <span style={{ marginLeft: "5px" }}>
            {t("by")}{" "}
            {party?.data()?.host?.length > 35
              ? `${party?.data()?.host?.substring(0, [35])}...`
              : party?.data()?.host}
          </span>
        </p>
        <div className="privateEventInnerButton">
          <ArrowForwardRoundedIcon
            style={{ fontSize: "25px", color: "white" }}
          />
        </div>
      </div>
    </div>
  );
}

{
  /* <span className="locationspan">
{t("location")}: <br />
{party.data().partylocation.length > 15
  ? `${party.data().partylocation.substring(0, [15])}...`
  : party.data().partylocation}{" "}
</span> */
}
