var firebaseConfig = {
  apiKey: "AIzaSyDTMBQt_sQuFpRMhtlzbSo1lGZdWJjOUMY",
  authDomain: "exude-hopon.firebaseapp.com",
  projectId: "exude-hopon",
  storageBucket: "exude-hopon.appspot.com",
  messagingSenderId: "888429059676",
  appId: "1:888429059676:web:a18ece77c45ca729771099",
  measurementId: "G-4NR9JMQ7DX",
};

export default firebaseConfig;
