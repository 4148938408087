import React, { useRef } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import Avatar from "@material-ui/core/Avatar";
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
import FirebaseContext from "../firebase/context";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import { useTranslation } from "react-i18next";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import NavigateNextRoundedIcon from "@material-ui/icons/NavigateNextRounded";

export default function FriendRequest({ uid, party, onAccept = () => {} }) {
  const history = useHistory();
  const { firebase, storage, user } = React.useContext(FirebaseContext);
  const { t } = useTranslation();
  const [userData = {}] = useDocumentData(firebase.db.doc(`users/${uid}`));
  const [partyData = {}] = useDocumentData(firebase.db.doc(`parties/${party}`));
  const element = useRef();

  function RemoveInvite() {
    element.current.classList.toggle("animation");

    setTimeout(() => {
      firebase.db.doc(`parties/${party}`).update({
        guests: firebase.FieldValue.arrayRemove(firebase.auth.currentUser.uid),
      });
      firebase.db.doc(`users/${uid}`).update({
        declines: firebase.FieldValue.arrayUnion(firebase.auth.currentUser.uid),
      });

      element.current.classList.toggle("animation");
    }, 400);
  }

  return (
    <div className="singlenotification" onClick={onAccept} ref={element}>
      <Avatar
        alt="Profile"
        src={userData.imgRef}
        style={{ height: "3rem", width: "3rem" }}
      />{" "}
      <p style={{ maxWidth: "9rem" }}>
        {" "}
        {userData.username && userData.username.length > 11
          ? `${userData.username.substring(0, [10])}...`
          : userData.username}
        <br /> <span>{t("party_guest")}</span>{" "}
      </p>
      <div className="invitebuttondiv">
        <div className="notificationbuttons">
          {/* <button
            onClick={onAccept}
            className="accept"
            style={{ width: "60px", marginRight: "10px" }}
          >
            <NavigateNextRoundedIcon style={{ fontSize: "20px" }} />
          </button>
          <button
            onClick={RemoveInvite}
            className="accept"
            style={{ width: "60px", background: "#fe4040", marginRight: "0" }}
          >
            <ClearRoundedIcon style={{ fontSize: "18px" }} />
          </button> */}
          <div
            className="notificationimage"
            style={{
              backgroundImage: `url(${partyData.image})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
