import React, { useRef } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import Avatar from "@material-ui/core/Avatar";
import FirebaseContext from "../firebase/context";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import { useTranslation } from "react-i18next";

export default function Kicked({ uid, onClick }) {
  const { firebase, storage, user } = React.useContext(FirebaseContext);
  const { t } = useTranslation();
  const [userData = {}] = useDocumentData(firebase.db.doc(`users/${uid}`));
  const element = useRef();

  function RemoveRequest() {
    element.current.classList.toggle("animation");

    setTimeout(() => {
      firebase.db
        .doc(`users/${user && user.uid}`)
        .update({ accepted: firebase.FieldValue.arrayRemove(uid) });

      element.current.classList.toggle("animation");
    }, 400);
  }

  return (
    <div className="singlenotification" ref={element}>
      <Avatar
        alt="Profile"
        src={userData.imgRef}
        style={{ height: "3rem", width: "3rem" }}
        onClick={onClick}
      />{" "}
      <p onClick={onClick}>
        {userData.username && userData.username.length > 11
          ? `${userData.username.substring(0, [10])}...`
          : userData.username}
        <br /> <span>{t("accepted")}</span>{" "}
      </p>
      <div className="notificationbuttons">
        <button onClick={RemoveRequest} className="accept">
          Ok
        </button>
        <button className="decline">
          {" "}
          <ClearRoundedIcon
            onClick={RemoveRequest}
            style={{ color: "#a5a5a5", fontSize: "18px" }}
          />{" "}
        </button>
      </div>
    </div>
  );
}
