import React from "react";
import FirebaseContext from "../firebase/context";
import { useDocumentData } from "react-firebase-hooks/firestore";
import AvatarImg from "@material-ui/core/Avatar";

export default function Avatar({ driverid }) {
  const { firebase, user } = React.useContext(FirebaseContext);
  const [userData] = useDocumentData(firebase.db.doc(`users/${driverid}`));

  if (!userData) return null;

  return (
    <>
      {userData && userData.imgRef ? (
        <div
          className="profiledriverpost friends"
          style={{
            backgroundImage: `url(${userData && userData.imgRef})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
      ) : (
        <AvatarImg
          style={{ width: "5rem", height: "5rem" }}
          alt="ProfileImage"
          src=""
        />
      )}
    </>
  );
}
