import React from "react";
import { useDocumentData, useCollection } from "react-firebase-hooks/firestore";
import AvatarComponentEvent from "./AvatarComponentEvent";
import FirebaseContext from "../firebase/context";
import NameGetter from "./NameGetter";
//Moment
import moment from "moment";
//i18n
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Favourite({ uid, onClick }) {
  const { t } = useTranslation();
  const { firebase, storage, user } = React.useContext(FirebaseContext);
  const [eventData] = useDocumentData(firebase.db.doc(`b2bposts/${uid}`));

  if (!eventData) return null;

  return (
    <div
      key={uid}
      onClick={onClick}
      className="partypostslidersingle userpage"
      style={{
        background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${eventData?.image})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <h1>
        {eventData.clubname && eventData.clubname.length > 19
          ? `${eventData.clubname.substring(0, [18])}...`
          : eventData.clubname}
      </h1>
      <p>
        {moment(eventData.date.toDate()).format("MMM DD,")} {eventData.opens}{" "}
        <br />
        <span>
          {eventData.address && eventData.address.length > 19
            ? `${eventData.address.substring(0, [18])}...`
            : eventData.address}
        </span>
      </p>
      <p className="promoprice">{eventData.entranceprice}</p>
    </div>
  );
}
