import React from "react";
//Google Maps
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
//Firebase Hooks
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
//Firebase Context
import FirebaseContext from "../firebase/context";
//Custom Marker
import AvatarComponentMini from "./AvatarComponentMini";
//Moment
import moment from "moment";

export default function SeatMarker({ uid }) {
  const { firebase, user } = React.useContext(FirebaseContext);
  const [userData = {}] = useDocumentData(firebase.db.doc(`users/${uid}`));
  const [info, setInfo] = React.useState(false);

  function handleMarker() {
    setInfo(!info);
  }

  if (!userData) return null;

  return (
    <>
      <Marker
        onClick={handleMarker}
        position={{
          lat: userData.lat,
          lng: userData.long,
        }}
        icon={{
          url: userData.imgRef || userData.image || "../profileplaceholder.png",
          scaledSize: new window.google.maps.Size(50, 50),
        }}
      >
        {info && (
          <InfoWindow>
            <p style={{ textAlign: "center" }}>
              {userData.username}
              <br />
              {moment(userData.locationdate.toDate())
                .startOf("seconds")
                .fromNow()}
            </p>
          </InfoWindow>
        )}
      </Marker>
    </>
  );
}
