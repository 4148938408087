import React from "react";
import { NavLink } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AvatarComponent from "./AvatarComponent";
import FirebaseContext from "../firebase/context";
import Promote from "../images/noise.svg";
import { useTranslation } from "react-i18next";
import BusinessImage from "../images/businessimage.webp";
import PromotionDetailPage from "./Promotiondetails";
import { SwipeableDrawer } from "@material-ui/core";
import useCloseDrawers from "../hooks/useDrawer";

export default function ClubsHome(onClose) {
  const { firebase, storage, user } = React.useContext(FirebaseContext);
  const { t } = useTranslation();
  const [promotionModal, setPromotionModal] = React.useState();

  useCloseDrawers({
    drawers: [() => setPromotionModal(null)],
  });

  if (!user.uid) return null;

  return (
    <>
      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 199 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={promotionModal}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setPromotionModal(false)}
      >
        <PromotionDetailPage onClose={() => setPromotionModal(false)} />
      </SwipeableDrawer>
      <div className="clubshome">
        <div className="businesshead">
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              borderBottom: "1px solid rgb(48 47 47)",
            }}
          >
            <ArrowBackIosIcon
              style={{
                color: "#FD7F4D",
                position: "absolute",
                left: "26px",
                top: "50%",
                padding: "10px",
                marginLeft: "-10px",
                transform: "translate(0%, -50%)",
              }}
              onClick={onClose.onClose}
            />
            <h1> {t("promotions")} </h1>
          </div>
        </div>
        <div className="businessblock">
          <img src={BusinessImage} alt="businessimage" />
          <h2>{t("promote")}</h2>
          <p style={{ maxWidth: "95%" }}>{t("promote_text")}</p>
        </div>
        <div className="clubhomebuttons">
          <span onClick={() => setPromotionModal(true)}>
            {t("promote_post")}
          </span>
          <span onClick={onClose.onClose}>{t("switch")}</span>
        </div>
      </div>
    </>
  );
}
