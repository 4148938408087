import React, { useState } from "react";
import FirebaseContext from "../firebase/context";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FavouriteV2 from "./FavouriteV2";
import { SwipeableDrawer } from "@material-ui/core";
import EventPostPage from "./EventPost";
import useCloseDrawers from "../hooks/useDrawer";
import FavouriteImg from "../images/favouriteimg.png";
import FavoriteIcon from "@material-ui/icons/Favorite";

export default function Settings(props) {
  const { firebase, user } = React.useContext(FirebaseContext);
  const { t } = useTranslation();
  const [eventPage, setEventPage] = useState({});

  const [userData] = useDocumentData(
    firebase.db.doc(`users/${user && user.uid}`)
  );

  const favouritelist = (userData && userData.favourites) || [];

  const favouritemap =
    favouritelist &&
    Object.entries(favouritelist)
      .filter(
        ([_, date]) => date && new Date() - date.toDate() < 1000 * 60 * 60 * 24
      )
      .map(([favourite], index) => {
        return (
          <FavouriteV2
            key={favourite}
            uid={favourite}
            onClick={() => setEventPage({ id: favourite, open: true })}
          />
        );
      });

  console.log("props", props);

  useCloseDrawers({
    drawers: [() => setEventPage((prev) => ({ ...prev, open: false }))],
  });

  return (
    <>
      <SwipeableDrawer
        transitionDuration={300}
        style={{ zIndex: 199 }}
        PaperProps={{
          elevation: 0,
          style: {
            borderRadius: "0",
            background: "black",
            width: "100vw",
            height: "100vh",
            marginTop: "env(safe-area-inset-top)",
          },
        }}
        anchor="right"
        open={eventPage.open}
        disableSwipeToOpen
        swipeAreaWidth={20}
        onClose={() => setEventPage((prev) => ({ ...prev, open: false }))}
      >
        <EventPostPage
          params={{ id: eventPage.id }}
          onClose={() => setEventPage((prev) => ({ ...prev, open: false }))}
        ></EventPostPage>
      </SwipeableDrawer>

      <div className="settingspagewrapper">
        <div className="businesshead">
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              borderBottom: "1px solid rgb(48 47 47)",
            }}
          >
            <ArrowBackIosIcon
              onClick={props.onClose}
              style={{
                color: "#FD7F4D",
                position: "absolute",
                left: "26px",
                top: "50%",
                transform: "translate(0%, -50%)",
                padding: "10px",
                marginLeft: "-10px",
              }}
            />
            <h1> {t("my_favourites")} </h1>
          </div>
        </div>
        {/* HEAD */}
        <div className="mainfavouritesection">
          {favouritemap?.length >= 1 ? (
            <div className="favouritemap">{favouritemap}</div>
          ) : (
            <div className="selectnow" style={{ margin: "10px 0" }}>
              <div className="likediv">
                <img src={FavouriteImg} />
                <FavoriteIcon
                  style={{ color: "white", fontSize: "35px" }}
                  className="likeicon"
                />
                <FavoriteIcon
                  style={{ color: "white", fontSize: "35px" }}
                  className="likeicon"
                />
                <FavoriteIcon
                  style={{ color: "white", fontSize: "35px" }}
                  className="likeicon"
                />
                <FavoriteIcon
                  style={{ color: "white", fontSize: "35px" }}
                  className="likeicon"
                />
              </div>
              <h2>{t("select_a_favourite")}</h2>
              <p> {t("no_favourites")} </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
