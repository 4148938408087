import React, { useState } from "react";
import FirebaseContext from "../firebase/context";
import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useDocumentData } from "react-firebase-hooks/firestore";

export default function EmailOverview(props) {
  const { firebase, user } = React.useContext(FirebaseContext);
  const { t } = useTranslation();

  return (
    <div className="changeusernamewrapper">
      <div className="businesshead">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            borderBottom: "1px solid rgb(48 47 47)",
          }}
        >
          <ArrowBackIosIcon
            onClick={props.onClose}
            style={{
              color: "#FD7F4D",
              position: "absolute",
              left: "26px",
              top: "50%",
              transform: "translate(0%, -50%)",
              padding: "10px",
              marginLeft: "-10px",
            }}
          />
          <h1> E-Mail </h1>
        </div>
      </div>
      <div className="changeusernamemain">
        <p style={{ marginTop: "0" }}>{t("email_text")}</p>
        <p style={{ color: "white" }}> {user?.email} </p>
      </div>
    </div>
  );
}
