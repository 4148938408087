import React, { useState } from "react";
import { NavLink } from "react-router-dom";
//Icons
import { emitCloseDrawerEvent } from "../hooks/useDrawer";
import { FiMapPin } from "react-icons/fi";
import { BiUser, BiHomeAlt2 } from "react-icons/bi";
import vibrate from "../util/feedback";

export default function Navigation() {
  const [indicatorPosition, setIndicatorPosition] = useState("center");

  function scrollFixer() {
    emitCloseDrawerEvent();
    document.scrollingElement.scrollTo(0, 0, { behaviour: "smooth" });
  }

  return (
    <div className="navigation">
      <div className="nav">
        <div
          className="navigationIndicator"
          style={
            indicatorPosition === "left"
              ? { left: "5px" }
              : indicatorPosition === "center"
              ? { left: "50%", transform: "translate(-50%, 0)" }
              : indicatorPosition === "right"
              ? { left: "calc(70% - 5px)" }
              : {}
          }
        />
        <NavLink
          to="/Maps"
          onClick={() => {
            emitCloseDrawerEvent();
            setIndicatorPosition("left");
            vibrate("light");
          }}
        >
          <FiMapPin
            className="navigationIcons"
            style={
              indicatorPosition === "left"
                ? { fontSize: "25px", color: "black" }
                : indicatorPosition === "center"
                ? { fontSize: "25px", color: "white" }
                : indicatorPosition === "right"
                ? { fontSize: "25px", color: "white" }
                : {}
            }
          />
        </NavLink>

        <NavLink
          onClick={() => {
            scrollFixer();
            setIndicatorPosition("center");
            vibrate("light");
          }}
          to="/Home"
        >
          <BiHomeAlt2
            className="navigationIcons"
            style={
              indicatorPosition === "left"
                ? {
                    fontSize: "25px",
                    color: "white",
                  }
                : indicatorPosition === "center"
                ? { fontSize: "25px", color: "black" }
                : indicatorPosition === "right"
                ? { fontSize: "25px", color: "white", fill: "white" }
                : {}
            }
          />
        </NavLink>

        <NavLink
          onClick={() => {
            scrollFixer();
            setIndicatorPosition("right");
            vibrate("light");
          }}
          to="/Profile"
        >
          <BiUser
            className="navigationIcons"
            style={
              indicatorPosition === "left"
                ? { fontSize: "25px", color: "white" }
                : indicatorPosition === "center"
                ? { fontSize: "25px", color: "white" }
                : indicatorPosition === "right"
                ? { fontSize: "25px", color: "black" }
                : {}
            }
          />
        </NavLink>
      </div>
    </div>
  );
}
